import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import clockRewindIcon from 'assets/svg/event/finance/clock-rewind-icon.svg';
import creditCardIcon from 'assets/svg/event/finance/credit-card-icon.svg';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { setPaymentDetailsDialog } from 'contexts/redux/dialog/dialogsSlice';
import { formatCurrency } from 'helpers/formatNumber';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IPayment } from 'services/@types';
import useHandlePaymentItem from '../../hooks/useHandlePaymentItem';

interface PaymentItemProps extends IPayment {
  isBlurred?: boolean;
  isExpense?: boolean;
  eventId?: string;
  paymentId?: string;
  paidAmount?: number;
}

const getStatusProps = (isPaid: boolean, isExpense: boolean) => {
  if (!isExpense && isPaid) {
    return {
      icon: clockRewindIcon,
      badge: { colorScheme: 'green', text: 'Completed' },
      bgColor: '#5fbf9630',
    };
  }
  switch (isPaid) {
    case true:
      return {
        icon: creditCardIcon,
        badge: { colorScheme: 'brand.900', text: 'Completed' },
        bgColor: '#AA915D33',
      };
    case false:
      return {
        icon: clockRewindIcon,
        badge: { colorScheme: 'gray', text: 'Pending' },
        bgColor: 'gray.100',
      };
    default:
      return {
        icon: clockRewindIcon,
        badge: { colorScheme: 'green', text: 'Overdue' },
        bgColor: 'gray.100',
      };
  }
};

const PaymentItem = (props: PaymentItemProps) => {
  const {
    paymentId,
    dueDate,
    amount,
    paidAmount,
    isBlurred,
    isPaid,
    isExpense,
    clients,
    quoteId,
    paymentIndex,
    eventId,
  } = props;
  const { icon } = getStatusProps(isPaid, isExpense);
  const { t } = useTranslation();
  const myBusiness = useMyBusiness();
  const textColor = useMemo(() => {
    return getStatusProps(isPaid, isExpense).badge.colorScheme;
  }, [isExpense, isPaid]);
  const bgColor = useMemo(() => {
    return getStatusProps(isPaid, isExpense).bgColor;
  }, [isExpense, isPaid]);

  const dispatch = useDispatch();

  const { formattedDate, quote, supplier, client } = useHandlePaymentItem({
    quoteId,
    payment: props,
    paymentIndex,
    isPaid,
    date: dueDate,
  });

  console.log(`Client info item`, client);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      dispatch(
        setPaymentDetailsDialog({
          item: {
            paymentId,
            quoteId,
            eventId,
            paymentIndex,
            // if there is no quoteId it means manual payment, send supplierId or clientId if exists
            supplierId: !quoteId ? supplier?.id : undefined,
            clientId: !quoteId ? client?.id : undefined,
          },
        }),
      );
    },
    [dispatch, paymentId, quoteId, eventId, paymentIndex, supplier, client],
  );
  const paymentItemName = useMemo(() => {
    if (quote?.clients.find((c) => c.client?.businessID === myBusiness?.id)) {
      return supplier?.name; // if i am the client then show the supplier name
    }
    if (!quote) {
      if (isExpense) {
        return supplier?.name;
      } else {
        return clients
          ?.map((c: any) =>
            c.client?.type === 'business'
              ? c.client?.company
              : `${c.client?.firstName} ${c.client?.lastName}`,
          )
          ?.join(', ');
      }
    }
    return quote?.clients
      ?.filter((c) => c.client?.businessID !== myBusiness?.id)
      ?.map((c) =>
        c.client?.type === 'business'
          ? c.client?.company
          : `${c.client?.firstName} ${c.client?.lastName}`,
      )
      ?.join(', ');
  }, [quote, myBusiness?.id, supplier?.name, clients, isExpense]);
  const categoryText = useMemo(() => {
    if (isExpense) {
      return `${supplier?.category
        ?.map((c) => t(`categories.${(c as { name: string }).name}`))
        .join(', ')}`;
    } else {
      if (client?.type === 'business') {
        return `${client?.address || ''}`;
      } else {
        return `${client?.email || ''}`;
      }
    }
  }, [isExpense, supplier?.category, t, client]);

  const paymentAmount = useMemo(
    () => formatCurrency(isPaid ? paidAmount : amount),
    [amount, paidAmount, isPaid],
  );

  return (
    <HStack
      w="100%"
      justify="space-between"
      py={2}
      cursor="pointer"
      onClick={handleClick}>
      <HStack spacing={4}>
        <Image src={icon} boxSize={7} />
        <VStack align="start" spacing={0}>
          <HStack>
            <Text fontWeight="medium">{paymentItemName}</Text>
            <Text
              fontWeight={'bold'}
              color="brand.900"
              fontSize={'sm'}
              wordBreak="break-all"
              noOfLines={1}>
              {categoryText}
            </Text>
          </HStack>
          <Text fontSize="sm" color="gray.500">
            {!isPaid
              ? t(`event_finance.due_on`, { date: formattedDate })
              : formattedDate}
          </Text>
        </VStack>
      </HStack>
      <HStack spacing={4}>
        <Text
          fontSize="md"
          fontWeight="bold"
          color={textColor}
          bg={bgColor}
          p={2}
          borderRadius="10px"
          transition="all 0.3s ease"
          filter={isBlurred ? 'blur(8px)' : 'none'}>
          {paymentAmount}
        </Text>
      </HStack>
    </HStack>
  );
};

export default PaymentItem;
