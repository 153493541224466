import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchThreads } from 'contexts/redux/messenger/messengerSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Thread } from 'services/@types';
import useDebounce from './useDebounce';
interface IUseThreadsProps {
  queryKey?: string[];
  query?: Record<string, any>;
  enabled?: boolean;
  cacheTime?: number;
  staleTime?: number;
}
const defaultTime = 5 * 60 * 1000; // 5 minutes
export default function useThreads(props: IUseThreadsProps): {
  threads: Thread[];
  isLoading: boolean;
} {
  const {
    query,
    queryKey,
    enabled,
    cacheTime = defaultTime,
    staleTime = defaultTime,
  } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { threads, threadsLoading } = useSelector(
    (state: RootState) => state.messenger,
  );

  const debouncedQueryKey = useDebounce(
    useMemo(
      () => (queryKey?.length > 0 ? queryKey : ['fetchThreads']),
      [queryKey],
    ),
    300,
  );

  const debouncedQuery = useDebounce(
    useMemo(() => query || { limit: 100 }, [query]),
    300,
  );

  const memoEnabled = useMemo(
    () => (enabled !== undefined ? enabled : true),
    [enabled],
  );

  const result = useQuery({
    queryKey: debouncedQueryKey,
    queryFn: async () =>
      await dispatch(fetchThreads(debouncedQuery || { limit: 100 })),
    enabled: memoEnabled,
    cacheTime,
    staleTime,
  } as UseQueryOptions<any>);

  const isLoading = useMemo(
    () => result.isLoading || threadsLoading,
    [result.isLoading, threadsLoading],
  );

  return {
    threads,
    isLoading,
  };
}
