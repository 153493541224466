import {
  Divider,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { openAlertDialog } from 'contexts/redux/dialog/dialogsSlice';
import { closeAlertDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { format } from 'date-fns';
import { formatCurrency } from 'helpers/formatNumber';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useDeleteQuote, useGetQuotes } from 'queries/quote';
import { useGetSupplier } from 'queries/supplier';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdOutlineMoreHoriz } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IQuote, QuoteStatus } from 'services/@types';
import { currency } from 'variables/currency';
import RenderQuoteStatus from 'views/main/finance/quote/quoteList/components/QuoteStatus';
import UploadQuoteModal from './UploadQuoteModal';

const Quote = memo(({ quote }: { quote: IQuote }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const supplierData = useGetSupplier(quote?.supplierId);
  const { mutate: deleteQuote } = useDeleteQuote();

  const name = useMemo(() => supplierData?.data?.name, [supplierData?.data]);

  const handleNavigateToUpdateQuote = useCallback(() => {
    navigate(`/main/finance/quotes/${quote?.id}`);
  }, [quote?.id, navigate]);

  const handleDeleteQuote = useCallback(() => {
    dispatch(
      openAlertDialog({
        title: t('event.delete_quote'),
        content: t('event.delete_quote_description'),
        onConfirm: async () => {
          deleteQuote(quote?.id);
          dispatch(closeAlertDialog());
        },
      }),
    );
  }, [deleteQuote, quote?.id, dispatch, t]);

  return (
    <Flex flexDirection="column" gap="10px">
      <Flex justifyContent="space-between">
        <Flex flexDirection="column" gap="10px">
          <Text variant="eventSupplierName">{name}</Text>
          <Text variant="clientDetailInfo">
            {quote?.totalAmount &&
              formatCurrency(
                quote.totalAmount,
                currency.find((c) => c?.value === quote?.currency)?.symbol,
              )}
          </Text>
        </Flex>
        <Menu>
          <MenuButton aria-label="more">
            <Icon as={MdOutlineMoreHoriz} color="#93C3B3" w="20px" h="20px" />
          </MenuButton>
          <MenuList
            w="95px"
            minW="95px"
            h="111px"
            borderRadius="24px"
            p="0"
            overflow="hidden">
            <MenuItem
              gap="13px"
              justifyContent="center"
              alignItems="center"
              h="50%"
              onClick={handleNavigateToUpdateQuote}>
              <Text variant="eventTabButtonTitle">{t('event.edit')}</Text>
            </MenuItem>
            <MenuItem
              gap="13px"
              justifyContent="center"
              alignItems="center"
              h="50%"
              onClick={handleDeleteQuote}>
              <Text variant="eventTabButtonTitle">{t('event.delete')}</Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      {quote?.createdAt && (
        <Text variant="addRowText">
          {format(new Date(quote?.createdAt), 'dd MMM, yyyy')}
        </Text>
      )}
      <Flex flexDirection="column" gap="10px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text variant="addRowText">
            {quote?.description || quote?.comment || '-'}
          </Text>
          <RenderQuoteStatus status={quote.status as QuoteStatus} />
        </Flex>
      </Flex>
      <Divider />
    </Flex>
  );
});

export default function SupplierQuotes() {
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('event_quotes');
  const business = useMyBusiness();
  const params = useParams();

  const eventId = useMemo(() => params.id, [params.id]);

  const { data: quotesData } = useGetQuotes({
    supplierBusinessId: business?.id,
    eventId,
    limit: 1000,
    projectBy: '-updatedAt',
  });

  const quotes = useMemo(() => quotesData?.results, [quotesData]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Card
      flex="1"
      borderRadius={{ base: 0, md: '30px' }}
      style={{ gridColumn: 'span 2' }}
      maxHeight="535px"
      h="100%"
      gap="20px"
      minH="535px"
      overflowX="hidden"
      overflowY="auto"
      minW={{ base: '100%', lg: 'auto' }}
      {...style}
      ref={ref}>
      <Flex alignItems="center" justify="space-between">
        <Text variant="eventPageCardTitle">{t('event.quotes')}</Text>
        <IconButton
          margin={'0 !important'}
          borderRadius="8px"
          maxWidth="24px"
          height="24px"
          minWidth="24px"
          p="0 !important"
          order={{ base: 1, lg: 0 }}
          aria-label="add-event"
          variant={'actionIconBlue'}
          icon={<Icon as={MdAdd} fontSize="16px" />}
          onClick={onOpen}
        />
      </Flex>
      <Flex flexDirection="column" gap="20px">
        {quotes?.map((quote) => (
          <Quote key={quote?.id} quote={quote} />
        ))}
      </Flex>
      <UploadQuoteModal isOpen={isOpen} onClose={onClose} />
    </Card>
  );
}
