import { useToast } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { fetchClients } from 'contexts/redux/client/clientSlice';
import {
  getStartingPayloadDate,
  setStartingPayloadDate,
} from 'contexts/redux/createMeeting/createMeetingSlice';
import {
  closeSplashDialog,
  setContactsPickerDialog,
  setQuoteDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import {
  createEvent,
  getEvent,
  updateEvent,
} from 'contexts/redux/event/eventSlice';
import { setSelectedQuote, updateQuote } from 'contexts/redux/quote/quoteSlice';
import store, { RootState } from 'contexts/redux/store';
import { useMyUserPreferences } from 'contexts/redux/userPreferences/userPreferencesSlice';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IClient,
  IClientContact,
  IEvent,
  IQuote,
  Supplier,
} from 'services/@types';
const requiredFields = ['name', 'type', 'location'];

interface ICreateEventResult {
  handleChange: (key: string, value: any) => void;
  handleCreateUpdateEvent: () => void;
  event: Partial<IEvent>;
  errorList: string[];
  isEventPlannerFormVisible: boolean;
  setIsEventPlannerFormVisible: (value: boolean) => void;
  handleOpenClientDialog: () => void;
  handleOpenSelectSupplierDialog: () => void;
  setClient: (client: { client: IClient; contacts: IClientContact[] }) => void;
  handleOpenQuoteDialog: () => void;
  selectedQuote: IQuote | null;
}

export default function useCreateEvent(): ICreateEventResult {
  const params = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { triggerChangesDetected, preventChangesDetected } = useAppNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const { defaultEventCalendarId } = useMyUserPreferences();
  const startingPayloadDate = useSelector(getStartingPayloadDate);
  const eventData = useSelector((state: RootState) => state.event.event);
  const [event, setEvent] = useState<Partial<IEvent>>({
    dateAndTime: new Date(),
    eventPlanner: null,
  });
  const [isEventPlannerFormVisible, setIsEventPlannerFormVisible] =
    useState<boolean>(false);
  const [errorList, setErrorList] = useState<string[]>([]);
  const { user, qoutes, selectedQuote } = useSelector((state: RootState) => ({
    user: state.auth.user,
    qoutes: state.quote.qoutes,
    selectedQuote: state.quote.selectedQuote,
  }));
  useEffect(() => {
    // find event calendar id and add it to the event as default calendar id
    if (defaultEventCalendarId) {
      setEvent((prev) => ({ ...prev, calendarId: defaultEventCalendarId }));
      return;
    }
    const calendar = store
      .getState()
      .calendars.calendars.find(
        (calendar) => calendar.isSystem && calendar.name === 'events',
      );
    if (calendar) {
      setEvent((prev) => ({ ...prev, calendarId: calendar.id }));
    }
  }, [defaultEventCalendarId]);

  const handleOpenClientDialog = useCallback(() => {
    dispatch(
      setContactsPickerDialog({
        item: {
          chosenClients: event.clients,
          eventId: event?.id,
          type: 'clients',
        },
        onConfirm: (
          v: {
            client: IClient;
            contacts: IClientContact[] | null;
          }[],
        ) => {
          if (errorList.includes('clients')) {
            setErrorList((prev) => prev.filter((field) => field !== 'clients'));
          }
          setEvent((prevstate) => {
            return {
              ...prevstate,
              clients: v,
            };
          });
        },
      }),
    );
  }, [dispatch, errorList, event?.clients, event?.id]);

  const handleSelectedQuote = useCallback(
    async (id: string) => {
      const quote = qoutes.find((q) => q.id === id);

      if (quote) {
        dispatch(setSelectedQuote(quote));
      }
    },
    [dispatch, qoutes],
  );

  const handleOpenQuoteDialog = useCallback(() => {
    dispatch(
      setQuoteDialog({
        onConfirm: (quote: IQuote) => {
          handleSelectedQuote(quote.id);
        },
      }),
    );
  }, [dispatch, handleSelectedQuote]);

  const handleOpenSelectSupplierDialog = useCallback(() => {
    dispatch(
      setContactsPickerDialog({
        item: {
          chosenSuppliers: event.eventPlanner ? [event.eventPlanner] : [],
          eventId: event?.id,
          type: 'suppliers',
        },
        onConfirm: (
          v: {
            supplier: Supplier;
          }[],
        ) => {
          if (errorList.includes('eventPlanner.id')) {
            setErrorList((prev) =>
              prev.filter((field) => field !== 'eventPlanner.id'),
            );
          }
          setEvent((prevstate) => ({
            ...prevstate,
            eventPlanner: v?.[0]?.supplier || null, // remove this once the supplier is selected
          }));
        },
      }),
    );
    // dispatch(
    //   setSelectSupplierDialog({
    //     item: {
    //       eventId: event?.id,
    //     },
    //     onConfirm: (e: Supplier[]) => {
    //       if (errorList.includes('eventPlanner.id')) {
    //         setErrorList((prev) =>
    //           prev.filter((field) => field !== 'eventPlanner'),
    //         );
    //       }
    //       setEvent((prevstate) => ({
    //         ...prevstate,
    //         eventPlanner: e?.[0],
    //       }));
    //     },
    //   }),
    // );
  }, [dispatch, errorList, event?.id, event?.eventPlanner]);

  const validateEvent = useCallback(() => {
    const allRequiredFields = [...requiredFields];

    if (isEventPlannerFormVisible && !event?.eventPlanner) {
      allRequiredFields.push('eventPlanner.id');
    }
    // for each client detail, add the required fields
    allRequiredFields.push('clients');

    const result: string[] = allRequiredFields.filter((field) => {
      const key = field as keyof IEvent;
      if (field === 'clients') {
        return (event?.clients || [])?.length === 0;
      } else if (field.includes('.')) {
        const [parent, child] = field.split('.');
        return !event[parent as keyof IEvent]?.[
          child as keyof (typeof event)[keyof IEvent]
        ];
      }
      return typeof event[key] === 'string'
        ? !event[key]
        : !event[key]?.['value'];
    });

    if (result.length > 0) {
      setErrorList(result);
      return true;
    }
    return false;
  }, [event, isEventPlannerFormVisible]);

  const handleCreateUpdateEvent = useCallback(async () => {
    const isError = validateEvent();
    const eventDataPayload: any = { ...event };
    if (isError) {
      console.log('errorList ->', errorList);
      return;
    }
    let response;
    if (params?.id) {
      const updates: any = {};

      for (const key in event) {
        if (event.hasOwnProperty(key)) {
          const typedKey = key as keyof IEvent;

          if (typedKey === 'eventPlanner') {
            if (event[typedKey]?.id) {
              updates[typedKey] = event[typedKey]?.id;
            }
          } else if (typedKey === 'clients') {
            const newClients = event[typedKey].map(
              (c: { client: IClient; contacts: IClientContact[] }) => ({
                client: c.client.id,
                contacts: !c.contacts
                  ? []
                  : c.contacts?.map((contact) => contact.id),
              }),
            );
            if (newClients.length > 0) {
              updates[typedKey] = newClients;
            }
          } else {
            if (event[typedKey] !== eventData[typedKey]) {
              updates[typedKey] = event[typedKey];
            }
          }
        }
      }
      response = await dispatch(
        updateEvent({
          id: params.id,
          updates,
        }),
      );
      await queryClient.invalidateQueries({
        queryKey: [`getEvent-${params.id}`],
      });
      await queryClient.invalidateQueries({
        queryKey: ['dashboardUpcomingEvents'],
      });
    } else {
      if (eventDataPayload.calendarId === null) {
        delete eventDataPayload.calendarId;
      }

      if (eventDataPayload.clients) {
        eventDataPayload.clients = eventDataPayload.clients.map(
          (c: { client: IClient; contacts: IClientContact[] }) => ({
            client: c.client.id,
            contacts: !c.contacts
              ? []
              : c.contacts?.map((contact) => contact.id),
          }),
        );
      } else {
        delete eventDataPayload.clients;
      }

      if (eventDataPayload.eventPlanner?.id) {
        eventDataPayload.eventPlanner = eventDataPayload.eventPlanner.id;
      } else {
        delete eventDataPayload.eventPlanner;
      }

      response = await dispatch(
        createEvent({ ...eventDataPayload, curator: user?.id } as IEvent),
      );
    }

    if (response?.payload) {
      if (selectedQuote && selectedQuote.eventId !== response?.payload?.id) {
        await dispatch(
          updateQuote({
            id: selectedQuote.id,
            updates: {
              eventId: response?.payload?.id,
            },
          }),
        );
      }
      await queryClient.invalidateQueries({
        queryKey: ['dashboardUpcomingEvents'],
      });

      await dispatch(fetchClients({}));
      setEvent({ clients: [] });
      toast({
        variant: 'main',
        title: t(
          params?.id
            ? 'create_event.toast.event_updated'
            : 'create_event.toast.event_added',
        ),
      });
      navigate(`/main/event/${response?.payload?.id}`);
      preventChangesDetected();
    }
    if (response?.error) {
      dispatch(closeSplashDialog());
    }
  }, [
    validateEvent,
    params.id,
    errorList,
    dispatch,
    event,
    eventData,
    user?.id,
    toast,
    t,
    navigate,
    queryClient,
    preventChangesDetected,
    selectedQuote,
  ]);
  const handleChange = useCallback(
    (key: string, value: any, errorKey?: string) => {
      triggerChangesDetected();
      if (errorList.includes(key)) {
        setErrorList((prev) => prev.filter((error) => error !== key));
      }
      if (errorKey && errorList.includes(errorKey)) {
        setErrorList((prev) => prev.filter((error) => error !== errorKey));
      }
      if (key === 'numberOfGuests' || key === 'numberOfGuestsChildren') {
        const regex = /^[1-9]\d{0,3}$/; // 1-9999
        if (!regex.test(value)) {
          setErrorList((prev) => [...prev, key]);
        } else {
          setErrorList((prev) => prev.filter((error) => error !== key));
        }
      }
      setEvent((prev) => ({ ...prev, [key]: value }));
    },
    [errorList, triggerChangesDetected],
  );

  const setClient = useCallback(
    (client: { client: IClient; contacts: IClientContact[] | null }) => {
      setEvent((prev) => ({
        ...prev,
        clients: [...(prev?.clients || []), client],
      }));
    },
    [],
  );

  useEffect(() => {
    if (params?.id) {
      const fetchEvent = async () => {
        const event = await dispatch(getEvent(params?.id));
        if (event.payload) {
          const eventData = { ...event.payload };
          if (eventData?.dateAndTime) {
            eventData.dateAndTime = new Date(eventData.dateAndTime);
          }
          if (eventData?.dateAndTimeEnd) {
            eventData.dateAndTimeEnd = new Date(eventData.dateAndTimeEnd);
          }
          if (eventData.dateAndTimeEnd) {
            eventData.dateAndTimeEnd = new Date(event.payload.dateAndTimeEnd);
          }
          setEvent(eventData);
          if (eventData.eventPlanner) {
            setIsEventPlannerFormVisible(true);
          }
        }
      };
      fetchEvent();
    } else {
      if (startingPayloadDate) {
        handleChange('dateAndTime', startingPayloadDate);
        dispatch(setStartingPayloadDate(null));
      }
    }
  }, [params?.id, dispatch, handleChange, startingPayloadDate]);

  useEffect(() => {
    if (!isEventPlannerFormVisible) {
      setErrorList((prev) =>
        prev.filter(
          (error) =>
            error !== 'eventPlanner' && !error.startsWith('eventPlanner'),
        ),
      );
    } else {
      setErrorList((prev) => prev.filter((error) => error !== 'clients'));
    }
  }, [setErrorList, isEventPlannerFormVisible]);

  useEffect(() => {
    return () => {
      // clear selected quote when unmount
      dispatch(setSelectedQuote(null));
    };
  }, [dispatch]);

  return {
    handleChange,
    handleCreateUpdateEvent,
    event,
    errorList,
    isEventPlannerFormVisible,
    setIsEventPlannerFormVisible,
    handleOpenClientDialog,
    handleOpenSelectSupplierDialog,
    setClient,
    handleOpenQuoteDialog,
    selectedQuote,
  };
}
