import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { closeProductCategoriesDialog } from 'contexts/redux/dialog/dialogsSlice';
import {
  createNewProductCategory,
  deleteProductCategory,
  setCurrentProductCategory,
  updateProductCategory,
} from 'contexts/redux/product/productSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPencilFill, BsThreeDots, BsTrash } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { ProductCategory } from 'services/@types';
export const ProductCategoriesModal = (props: {}) => {
  const { onClose } = useDisclosure();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<any>();
  const [editInput, setEditInput] = useState('');
  const [editingNameForCategory, setEditingNameForCategory] =
    useState<string>();
  const business = useMyBusiness();
  const { productCategoriesDialog } = useSelector(
    (state: RootState) => state.dialogs,
  );
  const { productCategories, currentProductCategory } = useSelector(
    (state: RootState) => state.product,
  );

  const handleUpdateCategoryName = useCallback(() => {
    dispatch(
      updateProductCategory({
        categoryId: currentProductCategory?.id,
        categoryName: editInput,
      }),
    );
    dispatch(setCurrentProductCategory(null));
    setEditingNameForCategory(null);
  }, [currentProductCategory, dispatch, editInput]);

  const handleStartEditingCategory = useCallback(() => {
    setEditingNameForCategory(currentProductCategory?.id);
    setEditInput(currentProductCategory?.name || '');
  }, [currentProductCategory?.id, currentProductCategory?.name]);

  const handleNewCategoryClicked = useCallback(async () => {
    const res = await dispatch(
      createNewProductCategory({
        categoryName: t('products.product_categories_modal.new_category'),
        businessID: business.id,
      }),
    );
    if (res.payload) {
      console.log('res', res);
      dispatch(setCurrentProductCategory(res.payload));
      setEditingNameForCategory(res.payload.id);
      setEditInput(res.payload.name);
    }
  }, [business?.id, dispatch, t]);

  const handleInputChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, category: ProductCategory) => {
      editingNameForCategory === category.id && setEditInput(e.target.value);
    },
    [editingNameForCategory],
  );
  return (
    <Modal
      isOpen={!!productCategoriesDialog}
      onClose={onClose}
      size="sm"
      scrollBehavior="inside"
      isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={'3xl'} py={4}>
        <ModalHeader>
          <HStack>
            <Text flex={1} fontSize="2xl">
              {t('products.product_categories_modal.title')}
            </Text>
            <ModalCloseButton
              position="relative"
              top={0}
              right={0}
              onClick={() => {
                dispatch(closeProductCategoriesDialog());
              }}
            />
          </HStack>
        </ModalHeader>
        <ModalBody>
          <VStack gap={1} w="100%">
            <HStack
              key="new"
              cursor={'pointer'}
              w="100%"
              color="brand.400"
              border="1px solid"
              borderColor={'gray.300'}
              borderRadius="2xl"
              _hover={{ bg: 'gray.100' }}
              _active={{ bg: 'gray.200' }}
              onClick={handleNewCategoryClicked}
              px={4}>
              <Text py={3} flex={1} fontWeight={500} fontSize="14px">
                {t('products.product_categories_modal.new_category')}
              </Text>
              <Icon as={FaPlus} p={0.5} />
            </HStack>
            {productCategories.map((category: ProductCategory, i: number) => (
              <HStack
                key={category.id}
                cursor={'pointer'}
                _hover={{ bg: 'gray.100' }}
                px={0}
                position="relative"
                w="100%"
                gap={0}
                py={0}>
                <InputField
                  flex={1}
                  onChange={(e) => handleInputChanged(e, category)}
                  isReadOnly={currentProductCategory?.id !== category.id}
                  transition="all 0.2s"
                  w="100%"
                  fontSize={'14px'}
                  fontWeight={500}
                  py={4}
                  value={
                    editingNameForCategory === category.id
                      ? editInput
                      : category.name === 'general_category'
                      ? t('products.general_category')
                      : category.name
                  }
                />
                {currentProductCategory?.id === category.id && (
                  <Flex
                    display={
                      editingNameForCategory === category.id ? 'flex' : 'none'
                    }
                    align="center"
                    sx={{ position: 'absolute' }}
                    right={i18n.dir() === 'ltr' ? 0 : 'auto'}
                    left={i18n.dir() === 'rtl' ? 0 : 'auto'}
                    gap={2}
                    px={0}>
                    <Button
                      variant="h4"
                      onClick={() => {
                        handleUpdateCategoryName();
                      }}>
                      {t('products.product_categories_modal.done')}
                    </Button>
                  </Flex>
                )}
                <Flex
                  display={
                    currentProductCategory?.id === category.id &&
                    editingNameForCategory === category.id
                      ? 'none'
                      : 'flex'
                  }
                  align="center"
                  sx={{ position: 'absolute' }}
                  right={i18n.dir() === 'ltr' ? 0 : 'auto'}
                  left={i18n.dir() === 'rtl' ? 0 : 'auto'}
                  gap={2}
                  px={3}>
                  <IconButton
                    display={
                      currentProductCategory?.id === category.id
                        ? 'flex'
                        : 'none'
                    }
                    key="delete"
                    variant="actionIconRed"
                    p={2}
                    onClick={() => {
                      dispatch(setCurrentProductCategory(null));
                      dispatch(deleteProductCategory(category.id));
                    }}
                    as={BsTrash}
                    aria-label={''}
                  />
                  <IconButton
                    key="edit"
                    display={
                      currentProductCategory?.id === category.id
                        ? 'flex'
                        : 'none'
                    }
                    variant="actionIconGrey"
                    p={2}
                    onClick={handleStartEditingCategory}
                    as={BsPencilFill}
                    aria-label={''}
                  />
                  <Icon
                    key="more"
                    cursor={'pointer'}
                    color="brand.400"
                    zIndex={2}
                    display={
                      category.name === 'general_category' ? 'none' : 'flex'
                    }
                    pointerEvents="all"
                    _hover={{ color: 'brand.300' }}
                    _active={{ color: 'brand.300' }}
                    _focus={{ color: 'brand.300' }}
                    as={BsThreeDots}
                    onClick={() => {
                      console.log('more');
                      if (currentProductCategory?.id === category.id) {
                        handleUpdateCategoryName();
                        return;
                      }
                      dispatch(setCurrentProductCategory(category));
                    }}
                    fontSize={24}
                    p={0}
                  />
                </Flex>
              </HStack>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
