import { Flex, Icon, IconButton, Image, Spacer, Text } from '@chakra-ui/react';
import NoFount from 'assets/svg/event/not-found.svg';
import Card from 'components/card/Card';
import DropdownField from 'components/fields/DropdownField';
import InputDateField from 'components/fields/InputDateField';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { Dispatch, SetStateAction, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { IPayment } from 'services/@types';
import PaymentsTable from './PaymentsTable';
import StatsCard from './StatsCard';
interface FinanceCardProps {
  title: string;
  stats?: {
    title: string;
    value: string;
    bgColor: string;
  }[];
  payments?: IPayment[];
  handleShowAll?: () => void;
  filter: {
    fromDate?: Date;
    toDate?: Date;
    range: string;
    search?: string;
    sortBy?: string;
  };
  setFilter: Dispatch<
    SetStateAction<{
      fromDate?: Date;
      toDate?: Date;
      range: string;
      search?: string;
      sortBy?: string;
    }>
  >;
  isSearchEnabled?: boolean;
  handlePlusClick?: () => void;
  handleSort: (header: string) => void;
}

export const DropdownFieldOptions = [
  { label: 'finance_overview.all', value: '' },
  { label: 'finance_overview.today', value: 'today' },
  { label: 'finance_overview.this_month', value: 'this_month' },
  { label: 'finance_overview.last_30_days', value: '30days' },
  { label: 'finance_overview.this_year', value: 'this_year' },
  { label: 'finance_overview.previous_year', value: 'previous_year' },
];

export default function FinanceCard(props: FinanceCardProps) {
  const {
    title,
    stats,
    payments,
    handleShowAll,
    filter,
    setFilter,
    isSearchEnabled = false,
    handlePlusClick = () => {},
    handleSort,
  } = props;
  const { t } = useTranslation();
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const options = useMemo(
    () =>
      DropdownFieldOptions.map((option) => ({
        ...option,
        label: t(option.label),
      })),
    [t],
  );

  const selectedOptions = useMemo(() => {
    const selected = options.find((option) => option.value === filter.range);
    return selected ? [selected] : [];
  }, [options, filter.range]);

  const showedOptions = useMemo(() => {
    const showed = options.find((option) => option.value === filter.range);
    return showed ? showed.label : '';
  }, [options, filter.range]);

  const handleChangeFilter = useCallback(
    (filter: { fromDate?: Date; toDate?: Date; range: string }) => {
      setFilter(filter);
    },
    [setFilter],
  );

  const onSelected = useCallback(
    (event: { value: string }) => {
      setFilter((prev) => ({
        ...prev,
        range: prev.range === event.value ? '' : event.value,
      }));
    },
    [setFilter],
  );

  const handleSearch = useCallback(
    (e: any) => {
      clearTimeout(timerRef?.current);

      timerRef.current = setTimeout(() => {
        setFilter((prev) => ({
          ...prev,
          search: e.target.value,
        }));
      }, 1000);
    },
    [setFilter],
  );

  return (
    <Card
      p="19.5px 24px"
      gap="24px"
      borderRadius={{ base: '0', md: '30px' }}
      flex={{ base: '1', md: '1 1 70%' }}
      minH="600px">
      {/* header */}
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="previewQuoteContactTitle">{t(title)}</Text>
        {!isSearchEnabled ? (
          <IconButton
            aria-label="Add"
            variant="actionIconBlueSmall"
            icon={<Icon as={FaPlus} color="warmWhite.100" />}
            display="flex"
            justifySelf="flex-end"
            transition="all 0.4s linear"
            onClick={handlePlusClick}
          />
        ) : (
          <Flex w="200px">
            <SearchBar
              background="whine"
              placeholder={t('finance_overview.search')}
              containerProps={{ border: '1px solid #00000099' }}
              onChange={handleSearch}
              value={filter?.search || ''}
            />
          </Flex>
        )}
      </Flex>
      {/* filtes */}
      <Flex
        alignItems="end"
        justifyContent="space-between"
        gap={{ base: '16px', md: '0px' }}
        flexDirection={{ base: 'column-reverse', md: 'row' }}>
        <Flex
          alignItems="center"
          gap={{ base: '5px', md: '20px' }}
          display={filter.range ? undefined : 'none'}>
          <InputDateField
            label={t('finance_overview.from_date')}
            value={filter.fromDate}
            onChange={(e: Date) => {
              handleChangeFilter({ ...filter, fromDate: e });
            }}
            justifyContent="end"
            w="100%"
            buttonStyles={{
              w: { base: '100%', md: 'fit-content' },
              maxW: { base: '100%', md: 'fit-content' },
              h: { base: '50px', md: '50px' },
            }}
          />
          <InputDateField
            label={t('finance_overview.to_date')}
            value={filter.toDate}
            onChange={(e: Date) => {
              handleChangeFilter({ ...filter, toDate: e });
            }}
            justifyContent="end"
            w="100%"
            buttonStyles={{
              w: { base: '100%', md: 'fit-content' },
              maxW: { base: '100%', md: 'fit-content' },
              h: { base: '50px', md: '50px' },
            }}
          />
        </Flex>
        <Spacer />
        <DropdownField
          closeOnSelect
          options={options}
          label={t('finance_overview.range')}
          onSelected={onSelected}
          selectedOptions={selectedOptions}
          showedOptions={showedOptions}
          placeholder={t(DropdownFieldOptions[0].label)}
          menuButton={{
            borderColor: '#00000099',
          }}
          w={{ base: '100%', md: 44 }}
        />
      </Flex>
      {/* stats cards */}
      <Flex alignItems="center" gap={{ base: '5px', md: '24px' }} w="100%">
        {stats?.map((stat, index) => (
          <StatsCard key={index} {...stat} />
        ))}
      </Flex>
      {/* table */}
      {payments?.length > 0 && (
        <PaymentsTable
          payments={payments}
          handleShowAll={handleShowAll}
          handleSort={handleSort}
          sortBy={filter.sortBy || ''}
        />
      )}
      {/* no payments */}
      {(!payments || payments?.length === 0) && (
        <Flex
          flexDir="column"
          gap="16px"
          alignItems="center"
          justifyContent="center"
          h="100%">
          <Image w="184px" src={NoFount} alt="" />
          <Text variant="financeOverviewNoDocumentsTitle">
            {t('finance_overview.nothing_yet_here')}
          </Text>
        </Flex>
      )}
    </Card>
  );
}
