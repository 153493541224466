import { Button, Flex, Image, Text } from '@chakra-ui/react';
import noInvoicesAndReceipts from 'assets/img/quote/NoQuotesCreated.png';
import Card from 'components/card/Card';
import DropDownField from 'components/fields/DropdownField';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useDocumentType from '../hooks/useDocumentType';

export default function NoInvoices() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    options,
    showedDocumentType,
    selectedDocumentType,
    onSelectedDocumentType,
    documentType,
  } = useDocumentType();

  const handleCreateDocument = useCallback(() => {
    navigate(`/main/finance/invoices/create?documentType=${documentType}`);
  }, [documentType, navigate]);

  return (
    <Flex
      w={'100%'}
      pt={{
        base: '210px',
        md: '80px',
        xl: '80px',
      }}
      flexDirection="column"
      gap="8px">
      <Card
        w="100%"
        h={{ base: 'calc(100vh - 50px)', md: 'calc(100vh - 150px)' }}
        gap="24px"
        p="24px"
        borderRadius={{ base: 0, md: '30px' }}
        justifyContent="center"
        alignItems="center">
        <Flex alignItems="center" gap="24px" flexDirection="column">
          <Image
            src={noInvoicesAndReceipts}
            w={{ base: '200px', md: '300px' }}
          />
          <Text variant="noEventsTitle">
            {t('invoicesAndReceipts.no_documents_created_yet')}
          </Text>
          <Text variant="noInvoicesDescription">
            {t(
              'invoicesAndReceipts.all_the_documents_you_will_create_in_the_future_will_be_stored_here',
            )}
          </Text>
          <DropDownField
            w="320px"
            options={options}
            placeholder={t('invoicesAndReceipts.choose_document_type')}
            onSelected={onSelectedDocumentType}
            showedOptions={showedDocumentType}
            selectedOptions={selectedDocumentType}
            closeOnSelect
          />
          <Button
            variant="h1cta"
            w="320px"
            disabled={!documentType}
            onClick={handleCreateDocument}>
            {t('invoicesAndReceipts.create')}
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
}
