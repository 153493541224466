import { Checkbox, Text } from '@chakra-ui/react';
import { VStack } from '@chakra-ui/react';
import { HStack } from '@chakra-ui/react';
import { Contact } from 'components/contactsPickerDialog/components/Contact';
import { useTranslation } from 'react-i18next';
import { ISharedWith, User } from 'services/@types';

type UserCardProps = {
  user?: Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>;
  externalUser?: Omit<ISharedWith, 'userId'>;
  isSelected?: boolean;
  onSelect?: (
    user: Pick<User, 'id' | 'firstName' | 'lastName' | 'email'> | string,
  ) => void;
};

export default function UserCard(props: UserCardProps) {
  const { t } = useTranslation();
  const { user, externalUser, isSelected, onSelect } = props;

  return user ? (
    <Contact
      color={'brand.200'}
      title={`${user.firstName} ${user.lastName}`}
      clientId={user.id}
      email={user.email}
      isSelected={isSelected}
      onSelect={() => {
        onSelect(user);
      }}
      address=""
    />
  ) : (
    <HStack
      spacing={0}
      gap={2}
      justifyContent={'space-between'}
      w="full"
      alignItems={'flex-start'}>
      <VStack spacing={0} gap={2} alignItems="flex-start">
        <Text
          color="#939393"
          fontSize={'12px'}
          fontWeight={400}
          lineHeight="16px"
          title={externalUser?.email}>
          {externalUser?.email}{' '}
          <span style={{ color: '#FFB422' }}>{t('files.external_user')}</span>
        </Text>
      </VStack>
      <Checkbox
        size="lg"
        borderRadius={'4px'}
        isChecked={!!isSelected}
        value={externalUser?.email}
        variant="gray"
        onChange={() => {
          onSelect(externalUser!.email);
        }}
      />
    </HStack>
  );
}
