import { AxiosInstance, AxiosResponse } from 'axios';
import { IInvoice, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class invoiceService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createInvoice(invoice: IInvoice): Promise<IInvoice> {
    const response: AxiosResponse<IInvoice> = await this.api.post(
      '/invoices',
      invoice,
    );
    return response.data;
  }

  public async getInvoices(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<IInvoice>> {
    const response: AxiosResponse<PaginatedResults<IInvoice>> =
      await this.api.get('/invoices', {
        params: queryParams,
      });
    return response.data;
  }

  public async getInvoice(invoiceId: string): Promise<IInvoice> {
    const response: AxiosResponse<IInvoice> = await this.api.get(
      `/invoices/${invoiceId}`,
    );
    return response.data;
  }

  public async updateInvoice(
    invoiceId: string,
    updates: Partial<IInvoice>,
  ): Promise<IInvoice> {
    delete updates.id;
    delete updates.createdAt;
    delete updates.updatedAt;
    delete updates?.senderBusinessID;
    delete updates?.senderID;
    const response: AxiosResponse<IInvoice> = await this.api.patch(
      `/invoices/${invoiceId}`,
      updates,
    );
    return response.data;
  }

  public async deleteInvoice(invoiceId: string): Promise<void> {
    await this.api.delete(`/invoices/${invoiceId}`);
  }
}

const _invoiceService = new invoiceService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _invoiceService;
