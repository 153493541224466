import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState,
} from 'react';
import { Flex, Icon, Image, Spacer, Text } from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import { Action, Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import EventIcon from 'assets/svg/fab/calendar-fab-icon.svg';
import InvoiceIcon from 'assets/svg/fab/invoice-fab-icon.svg';
import MessagesIcon from 'assets/svg/fab/messages-fab-icon.svg';
import QuoteIcon from 'assets/svg/fab/quote-fab-icon.svg';
import TaskIcon from 'assets/svg/fab/task-fab-icon.svg';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { AppDispatch } from 'contexts/redux/store';
import { useDispatch } from 'react-redux';
import { setInvoiceTypeDialog } from 'contexts/redux/dialog/dialogsSlice';
const actions = [
  {
    title: 'Quote',
    value: 'quote',
    icon: QuoteIcon,
    path: '/main/managment/quotes',
  },
  { title: 'Task', value: 'task', icon: TaskIcon, path: '/main/tasks' },
  {
    title: 'Meeting',
    value: 'meeting',
    icon: MessagesIcon,
    path: '/main/managment/meetings',
  },
  {
    title: 'Event',
    value: 'event',
    icon: EventIcon,
    path: '/main/events',
  },
  {
    title: 'Invoice',
    value: 'invoice',
    icon: InvoiceIcon,
    path: '/main/managment/invoices',
  },
];

export default function FloatingActionButton() {
  const { appNavigate: navigate } = useAppNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const { t, i18n } = useTranslation();
  const actionRefs = useRef([]);
  const [actionWidths, setActionWidths] = useState([]);

  const isRTL = useMemo(() => {
    return i18n.dir() === 'rtl';
  }, [i18n]);

  const fabConfig = useMemo(
    () => ({
      position: {
        right: isRTL ? 'auto' : 0,
        left: isRTL ? 20 : 'auto',
        bottom: 0,
      },
      mainButtonStyles: {
        backgroundColor: '#465D55',
      },
      actionButtonStyles: {
        backgroundColor: '#465D55',
      },
    }),
    [isRTL],
  );

  const handleOnClick = useCallback(
    (action: string) => {
      switch (action) {
        case 'invoice':
          dispatch(setInvoiceTypeDialog({}));
          break;
        case 'event':
          navigate(`/main/events/create`);
          break;
        case 'meeting':
          navigate(`/main/meetings/create`);
          break;
        case 'task':
          navigate(`/main/tasks/create`);
          break;
        case 'quote':
          navigate(`/main/finance/quotes/create`);
          break;
        default:
          console.log('No action');
      }
    },
    [navigate, dispatch],
  );

  useEffect(() => {
    const calculateWidths = () => {
      const widths = actionRefs.current.map((ref) => ref?.offsetWidth || 0);
      setActionWidths(widths);
    };

    calculateWidths();
    window.addEventListener('resize', calculateWidths);

    return () => window.removeEventListener('resize', calculateWidths);
  }, [i18n.language]);

  const itemMaxWidth = useMemo(() => {
    console.debug('language', i18n.language);
    const maxWidth = Math.max(...actionWidths);
    // const calcDiff = maxWidth - actionWidths[index];
    return maxWidth;
  }, [actionWidths, i18n.language]);

  // TODO: fix this
  if (location.pathname.includes('messenger')) {
    return null;
  }

  return (
    <Fab
      mainButtonStyles={fabConfig.mainButtonStyles}
      style={fabConfig.position}
      icon={<Icon fontSize={24} color="white" as={FiPlus} />}
      // event="hover"
      event="click"
      alwaysShowTitle={true}>
      {actions.map((action, index) => (
        <Action
          key={action.value}
          style={{
            ...fabConfig.actionButtonStyles,
            display: 'flex',
            height: '40px',
            width: 'fit-content',
            borderRadius: '30px',
            transform: isRTL ? 'translateX(30%)' : 'translateX(25%)',
            marginBottom: index < actions.length - 1 ? '10px' : '0',
            transition: 'transform 0.3s ease',
          }}
          onClick={() => handleOnClick(action.value)}>
          <Flex
            ref={(el) => (actionRefs.current[index] = el)}
            align="center"
            justify="flex-start"
            ps={4}
            pe={i18n.dir() === 'ltr' ? 4 : 1}
            // bg="green"
            py={2}
            width={itemMaxWidth}
            transition="all 0.2s"
            _hover={{ transform: 'scale(105%)' }}>
            <Text fontWeight="300" fontSize="xs" color="white" ml={2}>
              {t(`fab.${action.value}`)}
            </Text>
            <Spacer />
            <Flex
              width={action.value === 'meeting' ? '24px' : '20px'}
              height="20px"
              ml={2}>
              <Image src={action.icon} />
            </Flex>
          </Flex>
        </Action>
      ))}
    </Fab>
  );
}
