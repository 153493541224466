import { AxiosInstance, AxiosResponse } from 'axios';
import { IApproveQuote, IQuote, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class IQuoteService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createQuote(IQuoteRequest: IQuote): Promise<IQuote> {
    const response: AxiosResponse<IQuote> = await this.api.post(
      '/quotes',
      IQuoteRequest,
    );
    return response.data;
  }

  public async getQuotes(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<IQuote>> {
    const response: AxiosResponse<PaginatedResults<IQuote>> =
      await this.api.get('/quotes', {
        params: queryParams,
      });
    return response.data;
  }

  public async getQuote(id: string): Promise<IQuote> {
    const response: AxiosResponse<IQuote> = await this.api.get(`/quotes/${id}`);
    return response.data;
  }

  public async updateQuote(
    id: string,
    updates: Partial<IQuote>,
    query?: any,
  ): Promise<IQuote> {
    const response: AxiosResponse<IQuote> = await this.api.patch(
      `/quotes/${id}`,
      updates,
      {
        params: query,
      },
    );
    return response.data;
  }

  public async deleteQuote(id: string): Promise<void> {
    await this.api.delete(`/quotes/${id}`);
  }

  public async shareQuote(id: string, formData: FormData): Promise<void> {
    await this.api.post(`/quotes/share`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  // send
  public async sendQuote(id: string, clientId: string): Promise<void> {
    await this.api.post(`/quotes/send/${id}`, { clientId });
  }

  public async getApproveQuote(token: string): Promise<IApproveQuote> {
    const response: AxiosResponse<IApproveQuote> = await this.api.get(
      `/quotes/token/${token}`,
    );
    return response.data;
  }
}

const _IQuoteService = new IQuoteService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _IQuoteService;
