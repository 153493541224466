import { AxiosInstance, AxiosResponse } from 'axios';
import { Product, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class ProductService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createProduct(
    productRequest: Partial<Product>,
  ): Promise<Product> {
    const response: AxiosResponse<Product> = await this.api.post(
      '/products',
      productRequest,
    );
    return response.data;
  }

  public async getProducts(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<Product>> {
    const response: AxiosResponse<PaginatedResults<Product>> =
      await this.api.get('/products', {
        params: queryParams,
      });
    return response.data;
  }

  public async getProduct<T = Product>(
    productId: string,
    queryParams?: Record<string, any>,
  ): Promise<T> {
    let response: AxiosResponse<Product>;
    if (queryParams) {
      response = await this.api.get(`/products/${productId}`, {
        params: queryParams,
      });
    } else {
      response = await this.api.get(`/products/${productId}`);
    }

    return response.data as T;
  }
  public async getProductCategories(businessID: string): Promise<string[]> {
    const response: AxiosResponse<string[]> = await this.api.get(
      `/products/categories?businessID=${businessID}`,
    );
    return response.data;
  }

  public async updateProduct(
    productId: string,
    updates: Partial<Product>,
  ): Promise<Product> {
    const payload = {
      ...updates,
    }
    if (updates.subItems) {
      payload.subItems = updates.subItems.map((item) =>
        typeof item === 'string' ? item : item.id,
      );
    }
    const response: AxiosResponse<Product> = await this.api.patch(
      `/products/${productId}`,
      payload,
    );
    return response.data;
  }

  public async deleteProduct(productId: string): Promise<void> {
    await this.api.delete(`/products/${productId}`);
  }
}

const _productService = new ProductService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _productService;
