import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import Card from 'components/card/Card';
import { RootState } from 'contexts/redux/store';
import { getTasks } from 'contexts/redux/task/taskSlice';
import { motion } from 'framer-motion';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ITask } from 'services/@types';
import TaskHistoryIcon from '../../../../assets/svg/event/clockRewind.svg';
import TaskCard from '../../dashboard/components/TaskCard';

const Tasks = memo(
  ({ isTasksLoading, tasks }: { isTasksLoading: boolean; tasks: ITask[] }) => {
    const { t } = useTranslation();
    const { appNavigate: navigate } = useAppNavigate();
    const params = useParams();
    const memoizedTasks = useMemo(
      () =>
        [...tasks].sort((a, b) => {
          const dateA = new Date((a as ITask).dateAndTime);
          const dateB = new Date((b as ITask).dateAndTime);

          return dateB.getTime() - dateA.getTime();
        }),
      [tasks],
    );

    return (
      <>
        <HStack justifyContent="space-between" mb="8px"></HStack>
        {isTasksLoading && (
          <HStack justifyContent="center" height="auto" align="center">
            <Spinner />
          </HStack>
        )}
        <VStack gap="8px" pb="17px" spacing={0}>
          {tasks?.length === 0 && (
            <VStack height="100%" w="100%">
              <Text variant="taskCardSubTitle">{t('tasks.no_tasks')}</Text>
              <Button
                onClick={() =>
                  navigate(`/main/tasks/create?eventId=${params?.id}`)
                }
                variant="h1cta">
                {t('tasks.create_new_task')}
              </Button>
            </VStack>
          )}
          {memoizedTasks.map((task) => {
            const data = task as ITask;
            return (
              <TaskCard
                key={data.id}
                task={data}
                onTop={data.priority === 'important' ? true : false}
              />
            );
          })}
        </VStack>
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.isTasksLoading === nextProps.isTasksLoading &&
      prevProps.tasks === nextProps.tasks
    );
  },
);

type TaskListProps = {
  isLoading: boolean;
  shouldScroll?: boolean;
};
export default function TaskList({ isLoading, shouldScroll }: TaskListProps) {
  const { appNavigate: navigate } = useAppNavigate();
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('tasks');
  const params = useParams();
  const { tasks } = useSelector((state: RootState) => state.task);
  const dispatch = useDispatch<any>();

  const { isLoading: isTasksLoading } = useQuery({
    queryKey: [`tasks-${params?.id}`],
    queryFn: async () => {
      await dispatch(
        getTasks({
          event: params?.id,
          status: 'pending',
          sortBy: 'priority',
        }),
      );
    },
    enabled: !!params?.id,
  });

  return (
    <Card
      {...style}
      ref={ref}
      minH={{ base: 'unset', lg: '535px' }}
      borderRadius={{ base: 0, md: '30px' }}
      minW={{ base: '100%', lg: '310px' }}
      maxH="535px"
      w="100%"
      h="100%"
      overflowY={'auto'}
      flex={1}
      p="24px 16px 0"
      gap={3}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="cardTitle">{t('event.event_tasks')}</Text>
        <Flex align="center" gap="15px">
          <Flex
            w="24px"
            h="24px"
            bg="#8E7A7033"
            align="center"
            justify="center"
            borderRadius="6px"
            transition="all 0.2s ease-in-out"
            _hover={{ opacity: 0.6 }}
            onClick={() => navigate(`/main/event/${params?.id}/tasks-history`)}>
            <Image
              src={TaskHistoryIcon}
              alt="history"
              w="17px"
              h="17px"
              minW="17px"
              cursor="pointer"
            />
          </Flex>
          <IconButton
            bg="brand.400"
            aria-label="plus"
            w="24px"
            h="24px"
            minW="24px"
            cursor="pointer"
            onClick={() => navigate(`/main/tasks/create?eventId=${params?.id}`)}
            icon={<Icon as={FaPlus} color="warmWhite.100" fontWeight={400} />}
          />
        </Flex>
      </Flex>

      <Flex
        as={motion.div}
        initial={{ opacity: 0, scale: 0.98 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.98 }}
        flexDirection="column"
        overflow={shouldScroll ? 'auto' : 'hidden'}>
        <Tasks isTasksLoading={isTasksLoading} tasks={tasks} />
      </Flex>
    </Card>
  );
}
