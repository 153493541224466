import { Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import 'moment/locale/he';
import { useTranslation } from 'react-i18next';
import { BsCheckCircle } from 'react-icons/bs';
import { INotification } from 'services/@types';
import { getNotificationMomentFormat } from './NotificationComponent';

export default function TaskNotification({
  notification,
  notificationRef,
}: {
  notification: INotification;
  notificationRef: React.RefObject<HTMLDivElement>;
}) {
  const { t, i18n } = useTranslation();

  // Set moment locale based on current language
  moment.locale(i18n.language.startsWith('he') ? 'he' : 'en');

  return (
    <Flex
      ref={notificationRef}
      border="1px solid #F9FAFB"
      borderRadius="12px"
      p="8px"
      flexDirection="column">
      <VStack
        border="1px solid #F9FAFB"
        p="8px"
        borderRadius={'8px'}
        bgColor={'#93C3B31A'}
        spacing={0}>
        <HStack alignItems={'center'} gap="12px">
          <Icon as={BsCheckCircle} color="brand.900" w="24px" h="24px" />
          <VStack spacing={0} alignItems={'flex-start'}>
            <HStack spacing={0}>
              <Text variant="cardSubTitle" color="#1B2037" lineHeight={'20px'}>
              {t(notification.message, {
                  ...notification?.variables,
                  ...{
                    dueDate: moment(
                      new Date(notification?.variables?.dueDate),
                    ).format('D MMM, YYYY'),
                  },
                })}
              </Text>
            </HStack>
          </VStack>
        </HStack>
        <Text fontSize="md" color="gray.500" alignSelf="flex-end">
          {moment(notification?.createdAt).format(
            getNotificationMomentFormat(i18n.language),
          )}
        </Text>
      </VStack>
    </Flex>
  );
}
