import { useDisclosure, useToast } from '@chakra-ui/react';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useBusinessCategories } from 'queries/businessCategory';
import { useGetInviteTokenData } from 'queries/invite';
import { useSupplierDetailsRequest } from 'queries/userActions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ContactPerson, Invite, Supplier } from 'services/@types';
import { isValidEmail } from 'utils/isValidEmail';
import { isValidPhone } from 'utils/isValidPhone';

const useSupplierDetails = () => {
  const { appNavigate: navigate } = useAppNavigate();

  const toast = useToast();
  const { t } = useTranslation();
  const [hasSentSuccessfully, setHasSentSuccessfully] =
    useState<boolean>(false);
  const {
    isOpen: isContactMenuOpen,
    onOpen: onContactMenuOpen,
    onClose: onContactMenuClose,
  } = useDisclosure();
  const [supplierProps, setSupplierProps] = useState<Partial<Supplier>>({});
  const [errorLabels, setErrorLabels] = useState<{
    name: string;
    email: string;
    category: string;
    contacts: { email?: string; phone?: string }[];
  }>({
    name: '',
    email: '',
    category: '',
    contacts: [],
  });

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { data } = useGetInviteTokenData(token);
  const { mutateAsync: createSupplierRequest } = useSupplierDetailsRequest();

  const { data: businessCategoriesData } = useBusinessCategories();
  const businessCategories = businessCategoriesData?.results || [];
  const invite = useMemo(() => {
    if (!data) return null;
    return data?.invite as Invite;
  }, [data]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorLabels({ name: '', email: '', category: '', contacts: [] });
      setSupplierProps({ ...supplierProps, [e.target.id]: e.target.value });
    },
    [supplierProps],
  );
  const handleChangeBankDetails = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorLabels({
        name: '',
        email: '',
        category: '',
        contacts: [],
      });
      setSupplierProps({
        ...supplierProps,
        bankDetails: {
          ...supplierProps.bankDetails,
          [e.target.id]: e.target.value,
        },
      });
    },
    [supplierProps],
  );
  const onAddContact = useCallback(() => {
    if (supplierProps?.contacts?.length === 2) {
      return;
    }
    if (!supplierProps.contacts || supplierProps.contacts?.length === 0) {
      setSupplierProps((prev) => ({ ...prev, contacts: [{}] }));
    }
    setSupplierProps((prev) => ({
      ...prev,
      contacts: [
        ...prev.contacts,
        {
          fullName: '',
          email: '',
          phone: '',
        },
      ],
    }));
  }, [supplierProps]);
  const onRemoveContactB = useCallback(() => {
    if (supplierProps.contacts?.length === 2) {
      supplierProps.contacts = [supplierProps.contacts[0]];
    }
  }, [supplierProps]);
  const handleContactChange = useCallback(
    (contactDetails: ContactPerson, index: number) => {
      setErrorLabels({
        name: '',
        email: '',
        category: '',
        contacts: [],
      });
      if (Object.keys(contactDetails).length === 0) {
        return;
      }
      setSupplierProps((prevProps) => {
        const newContacts = prevProps.contacts ? [...prevProps.contacts] : [];
        newContacts[index] = contactDetails;
        return { ...prevProps, contacts: newContacts };
      });
    },
    [],
  );
  const validate = useCallback(() => {
    let isValid = true;
    if (!supplierProps.name) {
      setErrorLabels((prev) => ({
        ...prev,
        name: t('suppliers.errors.name'),
      }));
      isValid = false;
    }
    if (!supplierProps.email) {
      setErrorLabels((prev) => ({
        ...prev,
        email: t('suppliers.errors.email'),
      }));
      isValid = false;
    }
    if (!isValidEmail(supplierProps.email)) {
      setErrorLabels((prev) => ({
        ...prev,
        email: t('suppliers.errors.invalid_email'),
      }));
      isValid = false;
    }
    if (!supplierProps.category || supplierProps.category.length === 0) {
      setErrorLabels((prev) => ({
        ...prev,
        category: t('suppliers.errors.category'),
      }));
      isValid = false;
    }

    if (supplierProps.contacts?.length > 0) {
      supplierProps.contacts.forEach((contact) => {
        if (
          !contact.email ||
          !isValidEmail(contact.email) ||
          !contact.phone ||
          !isValidPhone(contact.phone)
        ) {
          isValid = false;
        }
        setErrorLabels((prev) => {
          const newContacts = [...prev.contacts];
          const newContactErrors: { email?: string; phone?: string } = {};
          if (!contact.email || !isValidEmail(contact.email)) {
            newContactErrors.email = t('suppliers.errors.invalid_email');
          }
          if (!contact.phone || !isValidPhone(contact.phone)) {
            newContactErrors.phone = t('suppliers.errors.invalid_phone');
          }
          if (Object.keys(newContactErrors).length > 0) {
            newContacts.push(newContactErrors);
          } else {
            newContacts.push({});
          }
          return { ...prev, contacts: newContacts };
        });
      });
    }

    return isValid;
  }, [
    supplierProps?.name,
    supplierProps?.email,
    supplierProps?.category,
    t,
    supplierProps?.contacts,
  ]);

  const handleSave = useCallback(async () => {
    if (!validate()) {
      return;
    }
    try {
      const newSupplier = await createSupplierRequest({
        supplierProps,
        token,
      });
      console.log('create supplier ->', newSupplier);
      setHasSentSuccessfully(true);
    } catch (error) {
      console.error(error);
      toast({
        variant: 'error',
        position: 'top-right',
        title: t('supplier_details.errors.supplier_creation_failed'),
        status: 'error',
      });
    }
  }, [supplierProps, t, toast, token, validate, createSupplierRequest]);

  const handleNavigateToSignUp = useCallback(() => {
    navigate('/auth/sign-up');
  }, [navigate]);

  useEffect(() => {
    if (invite) {
      if (invite.status !== 'pending') {
        navigate('/');
      }
      setSupplierProps({
        name: invite.fullName,
        email: invite.email,
      });
    }
  }, [invite, navigate]);

  return {
    supplierProps,
    setSupplierProps,
    errorLabels,
    setErrorLabels,
    handleChange,
    businessCategories,
    handleChangeBankDetails,
    isContactMenuOpen,
    onContactMenuOpen,
    onContactMenuClose,
    onAddContact,
    onRemoveContactB,
    handleContactChange,
    handleSave,
    hasSentSuccessfully,
    handleNavigateToSignUp,
  };
};
export default useSupplierDetails;
