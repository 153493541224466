import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import _notificationService from 'services/notification.api';

interface UnreadNotificationsCount {
  count: number;
}

interface UseGetUnreadNotificationsCountOptions {
  enabled?: boolean;
}

export const getUnreadNotificationsCountQueryKey = (): QueryKey => [
  'unreadNotificationsCount',
];

const getUnreadNotificationsCountQueryOptions = (
  notificationId?: string | null,
) => ({
  queryKey: [getUnreadNotificationsCountQueryKey(), notificationId],
  queryFn: () => _notificationService.getUnreadNotifications(),
  staleTime: Infinity, // make it unstale
});

const useGetUnreadNotificationsCount = (
  notificationId: string | null,
  options: UseGetUnreadNotificationsCountOptions = {},
): UseQueryResult<UnreadNotificationsCount, Error> => {
  return useQuery({
    ...getUnreadNotificationsCountQueryOptions(notificationId),
  });
};

useGetUnreadNotificationsCount.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
) => {
  await queryClient.prefetchQuery(getUnreadNotificationsCountQueryOptions());
};

useGetUnreadNotificationsCount.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
) => {
  queryClient.invalidateQueries({
    queryKey: getUnreadNotificationsCountQueryKey(),
  });
};

export default useGetUnreadNotificationsCount;
