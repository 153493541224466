import { useToast } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  closeAddTrackerCustomEventDialog,
  setContactsPickerDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { createTrackedEvent } from 'contexts/redux/trackedEvent/trackedEventSlice';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  IClient,
  IClientContact,
  ITrackerCustomEvent,
  Supplier,
  UrlItem,
} from 'services/@types';
import UploadService from 'services/upload.api';
import { TRACKER_CUSTOM_EVENT_TYPES } from '../constants';
export default function useAddTrackerCustomEvent() {
  const { t } = useTranslation();
  const dialog = useSelector(
    (state: RootState) => state.dialogs.addTrackerCustomEventDialog,
  );
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const myBusiness = useMyBusiness();
  const myUser = useMyUser();
  const [loading, setLoading] = useState(false);
  const [customEvent, setCustomEvent] = useState<ITrackerCustomEvent>({
    type: '',
    meetingDateAndTime: new Date(),
    title: '',
    participants: {
      suppliers: [],
      clients: [],
    },
    media: [],
    eventId: '',
    notes: [],
  });

  const eventId = useMemo(() => dialog?.item?.eventId, [dialog?.item?.eventId]);

  const startLoading = useCallback(() => {
    setLoading(true);
  }, []);

  const stopLoading = useCallback(() => {
    setLoading(false);
  }, []);

  const typeOptions = useMemo(
    () =>
      TRACKER_CUSTOM_EVENT_TYPES.map((type) => ({
        label: t(`tracker.custom_event_type.${type}`),
        value: type,
      })),
    [t],
  );

  const selectedOptions = useMemo(() => {
    const selected = typeOptions.find((o) => o.value === customEvent?.type);
    return selected ? [selected] : [];
  }, [typeOptions, customEvent?.type]);

  const showedOptions = useMemo(() => {
    const showed = typeOptions.find((o) => o.value === customEvent?.type);
    return showed ? t(`tracker.custom_event_type.${showed.value}`) : null;
  }, [typeOptions, customEvent?.type, t]);

  const handleChange = useCallback((name: string, value: string | Date) => {
    setCustomEvent((prev) => ({ ...prev, [name]: value }));
  }, []);

  const onClose = useCallback(() => {
    // clear custom event
    setCustomEvent({
      type: '',
      meetingDateAndTime: new Date(),
      title: '', // note title
      participants: {
        suppliers: [],
        clients: [],
      },
      media: [], // note media
      eventId: '',
      notes: [],
    });
    dispatch(closeAddTrackerCustomEventDialog());
  }, [dispatch]);

  const handleOpenSelectContactsDialog = useCallback(() => {
    dispatch(
      setContactsPickerDialog({
        item: {
          eventId: dialog?.item?.eventId,
          chosenSuppliers: customEvent.participants.suppliers || [],
          chosenClients: customEvent.participants.clients || [],
          type: 'contacts',
        },
        onConfirm: (v: {
          clients: {
            client: IClient;
            contacts: IClientContact[];
          }[];
          suppliers: {
            supplier: Supplier;
          }[];
        }) => {
          setCustomEvent((prev) => ({
            ...prev,
            participants: {
              ...prev.participants,
              suppliers: v.suppliers.map((s) => s.supplier),
              clients: v.clients.map((c) => c.client),
            },
          }));
        },
      }),
    );
  }, [
    dispatch,
    dialog?.item?.eventId,
    customEvent.participants.suppliers,
    customEvent.participants.clients,
  ]);

  const handleChangeTime = useCallback(
    (e: string) => {
      const newDateAndTime = new Date(customEvent?.meetingDateAndTime);
      newDateAndTime.setHours(Number(e.split(':')[0]));
      newDateAndTime.setMinutes(Number(e.split(':')[1]));
      handleChange('meetingDateAndTime', new Date(newDateAndTime));
    },
    [customEvent?.meetingDateAndTime, handleChange],
  );

  const handleAddCustomEventFile = useCallback(
    (urlItem: UrlItem) => {
      console.log('urlItem', urlItem);
      setCustomEvent((prev) => ({
        ...prev,
        media: [...prev.media, urlItem],
      }));
      stopLoading();
    },
    [stopLoading],
  );

  const handleSaveCustomEvent = useCallback(async () => {
    const newCustomEvent = customEvent;
    newCustomEvent.participants = {
      suppliers: customEvent.participants.suppliers.map(
        (supplier) => (supplier as Supplier)?.id,
      ) as string[],
      clients: customEvent.participants.clients.map(
        (client) => (client as IClient)?.id,
      ) as string[],
    };

    if (newCustomEvent.title !== '') {
      newCustomEvent.notes = [
        {
          text: newCustomEvent.title,
          creator: myUser?.id,
        },
      ];
    }

    if (newCustomEvent.media.length > 0) {
      if (newCustomEvent.notes.length > 0) {
        newCustomEvent.notes[0].media = newCustomEvent.media;
      } else {
        newCustomEvent.notes = [
          {
            media: newCustomEvent.media,
            creator: myUser?.id,
          },
        ];
      }

      newCustomEvent.media = [];
    }

    startLoading();

    const response = await dispatch(
      createTrackedEvent({
        ...newCustomEvent,
        eventId,
        businessId: myBusiness?.id,
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      onClose();
      toast({
        title: t('tracker.add_custom_event_dialog.success'),
        variant: 'main',
        position: 'top-right',
      });
      queryClient.invalidateQueries({
        queryKey: ['trackedEvents', eventId],
      });
    }
    stopLoading();
  }, [
    customEvent,
    dispatch,
    onClose,
    toast,
    t,
    eventId,
    myBusiness?.id,
    startLoading,
    stopLoading,
    queryClient,
    myUser?.id,
  ]);

  const handleRemoveCustomEventFile = useCallback(async () => {
    const [target, targetId, access, fileId] =
      customEvent?.media?.[0]?.url.split('/');
    await UploadService.deleteUpload({
      target,
      targetId,
      access,
      fileId,
    });
    setCustomEvent((prev) => ({
      ...prev,
      media: [],
    }));
  }, [customEvent]);

  const handleRemoveSupplier = useCallback((supplierId: string) => {
    setCustomEvent((prev) => ({
      ...prev,
      participants: {
        ...prev.participants,
        suppliers: (prev.participants?.suppliers as Supplier[])?.filter(
          (supplier) => supplier?.id !== supplierId,
        ),
      },
    }));
  }, []);

  const handleRemoveClient = useCallback((clientId: string) => {
    setCustomEvent((prev) => ({
      ...prev,
      participants: {
        ...prev.participants,
        clients: (prev.participants?.clients as IClient[])?.filter(
          (client) => client?.id !== clientId,
        ),
      },
    }));
  }, []);

  return {
    customEvent,
    handleChange,
    onClose,
    handleOpenSelectContactsDialog,
    typeOptions,
    selectedOptions,
    showedOptions,
    handleChangeTime,
    handleAddCustomEventFile,
    handleSaveCustomEvent,
    dialog,
    handleRemoveCustomEventFile,
    loading,
    startLoading,
    handleRemoveSupplier,
    handleRemoveClient,
    stopLoading,
  };
}
