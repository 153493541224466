import { TbMapPin } from 'react-icons/tb';

import { Icon } from '@chakra-ui/react';

import { Checkbox, Text, VStack } from '@chakra-ui/react';

import { HStack } from '@chakra-ui/react';
import { TbMailOpened } from 'react-icons/tb';

export const Contact = ({
  color,
  title,
  clientId,
  email,
  address,
  isSelected,
  onSelect,
  isInner,
  hideCheckbox,
}: {
  color: string;
  title: string;
  clientId: string;
  email: string;
  address: string;
  isSelected: boolean;
  onSelect: () => void;
  isInner?: boolean;
  hideCheckbox?: boolean;
}) => {
  return (
    <HStack
      p={'12px'}
      overflow="hidden"
      borderRadius={'16px'}
      w="full"
      color={'#939393'}
      alignItems={'center'}
      gap={'10px'}
      spacing={0}
      fontWeight={400}
      fontSize={'12px'}
      border={'1px solid'}
      borderColor={color}
      lineHeight="16px"
      flexShrink={0}
      {...(isInner && {
        borderColor: 'transparent',
        bg: '#93C3B31A',
      })}>
      <VStack
        spacing={0}
        alignItems={'flex-start'}
        flex={1}
        w="full"
        gap={2}
        overflow="hidden">
        <HStack
          spacing={0}
          gap={2}
          justifyContent={'space-between'}
          w="full"
          alignItems={'flex-start'}>
          <VStack spacing={0} gap={2} alignItems="flex-start">
            <Text
              color="#141718"
              fontSize={'14px'}
              fontWeight={700}
              lineHeight="19px"
              textTransform="capitalize"
              title={title}>
              {title}
            </Text>
          </VStack>
          {!hideCheckbox && (
            <Checkbox
              size="lg"
              borderRadius={'4px'}
              isChecked={!!isSelected}
              value={clientId}
              variant="gray"
              onChange={onSelect}
            />
          )}
        </HStack>
        <HStack spacing={0} gap={3} flex={1} w="full">
          <HStack
            overflow="hidden"
            isTruncated
            spacing={0}
            gap={1}
            alignItems="center">
            <Icon as={TbMailOpened} fontSize={'16px'} />
            <Text title={email}>{email}</Text>
          </HStack>
          {address && (
            <HStack
              w="full"
              overflow="hidden"
              isTruncated
              spacing={0}
              gap={1}
              flex={1}
              alignItems="center">
              <Icon as={TbMapPin} fontSize={'16px'} />
              <Text title={address}>{address}</Text>
            </HStack>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
};
