const calculateGross = ({
  amount,
  vat,
  isVatIncluded,
}: {
  amount: string;
  vat: number;
  isVatIncluded: boolean;
}): string => {
  const amountNumber = Number(amount);
  const vatNumber = Number(vat);
  if (isVatIncluded) {
    return (amountNumber + (amountNumber * vatNumber) / 100).toString();
  }
  return amountNumber.toString();
};

const calculateVat = ({
  amount,
  vat,
  isVatIncluded,
}: {
  amount: string;
  vat: number;
  isVatIncluded: boolean;
}): string => {
  const amountNumber = Number(amount);
  const vatNumber = Number(vat);
  if (isVatIncluded) {
    return ((amountNumber * vatNumber) / 100).toString();
  }
  return '0';
};

const calculateTotalAmount = ({
  gross,
  discount,
}: {
  gross: string;
  discount: string;
}): string => {
  const grossNumber = Number(gross);
  const discountNumber = Number(discount);
  return (grossNumber - discountNumber).toString();
};

const calculateTotalVat = ({
  vat,
  totalAmount,
  isVatIncluded,
}: {
  vat: number;
  totalAmount: string;
  isVatIncluded: boolean;
}): string => {
  const totalAmountNumber = Number(totalAmount);
  if (isVatIncluded) {
    return ((totalAmountNumber * vat) / 100).toString();
  }
  return '0';
};

const calculateAmountToPay = ({
  vat,
  totalAmount,
  isVatIncluded,
}: {
  vat: number;
  totalAmount: string;
  isVatIncluded: boolean;
}): string => {
  const totalAmountNumber = Number(totalAmount);
  const vatNumber = Number(vat);
  if (isVatIncluded) {
    return (
      totalAmountNumber +
      (totalAmountNumber * vatNumber) / 100
    ).toString();
  }
  return totalAmountNumber.toString();
};

export {
  calculateGross,
  calculateVat,
  calculateTotalAmount,
  calculateTotalVat,
  calculateAmountToPay,
};
