import { HStack, Icon, IconButton, Text, VStack } from '@chakra-ui/react';

import { useCallback, useMemo } from 'react';

import { BusinessCategory } from 'services/@types';

import { IClientContact } from 'services/@types';

import { IClient } from 'services/@types';

import { useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Business } from 'services/@types';
import { LuChevronDown } from 'react-icons/lu';
import { TbMapPin } from 'react-icons/tb';
import { TbMailOpened } from 'react-icons/tb';
import { Contact } from './Contact';

export const ClientBusiness = ({
  client,
  business,
  selectedContacts,
  onSelect,
  hideCheckbox,
}: {
  client: IClient;
  business: Omit<Business, 'category'> & { category: BusinessCategory[] };
  selectedContacts?: { client: IClient; contacts: IClientContact[] } | null;
  onSelect: (clientId: string, contactId?: string) => void;
  hideCheckbox?: boolean;
}) => {
  const { isOpen, onOpen, onToggle } = useDisclosure({
    defaultIsOpen: !!hideCheckbox
  });
  useEffect(() => {
    if (selectedContacts?.contacts?.length > 0) {
      onOpen();
    }
  }, [onOpen, selectedContacts]);

  const { name, category, email, address, contacts } = useMemo(() => {
    const obj = {
      name: client.company,
      category: business?.category?.map((c) => c.name).join(', '),
      email: client.email,
      address: client.address,
      contacts: !client?.contacts
        ? []
        : client.contacts?.map((c) => ({
            id: c.id,
            name: `${c.firstName} ${c.lastName}`,
            email: c.email,
            phone: c.phone,
            address: c.address,
            isSelected: selectedContacts?.contacts?.some(
              (contact) => contact?.id === c?.id,
            ),
          })),
    };
    return obj;
  }, [business?.category, client, selectedContacts]);

  const maxHeight = useMemo(() => {
    if (contacts?.length < 1) return 0;
    const itemsTotalLength = contacts.length * 70;
    const gapTotalLength = (contacts.length - 1) * 4;
    const spacesTotalLength = 12;
    return itemsTotalLength + gapTotalLength + spacesTotalLength;
  }, [contacts?.length]);

  const handleSelectContact = useCallback(
    (contactId: string) => {
      onSelect(client.id, contactId);
    },
    [client.id, onSelect],
  );

  return (
    <VStack
      p={'12px'}
      overflow="hidden"
      borderRadius={'16px'}
      w="full"
      color={'#939393'}
      alignItems={'flex-start'}
      spacing={0}
      fontWeight={400}
      fontSize={'12px'}
      border={'1px solid'}
      borderColor={'brand.900'}
      lineHeight="16px"
      flexShrink={0}>
      <VStack
        spacing={0}
        alignItems={'flex-start'}
        flex={1}
        w="full"
        gap={2}
        overflow="hidden">
        <HStack
          spacing={0}
          gap={2}
          justifyContent={'space-between'}
          w="full"
          alignItems={'flex-start'}>
          <VStack spacing={0} gap={2} alignItems="flex-start">
            <Text
              color="#141718"
              fontSize={'14px'}
              fontWeight={700}
              lineHeight="19px"
              textTransform="capitalize"
              title={name}>
              {name}
            </Text>
            {category && (
              <Text
                color="brand.900"
                fontSize="12px"
                lineHeight={'16px'}
                fontWeight={700}
                textTransform="capitalize">
                {category}
              </Text>
            )}
          </VStack>
          {/* toggle contact list trigger */}
          <IconButton
            aria-label="close"
            icon={
              <Icon
                as={LuChevronDown}
                w="16px"
                h="16px"
                color="#939393"
                transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                transition="all 0.2s ease-in-out"
              />
            }
            size="xs"
            variant="solid"
            bg={'transparent'}
            onClick={onToggle}
          />
        </HStack>
        <HStack spacing={0} gap={3} flex={1} w="full">
          <HStack
            overflow="hidden"
            isTruncated
            spacing={0}
            gap={1}
            alignItems="center">
            <Icon as={TbMailOpened} fontSize={'16px'} />
            <Text title={email}>{email}</Text>
          </HStack>
          {address && (
            <HStack
              w="full"
              overflow="hidden"
              isTruncated
              spacing={0}
              gap={1}
              flex={1}
              alignItems="center">
              <Icon as={TbMapPin} fontSize={'16px'} />
              <Text title={address}>{address}</Text>
            </HStack>
          )}
        </HStack>
      </VStack>
      <VStack
        spacing={0}
        overflow="hidden"
        mt={isOpen ? '12px !important' : 0}
        pt={isOpen ? 3 : 0}
        height={isOpen ? (maxHeight === 0 ? 'auto' : `${maxHeight}px`) : 0}
        borderTop={'1px solid'}
        gap={1}
        alignItems={'flex-start'}
        w="full"
        borderColor={isOpen ? '#E8E8E8' : 'transparent'}
        transition="all 0.2s ease-in-out">
        {contacts?.map((contact) => (
          <Contact
            hideCheckbox={hideCheckbox}
            isInner
            key={contact.id}
            color={'brand.200'}
            title={contact.name}
            clientId={client.id}
            email={contact.email}
            address={contact.address}
            isSelected={contact.isSelected}
            onSelect={() => {
              handleSelectContact(contact.id);
            }}
          />
        ))}
      </VStack>
    </VStack>
  );
};
