import { useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { fetchProducts } from 'contexts/redux/product/productSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from 'services/@types';
import ProductService from 'services/product.api';

interface IInvoiceProductProps {
  product: any;
  updateProductByCurrencyChange: (productData: any, index: number) => void;
  index: number;
}

export default function useInvoiceProduct(props: IInvoiceProductProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { product, updateProductByCurrencyChange, index } = props;
  const [currentEditableProduct, setCurrentEditableProduct] =
    useState<Partial<Product>>(null);
  const [showOptions, setShowOptions] = useState<boolean>(true);
  const [isPackageInfoVisible, setIsPackageInfoVisible] = useState(false);
  const { products } = useSelector((state: RootState) => ({
    products: state.product.products,
  }));

  const {
    isOpen: isAddProductOpen,
    onOpen: onOpenAddProduct,
    onClose: onAddProductClosed,
  } = useDisclosure();

  const { data: productData } = useQuery({
    queryKey: ['product', product.productId],
    queryFn: () => ProductService.getProduct(product.productId),
    enabled: !!product.productId,
  });

  const memoProducts = useMemo(() => {
    return products?.map((p) => ({
      value: p.id,
      label: p.name,
    }));
  }, [products]);

  const isPackage = useMemo(() => {
    return (
      Array.isArray(productData?.subItems) && productData?.subItems?.length > 0
    );
  }, [productData?.subItems]);

  const onOptionsRendered = useCallback(
    (options) => {
      const showOptions =
        options?.length === 0 && product?.description?.length > 0;
      setShowOptions(!showOptions);
    },
    [product?.description?.length],
  );

  const handleAddProductClosed = useCallback(() => {
    onAddProductClosed();
    setCurrentEditableProduct(null);
  }, [onAddProductClosed]);

  const handlePackageInfoVisibility = useCallback(() => {
    setIsPackageInfoVisible((prev) => !prev);
  }, []);

  // handle currency change
  useEffect(() => {
    updateProductByCurrencyChange(productData, index);
  }, [updateProductByCurrencyChange, productData, index]);

  useEffect(() => {
    dispatch(fetchProducts({}));
  }, [dispatch]);

  return {
    memoProducts,
    isPackage,
    onOptionsRendered,
    isAddProductOpen,
    onOpenAddProduct,
    currentEditableProduct,
    handleAddProductClosed,
    isPackageInfoVisible,
    handlePackageInfoVisibility,
    productData,
    showOptions,
  };
}
