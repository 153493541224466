import { IClient, Supplier } from 'services/@types';

export const trimAndLowerCase = (str: string) => str.toLowerCase().trim();

export const convertToClient = (supplier: Supplier): Partial<IClient> => {
  const [firstName, lastName] = supplier?.name?.split(' ') || [];
  return {
    email: supplier.email,
    phone: supplier.phone,
    address: supplier.address,
    // if exist
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    // remove is no needed
    idNumber: supplier.vatId,
    businessID: supplier.businessID,
  };
};

export const convertToSupplier = (client: IClient): Partial<Supplier> => {
  const supplierName = client?.firstName + ' ' + client?.lastName;
  return {
    email: client.email,
    phone: client.phone,
    address: client.address,
    name: supplierName,
    vatId: client?.idNumber,
    businessID: client?.businessID,
  };
};
