import {
  Badge,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { format } from 'date-fns';
import { enGB, he } from 'date-fns/locale';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HiOutlineMailOpen } from 'react-icons/hi';
import { IoLocationOutline } from 'react-icons/io5';
import { LuX } from 'react-icons/lu';
import { MdClose } from 'react-icons/md';
import { BusinessEventPlan, IEvent } from 'services/@types';
import _businessEventPlanService from 'services/businessEventPlan.api';
interface EventCardProps {
  event: Partial<IEvent>;
  onClick?: (event: IEvent) => void;
  isQuoteEvent?: boolean;
  cursor?: string;
  handleClickXIcon?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  [key: string]: any;
}
export default function EventCard(props: EventCardProps) {
  const {
    event,
    onClick,
    isQuoteEvent = false,
    cursor,
    handleClickXIcon,
    ...rest
  } = props;
  const { t, i18n } = useTranslation();
  const month = useMemo(
    () =>
      event?.dateAndTime
        ? format(new Date(event.dateAndTime), 'MMM', {
            locale: i18n?.language?.startsWith('he') ? he : enGB,
          })
        : new Date(),
    [event?.dateAndTime, i18n?.language],
  );
  const date = useMemo(
    () =>
      event?.dateAndTime
        ? format(new Date(event.dateAndTime), 'dd')
        : new Date(),
    [event?.dateAndTime],
  );
  const businessId = useMyBusiness()?.id;

  const { data: businessEventPlan } = useQuery<BusinessEventPlan | null>({
    queryKey: [`getEventParticipants-${event?.id}-${businessId}`],
    queryFn: async () => {
      const result = await _businessEventPlanService.getBusinessEventPlans({
        eventId: event?.id,
        populate: 'businessId',
      });

      const data = result.results[0];
      return data;
    },
    enabled: !!event?.id && !!businessId,
  });

  if (!event) return null;

  return (
    <Flex pos="relative" w="100%" h="50px" {...rest}>
      <VStack
        width="100%"
        border="1px solid"
        borderColor={
          !isQuoteEvent ? `eventStatus.${businessEventPlan?.status}` : '#5FBF96'
        }
        borderRadius="16px"
        transition="all 0.3s ease"
        cursor={cursor || 'pointer'}
        _hover={{ bg: '#F5F5F5' }}
        //   pb={!isQuoteEvent && '20px'}
        // py={!isQuoteEvent ? 6 : 2}
        onClick={() => onClick && onClick(event as IEvent)}
        justifyContent="center"
        h="50px"
        {...rest}>
        <Flex w="100%" gap={5} px={6} {...(props.onDelete && { pr: 1 })}>
          <VStack textAlign="center" spacing={0} justifyContent="center">
            <Text variant="eventCardDate" fontSize="xs" lineHeight="12px">
              {month}
            </Text>
            <Text variant="eventCardDate">{date}</Text>
          </VStack>
          <VStack
            alignItems="start"
            justifyContent="center"
            spacing={0}
            w="100%">
            <Text variant="eventCardTitle">{event.name}</Text>
            {!isQuoteEvent && (
              <Text variant="eventCardSubTitle">{event.name}</Text>
            )}
            <HStack w="100%" justifyContent="space-between">
              {event?.numberOfGuests && (
                <HStack>
                  <Icon as={HiOutlineMailOpen} color="general.100" />
                  <Text
                    color="general.100"
                    noOfLines={1}
                    pt={1}
                    fontWeight="400"
                    fontSize={{ base: 'sm', sm: 'xs', '2xl': 'sm' }}
                    wordBreak={'break-all'}>
                    {t('create_event.modals.found_event.invited', {
                      numberOfGuests: event?.numberOfGuests,
                      numberOfChildren: event?.numberOfGuestsChildren,
                    })}
                  </Text>
                </HStack>
              )}
              {event?.location?.label && (
                <HStack maxW={160}>
                  <Icon as={IoLocationOutline} color="general.100" />
                  <Text
                    color="general.100"
                    noOfLines={1}
                    pt={0}
                    fontWeight="400"
                    fontSize={{ base: 'sm', sm: 'xs', '2xl': 'sm' }}
                    wordBreak={'break-all'}>
                    {event?.location?.label}
                  </Text>
                </HStack>
              )}
            </HStack>
          </VStack>
          {handleClickXIcon && (
            <IconButton
              icon={<LuX />}
              onClick={handleClickXIcon}
              aria-label="close"
              color="#00000066"
              w="20px"
              h="20px"
              minW="20px"
              maxW="20px"
              cursor="pointer"
              variant="unstyled"
              alignSelf="center"
              ml="auto"
            />
          )}
          {props.onDelete && (
            <IconButton
              onClick={props.onDelete}
              aria-label="delete"
              icon={<Icon as={MdClose} />}
            />
          )}
        </Flex>
      </VStack>
      {!isQuoteEvent && (
        <HStack justifyContent="end" pos="absolute" top="0" px={4} width="100%">
          <Badge
            borderBottomLeftRadius="15px"
            borderBottomRightRadius="15px"
            borderTopLeftRadius="0px"
            borderTopRightRadius="0px"
            bg={`eventStatus.${businessEventPlan?.status || 'new'}`}
            paddingLeft="15px"
            paddingRight="15px">
            {t(`event.status.${businessEventPlan?.status || 'new'}`)}
          </Badge>
        </HStack>
      )}
    </Flex>
  );
}
