import { ChangeEvent, useState } from 'react';

import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  AppDialogProps,
  closeRequestSupplierDetailsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useCreateSupplierInvite } from 'queries/invite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose, MdContentCopy } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { isValidEmail } from 'utils/isValidEmail';

const RequestSupplierDetailsDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const myBusiness = useMyBusiness();
  const toast = useToast();
  const { t } = useTranslation();
  const { requestSupplierDetailsDialog } = useSelector((state: RootState) => ({
    requestSupplierDetailsDialog: state.dialogs.requestSupplierDetailsDialog,
  }));
  const dialog = requestSupplierDetailsDialog as AppDialogProps;

  const [supplierEmail, setSupplierEmail] = useState<string>('');
  const [supplierName, setSupplierName] = useState<string>('');
  const [supplierComment, setSupplierComment] = useState<string>('');
  const [errorList, setErrorList] = useState<string[]>([]);

  const { mutateAsync: createInvite } = useCreateSupplierInvite();

  const handleClose = useCallback(() => {
    setSupplierEmail('');
    setSupplierName('');
    setSupplierComment('');
    setErrorList([]);
    dispatch(closeRequestSupplierDetailsDialog());
  }, [dispatch]);

  const validateData = useCallback(() => {
    if (!isValidEmail(supplierEmail)) {
      setErrorList(['email']);
      return false;
    }
    if (!supplierName) {
      setErrorList(['name']);
      return false;
    }
    return true;
  }, [supplierEmail, supplierName]);

  const handleSubmit = useCallback(() => {
    const isValid = validateData();
    if (isValid) {
      if (dialog?.onConfirm) {
        dialog?.onConfirm({ supplierEmail, supplierName, supplierComment });
      }
      handleClose();
    }
  }, [
    dialog,
    supplierEmail,
    supplierName,
    supplierComment,
    handleClose,
    validateData,
  ]);

  const handleCopyClipboard = useCallback(
    (url: string) => {
      navigator.clipboard.writeText(url);
      toast({
        title: t('suppliers.toast.link_copied'),
        status: 'success',
        variant: 'main',
      });
    },
    [t, toast],
  );

  const handleCopyInvitationLink = useCallback(async () => {
    const isValid = validateData();
    if (!isValid) {
      return;
    }
    try {
      const response = await createInvite({
        email: supplierEmail,
        fullName: supplierName,
        businessId: myBusiness?.id,
        serviceDescription: supplierComment,
      });

      toast({
        variant: 'main',
        title: t('suppliers.toast.supplier_details_request_sent'),
        status: 'success',
      });

      const invitationLink = response.redirectUrl;
      handleCopyClipboard(invitationLink);
    } catch (error) {
      console.error('Error copying invitation link:', error);
      if (error instanceof AxiosError && error?.response?.status === 409) {
        toast({
          variant: 'error',
          title: t('suppliers.toast.supplier_details_request_already_sent'),
          status: 'error',
        });
      } else {
        toast({
          variant: 'error',
          title: t('suppliers.toast.supplier_details_request_error'),
          status: 'error',
        });
      }
    }
  }, [
    validateData,
    supplierEmail,
    supplierName,
    supplierComment,
    myBusiness?.id,
    toast,
    t,
    handleCopyClipboard,
    createInvite,
  ]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      if (errorList.includes(name)) {
        setErrorList((prev) => prev.filter((f) => f !== name));
      }
      switch (name) {
        case 'email':
          setSupplierEmail(value);
          break;
        case 'name':
          setSupplierName(value);
          break;
        case 'comment':
          setSupplierComment(value);
          break;
      }
    },
    [errorList],
  );

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px" overflow="hidden">
        <ModalBody p="24px" pt={0}>
          <Flex gap="24px" flexDir="column">
            <HStack justify="space-between" align="center">
              <Text variant="cardTitle" fontSize={'md'} fontWeight="600">
                {t('dialogs.request_supplier_details.title')}
              </Text>
              <IconButton
                icon={<Icon as={MdClose} color="#939393" w="24px" h="24px" />}
                aria-label="close"
                variant="ghost"
                onClick={handleClose}
              />
            </HStack>
            <InputField
              placeholder={'supplier@example.com'}
              label={t(`dialogs.request_supplier_details.labels.email`)}
              value={supplierEmail}
              name="email"
              type="email"
              onChange={handleChange}
              labelProps={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#00000099',
                ps: '10px',
              }}
              borderColor={errorList.includes('email') ? 'red' : 'gray.300'}
              error={errorList.includes('email')}
              errorMessage={t('dialogs.request_supplier_details.errors.email')}
            />
            <InputField
              placeholder={t('dialogs.request_supplier_details.labels.name')}
              label={t(`dialogs.request_supplier_details.labels.name`)}
              value={supplierName}
              name="name"
              type="text"
              onChange={handleChange}
              labelProps={{
                fontSize: '14px',
                fontWeight: '500',
                color: '#00000099',
                ps: '10px',
              }}
              error={errorList.includes('name')}
            />
            <TextField
              id="comment"
              w="full"
              value={supplierComment}
              name="comment"
              minH={130}
              onChange={handleChange}
              placeholder={t(
                'dialogs.request_supplier_details.labels.comment_placeholder',
              )}
              label={t(`dialogs.request_supplier_details.labels.comment`)}
              error={errorList.includes('comment')}
            />
            <Flex w="full">
              <Button
                type="button"
                variant="h4underlineRed"
                color="brand.900"
                fontWeight={600}
                w="full"
                leftIcon={<Icon as={MdContentCopy} />}
                onClick={handleCopyInvitationLink}>
                {t(
                  'dialogs.request_supplier_details.buttons.copy_invitation_link',
                )}
              </Button>
            </Flex>
            <Flex
              content="space-between"
              align="center"
              flexDir="column"
              gap={{ base: '8px', md: '24px' }}>
              <Button
                w="full"
                variant="h1cta"
                fontSize={{ base: '12px !important', md: '14px !important' }}
                lineHeight={{ base: '12px !important', md: '16px !important' }}
                height="44px !important"
                onClick={() => handleSubmit()}>
                {t('dialogs.request_supplier_details.buttons.send')}
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RequestSupplierDetailsDialog;
