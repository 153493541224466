import {
  Box,
  Divider,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Text,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';

import {
  setBusinessEventPlan,
  useBusinessEventPlanSelector,
} from 'contexts/redux/businessEventPlan/businessEventPlanSlice';
import { setAddParticipantDialog } from 'contexts/redux/dialog/dialogsSlice';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useEffect, useMemo } from 'react';
import { MdAdd } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { IEvent } from 'services/@types';
import useParticipants from '../hooks/useParticipants';
import NoDataFound from './NoDataFound';
import ParticipantItem from './ParticipantItem';

type Props = {
  event: IEvent;
};

const Participants = ({ event }: Props) => {
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('participants');
  const dispatch = useDispatch();

  const { businessEventPlan } = useBusinessEventPlanSelector();

  const participants = useMemo(
    () => businessEventPlan?.participants || [],
    [businessEventPlan?.participants],
  );

  const { data, isLoading } = useParticipants({
    eventId: event?.id,
  });

  useEffect(() => {
    dispatch(
      setBusinessEventPlan(
        data
          ? {
              id: data.id,
              eventId: event,
              businessId: data.businessId,
              participants: data.participants,
            }
          : null,
      ),
    );
  }, [data, dispatch, event]);

  return (
    <Card
      ref={ref}
      minH={{ base: 'unset', lg: '535px' }}
      minW={{ base: '100%', lg: '310px' }}
      maxH="535px"
      borderRadius={{ base: 0, md: '30px' }}
      overflowY={'auto'}
      w={{ base: '100%', lg: '310px' }}
      gap="12px"
      {...style}>
      <Flex alignItems="center" justify="space-between">
        <Text variant="eventPageCardTitle">
          {t('event.top_bar_tabs.sub_tabs.participants')}
        </Text>
        <IconButton
          margin={'0 !important'}
          borderRadius="8px"
          maxWidth="24px"
          height="24px"
          minWidth="24px"
          p="0 !important"
          order={{ base: 1, lg: 0 }}
          aria-label="add-event"
          variant={'actionIconBlue'}
          icon={<Icon as={MdAdd} fontSize="16px" />}
          onClick={() => {
            dispatch(
              setAddParticipantDialog({
                eventId: event.id,
              }),
            );
          }}
        />
      </Flex>
      <Divider bg="#E1E1E1" />
      {isLoading ? (
        <Box
          position="absolute"
          w="100%"
          h="100%"
          top="50%"
          left="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          transform="translate(-50%, -50%)"
          zIndex="1">
          <Spinner color="brand.500" />
        </Box>
      ) : (
        <Flex flexDir="column" gap="12px">
          {participants?.map((participant, index) => (
            <>
              <ParticipantItem participant={participant} key={index} />
              <Divider bg="#E1E1E1" />
            </>
          ))}
          {participants.length === 0 && <NoDataFound />}
        </Flex>
      )}
    </Card>
  );
};

export default Participants;
