// src/features/products/userSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { PaginatedResults, Product, ProductCategory } from 'services/@types';
import ProductService from 'services/product.api';
import ProductCategoryService from 'services/productCategory.api';

import {
  closeAlertDialog,
  closeSplashDialog,
  showSplashDialog,
} from '../dialog/dialogsSlice';
import store, { RootState } from '../store';
export interface ProductState {
  products: Product[];
  productCategories: ProductCategory[];
  currentProduct: Product | null;
  currentProductCategory: ProductCategory | null;
  product: Product | null;
  loading: boolean;
  error: string | null;

  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: ProductState = {
  products: [],
  productCategories: [],
  product: null,
  loading: false,
  error: null,
  currentProductCategory: null,
  currentProduct: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await ProductService.getProducts({
        params,
        limit: 1000,
        sortBy: 'createdAt:desc',
        populate: 'subItems',
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const fetchProductCategories = createAsyncThunk(
  'products/fetchProductCategories',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await ProductCategoryService.getProductCategories(
        params.businessID,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchProduct = createAsyncThunk(
  'product/fetchProduct',
  async (productId: string, { rejectWithValue }) => {
    try {
      const response = await ProductService.getProduct(productId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createProduct = createAsyncThunk(
  'product/createProduct',
  async (product: Partial<Product>, { rejectWithValue }) => {
    try {
      store.dispatch(
        showSplashDialog({
          title: 'signup.onboarding.product_info.creating_product',
        }),
      );
      const newProduct = await ProductService.createProduct(product);
      console.log('#Redux create product ->', newProduct);
      store.dispatch(closeSplashDialog());

      return newProduct;
    } catch (error) {
      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);
export const createPackage = createAsyncThunk(
  'product/createPackage',
  async (
    {
      product,
      existingItems,
      newSubItems,
    }: {
      product: Partial<Product>;
      existingItems: Partial<Product>[];
      newSubItems: Partial<Product>[];
    },
    { rejectWithValue },
  ) => {
    try {
      store.dispatch(
        showSplashDialog({
          title: 'products.splash.creating_package',
        }),
      );
      const newSubProducts = await Promise.all(
        newSubItems.map((item) => ProductService.createProduct(item)),
      );
      product.subItems = [...existingItems, ...newSubProducts].map((i) => i.id);
      const newPackage = await ProductService.createProduct(product);
      console.log('#Redux create package ->', newPackage);
      store.dispatch(closeSplashDialog());

      return { package: newPackage, newProducts: newSubProducts };
    } catch (error) {
      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);
export const updatePackage = createAsyncThunk(
  'product/updatePackage',
  async (
    {
      productId,
      updates,
      existingItems,
      newSubItems,
    }: {
      productId: string;
      updates: Partial<Product>;
      existingItems: Partial<Product>[];
      newSubItems: Partial<Product>[];
    },
    { rejectWithValue },
  ) => {
    try {
      store.dispatch(
        showSplashDialog({
          title: 'products.splash.updating_package',
        }),
      );
      const newSubProducts = await Promise.all(
        newSubItems.map((item) => ProductService.createProduct(item)),
      );
      updates.subItems = [...existingItems, ...newSubProducts].map((i) =>
        typeof i === 'string' ? i : i.id,
      );
      const updatedPackage = await ProductService.updateProduct(
        productId,
        updates,
      );
      console.log('#Redux update package ->', updatedPackage);
      store.dispatch(closeSplashDialog());

      return { package: updatedPackage, newProducts: newSubProducts };
    } catch (error) {
      store.dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);
export const updateProduct = createAsyncThunk(
  'bussiness/updateProduct',
  async (
    { productId, updates }: { productId: string; updates: Partial<Product> },
    { rejectWithValue },
  ) => {
    try {
      delete updates.id;
      delete updates.createdAt;
      delete updates.updatedAt;
      delete updates.businessID;
      delete updates.createdBy;

      const response = await ProductService.updateProduct(productId, updates);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteProduct = createAsyncThunk(
  'product/deleteProduct',
  async (productId: string, { rejectWithValue }) => {
    try {
      await ProductService.deleteProduct(productId);
      store.dispatch(closeAlertDialog());
      return productId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const createNewProductCategory = createAsyncThunk(
  'product/createNewProductCategory',
  async (
    {
      categoryName,
      businessID,
    }: {
      categoryName: string;
      businessID: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await ProductCategoryService.createProductCategory({
        name: categoryName,
        businessID,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const deleteProductCategory = createAsyncThunk(
  'product/deleteProductCategory',
  async (categoryId: string, { rejectWithValue }) => {
    try {
      await ProductCategoryService.deleteProductCategory(categoryId);
      return categoryId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const updateProductCategory = createAsyncThunk(
  'product/updateProductCategory',
  async (
    {
      categoryId,
      categoryName,
    }: {
      categoryId: string;
      categoryName: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await ProductCategoryService.updateProductCategory(
        categoryId,
        {
          name: categoryName,
        },
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
    setCurrentProduct: (state, action: PayloadAction<Product | null>) => {
      state.currentProduct = action.payload;
    },
    setCurrentProductCategory: (
      state,
      action: PayloadAction<ProductCategory | null>,
    ) => {
      state.currentProductCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all products
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchProducts.fulfilled,
        (state, action: PayloadAction<PaginatedResults<Product>>) => {
          state.products = action.payload.results as Product[];
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      .addCase(fetchProducts.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch a single user
      .addCase(fetchProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchProduct.fulfilled,
        (state, action: PayloadAction<Product>) => {
          state.product = action.payload;
          state.loading = false;
        },
      )
      .addCase(fetchProduct.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create a user
      .addCase(createProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createProduct.fulfilled,
        (state, action: PayloadAction<Product>) => {
          state.product = action.payload;
          state.loading = false;
          state.products.unshift(action.payload);
          state.currentProduct = action.payload;
        },
      )
      .addCase(createProduct.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update a user
      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateProduct.fulfilled,
        (state, action: PayloadAction<Product>) => {
          const index = state.products.findIndex(
            (user) => user.id === action.payload.id,
          );
          if (index !== -1) {
            state.products[index] = action.payload;
          }
          state.product = action.payload;
          state.loading = false;
          // if (
          //   state.productCategories.indexOf(action.payload.category || '') ===
          //   -1
          // ) {
          //   state.productCategories.push(action.payload.category || '');
          // }
        },
      )
      .addCase(updateProduct.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete a user
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteProduct.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.products = state.products.filter(
            (user) => user.id !== action.payload,
          );
          state.loading = false;
          state.currentProduct = null;
        },
      )
      .addCase(deleteProduct.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchProductCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchProductCategories.fulfilled,
        (state, action: PayloadAction<PaginatedResults<ProductCategory>>) => {
          state.productCategories = action.payload.results;
          state.loading = false;
        },
      )
      .addCase(
        fetchProductCategories.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      .addCase(createPackage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createPackage.fulfilled,
        (
          state,
          action: PayloadAction<{ package: Product; newProducts: Product[] }>,
        ) => {
          state.product = action.payload.package;
          state.loading = false;
          action.payload.newProducts.forEach((product) => {
            state.products.unshift(product);
          });
          state.products.unshift(action.payload.package);
          state.currentProduct = action.payload.package;
        },
      )
      .addCase(createPackage.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updatePackage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updatePackage.fulfilled,
        (
          state,
          action: PayloadAction<{ package: Product; newProducts: Product[] }>,
        ) => {
          state.product = action.payload.package;
          state.loading = false;
          action.payload.newProducts.forEach((product) => {
            state.products.unshift(product);
          });
          state.products.unshift(action.payload.package);
          state.currentProduct = action.payload.package;
        },
      )
      .addCase(updatePackage.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createNewProductCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createNewProductCategory.fulfilled,
        (state, action: PayloadAction<ProductCategory>) => {
          state.productCategories.push(action.payload);
          state.loading = false;
        },
      )
      .addCase(
        createNewProductCategory.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      .addCase(updateProductCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateProductCategory.fulfilled,
        (state, action: PayloadAction<ProductCategory>) => {
          const index = state.productCategories.findIndex(
            (category) => category.id === action.payload.id,
          );
          if (index !== -1) {
            state.productCategories[index] = action.payload;
          }
          state.currentProductCategory = null;
          state.loading = false;
        },
      )
      .addCase(
        deleteProductCategory.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      .addCase(deleteProductCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteProductCategory.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.productCategories = state.productCategories.filter(
            (category) => category.id !== action.payload,
          );
          state.loading = false;
          state.currentProductCategory = null;
        },
      );
  },
});

export function useMyProducts() {
  const products = useSelector((state: RootState) => state.product.products);
  return products;
}

export const { setCurrentProduct, setCurrentProductCategory } =
  productSlice.actions;

export default productSlice.reducer;
