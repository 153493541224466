import {
  Button,
  Flex,
  HStack,
  Icon,
  ScaleFade,
  Switch,
  Text,
  useClipboard,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import AssignAutocompleteField from 'components/AssignAutocompleteField';
import CalendarsDropDown from 'components/calendarsDropDown';
import EventCard from 'components/eventsPickerDialog/eventCard';
import DropdownField from 'components/fields/DropdownField';
import InputDateField from 'components/fields/InputDateField';
import InputField from 'components/fields/InputField';
import SwitchField from 'components/fields/SwitchField';
import TextField from 'components/fields/TextField';
import LocationSearchInput from 'components/placesAutocomplete';
import TimeSelector from 'components/timeSelector';
import {
  getStartingPayloadDate,
  getStartingPayloadEvent,
  selectSelectedDate,
  setStartingPayloadDate,
  setStartingPayloadEvent,
} from 'contexts/redux/createMeeting/createMeetingSlice';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import { IoIosAlert } from 'react-icons/io';
import { PiCopySimple } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import useCreateUpdateMeeting from '../hooks/useCreateUpdateMeeting';

export const remindTypes = ['none', '10', '30'];

export default function CreateMeetingContainer() {
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { onToggle, isOpen } = useDisclosure();
  const selectedDate = useSelector(selectSelectedDate);
  const startingPayloadDate = useSelector(getStartingPayloadDate);
  const startingPayloadEvent = useSelector(getStartingPayloadEvent);
  const dispatch = useDispatch<any>();
  const {
    errorList,
    handleChangeMeeting,
    handleCreateUpdateMeeting,
    meeting,
    handleChangeRemind,
    selectedRemind,
    handleOpenEventDialog,
    event,
    displayingGuest,
    currentGuestInput,
    handleSelectGuest,
    handleSelectExternalGuest,
    handleRemoveGuest,
    setCurrentGuestInput,
    setEvent,
    selectedGuests,
  } = useCreateUpdateMeeting();

  useEffect(() => {
    if (startingPayloadDate) {
      handleChangeMeeting({
        target: { value: startingPayloadDate, name: 'startDate' },
      });
      dispatch(setStartingPayloadDate(null));
    }
  }, [handleChangeMeeting, startingPayloadDate, dispatch]);

  useEffect(() => {
    if (startingPayloadEvent) {
      setEvent(startingPayloadEvent);
      handleChangeMeeting({
        target: { value: startingPayloadEvent.dateAndTime, name: 'startDate' },
      });
      queryClient.invalidateQueries({
        queryKey: [`getMeetings-${startingPayloadEvent.id}`],
      });
      dispatch(setStartingPayloadEvent(null));
    }
  }, [
    startingPayloadEvent,
    handleChangeMeeting,
    dispatch,
    setEvent,
    queryClient,
  ]);

  const handleAnchorChange = useCallback(
    (e: any) => {
      handleChangeMeeting({
        target: {
          value: e.target.checked,
          name: 'isAnchorMeeting',
        },
      });
    },
    [handleChangeMeeting],
  );

  const { onCopy, hasCopied } = useClipboard(event?.meetLink);
  return (
    <VStack alignItems="start" w={{ base: '100%', md: '60%' }} gap="26px">
      <HStack justifyContent="space-between" align="center" w="100%">
        <Text variant="cardTitle">
          {meeting?.id
            ? t('create_meeting.update_meeting')
            : t('create_meeting.title')}
        </Text>
        {event && (
          <EventCard
            event={event}
            onClick={handleOpenEventDialog}
            isQuoteEvent
            // flex="1"
            maxW={{ base: '100%', md: '320px' }}
            w={{ base: '100%', md: 'fit-content' }}
          />
        )}
        {!event && (
          <Button
            variant="h1cta"
            w={{ base: '100%', md: 'fit-content' }}
            onClick={handleOpenEventDialog}
            maxW={{ base: '100%', md: '320px' }}
            flex="1"
            px={24}>
            {t('create_meeting.assign_to_event')}
          </Button>
        )}
      </HStack>
      <Flex wrap="wrap" gap="21px" w="100%">
        <InputField
          label={t('create_meeting.meeting_title')}
          placeholder={t('create_meeting.meeting_title')}
          name="name"
          flex="1"
          w={{ base: '100%', md: '310px' }}
          containerStyles={{ w: { base: '100%', md: '310px' } }}
          value={meeting?.name || ''}
          onChange={handleChangeMeeting}
          borderColor={errorList.includes('name') ? 'error.100' : 'gray.200'}
        />
        <CalendarsDropDown
          alignSelf="self-end"
          isMeeting
          w={{ base: '100%', md: '310px' }}
          value={meeting?.calendarId}
          onChange={(value: string) => {
            handleChangeMeeting({
              target: {
                value,
                name: 'calendarId',
              },
            });
          }}
        />
        <HStack w="100%" gap="20px">
          <VStack spacing={0}>
            <LocationSearchInput
              label={t('create_meeting.location')}
              placeholder={t('create_meeting.location')}
              onChange={(e) => {
                console.log('location search input ->', e);
                handleChangeMeeting({
                  target: {
                    value: { label: e, value: e },
                    name: 'location',
                  },
                });
              }}
              onSelect={(e) => {
                console.log('location search input ->', e);
                handleChangeMeeting({
                  target: {
                    value: e,
                    name: 'location',
                  },
                });
              }}
              inputProps={
                errorList.includes('location')
                  ? {
                      _empty: {
                        borderColor: 'error.100',
                      },
                    }
                  : {}
              }
            />
          </VStack>
          <VStack spacing={0} justifyContent="flex-end" h="100%" gap={1}>
            <SwitchField
              w="100%"
              wrapperProps={{
                w: '100%',
                p: '0px',
              }}
              labelProps={{
                marginLeft: i18n.dir() === 'rtl' ? 0 : '12px',
                marginRight: i18n.dir() === 'rtl' ? '12px' : 0,
                fontSize: '14px',
                fontWeight: 400,
              }}
              colorScheme="yellow"
              color="#AA915D"
              display="flex"
              labelColor="#AA915D"
              reversed
              label={t('create_meeting.anchor_meeting')}
              isChecked={meeting?.isAnchorMeeting}
              onChange={handleAnchorChange}
              id="isAnchorMeeting"
            />
            <Text fontSize="12px" color="gray.500">
              {t('create_meeting.anchor_meeting_note')}
            </Text>
          </VStack>
        </HStack>
      </Flex>
      <Flex wrap="wrap" gap="20px" alignItems="end">
        <HStack>
          <Flex flex="1" gap="20px" alignItems="end">
            <InputDateField
              name="startDate"
              label={t('create_meeting.meeting_date')}
              value={selectedDate.start}
              onChange={(e: Date) => {
                const event = {
                  target: {
                    value: e,
                    name: 'startDate',
                  },
                };
                handleChangeMeeting(event);
              }}
              justifyContent="end"
              containerStyles={{
                w: { base: '100%', md: '166px' },
              }}
              buttonStyles={{
                borderColor: errorList.includes('startDate')
                  ? 'error.100'
                  : 'general.100',
                w: { base: '100%', md: '166px' },
              }}
            />
            <TimeSelector
              borderColor={
                errorList.includes('endTime') ? 'error.100' : 'general.100'
              }
              w={{ base: '100%', md: '126px' }}
              label={t('create_meeting.start_meeting')}
              value={meeting?.startDate
                ?.toLocaleTimeString('en-US', { hourCycle: 'h24' })
                .slice(0, 5)}
              flex="1"
              onChange={(e: string) => {
                handleChangeMeeting({
                  target: {
                    value: e,
                    name: 'startTime',
                  },
                });
              }}
            />
          </Flex>
        </HStack>
        <Flex flex="1" gap="20px" alignItems="end">
          <TimeSelector
            borderColor={
              errorList.includes('endTime') ? 'error.100' : 'general.100'
            }
            w={{ base: '100%', md: '126px' }}
            label={t('create_meeting.end_meeting')}
            value={meeting?.endDate
              ?.toLocaleTimeString('en-US', { hourCycle: 'h24' })
              .slice(0, 5)}
            flex="1"
            onChange={(e: string) => {
              handleChangeMeeting({
                target: {
                  value: e,
                  name: 'endTime',
                },
              });
            }}
          />
          <DropdownField
            closeOnSelect
            options={remindTypes.map((type) => `create_meeting.remind_${type}`)}
            onSelected={handleChangeRemind}
            selectedOptions={selectedRemind}
            placeholder={
              meeting?.reminder
                ? t(`create_meeting.remind_${meeting.reminder}`)
                : t('create_meeting.remind_me')
            }
            label={t('create_meeting.remind_me')}
            w={{ base: '100%', md: '166px' }}
            formLabelStyles={{ mb: 1 }}
            menuButton={{
              borderColor: 'general.100',
              h: '50px',
              borderRadius: '16px',
            }}
          />
        </Flex>
        <TextField
          label={t('create_meeting.note')}
          placeholder={t('create_meeting.note')}
          w="100%"
          minH="150px"
          resize="none"
          name="description"
          value={meeting?.description || ''}
          onChange={handleChangeMeeting}
        />
        <AssignAutocompleteField
          options={displayingGuest}
          value={currentGuestInput}
          onSelected={handleSelectGuest}
          onExternalSelected={handleSelectExternalGuest}
          selectedOptions={selectedGuests}
          handleRemove={handleRemoveGuest}
          onChange={(e) => setCurrentGuestInput(e.target.value)}
          label={t('create_meeting.add_guests')}
          placeholder={t('create_meeting.add_guests')}
          w="100%"
          h="auto"
          minH="auto"
          maxH="auto"
        />
        {event?.meetLink && (
          <VStack alignItems="start" gap="12px">
            <Flex gap="12px" alignItems="center">
              <Switch dir={i18n.dir()} onChange={onToggle} variant="brand" />
              <Text variant="cardSectionTitle">
                {t('create_meeting.google_meets_link')}
              </Text>
            </Flex>
            <ScaleFade initialScale={0.9} in={isOpen}>
              <HStack bg="#9393930D" borderRadius="8px" p="4px 8px" gap="12px">
                <Text variant="cardSectionTitle">{event?.meetLink}</Text>
                <Icon
                  as={hasCopied ? FaCheck : PiCopySimple}
                  color="#AA915D"
                  w="20px"
                  h="20px"
                  cursor="pointer"
                  onClick={onCopy}
                />
              </HStack>
            </ScaleFade>
          </VStack>
        )}
        <HStack
          justifyContent="end"
          w="100%"
          flexDirection={{ base: 'column-reverse', md: 'row' }}
          gap={2}>
          {errorList.length > 0 && (
            <HStack justify="center">
              <Icon
                as={IoIosAlert}
                w="16px"
                h="16px"
                bg="white"
                color="error.100"
                borderRadius="50%"
              />
              <Text variant="errorTitle">{t('create_event.error')}</Text>
            </HStack>
          )}
          <Button
            variant="h1cta"
            minH={{ base: '50px', md: 'fit-content' }}
            borderRadius="16px"
            w={{ base: '100%', md: '140px' }}
            isDisabled={errorList.length > 0}
            onClick={() => {
              handleCreateUpdateMeeting();
            }}>
            {meeting?.id
              ? t('create_meeting.update_meeting')
              : t('create_meeting.create_meeting')}
          </Button>
        </HStack>
      </Flex>
    </VStack>
  );
}
