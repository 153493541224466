import {
  MenuButton,
  Menu,
  Flex,
  Text,
  IconButton,
  Icon,
  MenuList,
  MenuItem,
  Checkbox,
} from '@chakra-ui/react';
import DropdownField from 'components/fields/DropdownField';
import InputDateField from 'components/fields/InputDateField';
import InputField from 'components/fields/InputField';
import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import { useTranslation } from 'react-i18next';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { FiMoreHorizontal } from 'react-icons/fi';
import { IInvoice } from 'services/@types';

interface IDetailsOfReceiptsProps {
  receipientDetails: IInvoice['receipientDetails'];
  handleChangeDetailsOfReceipts: (key: string, value: any) => void;
}

export default function DetailsOfReceipts(props: IDetailsOfReceiptsProps) {
  const { receipientDetails, handleChangeDetailsOfReceipts } = props;
  const { t } = useTranslation();
  const { data: businessPreferences } = useGetBusinessPreferences();
  return (
    <Flex flexDirection="column" gap="16px">
      <Text variant="lineUpFormDialogFileName">
        {t('create_invoice.details_of_receipts')}
      </Text>
      <Flex wrap="wrap" gap="12px" alignItems="end">
        <DropdownField
          label={t('create_invoice.method')}
          placeholder={t('create_invoice.method')}
          w={{ base: '100%', md: '289px' }}
          options={[]}
        />
        <DropdownField
          label={t('create_invoice.currency')}
          placeholder={t('create_invoice.currency')}
          w={{ base: '100%', md: '289px' }}
          options={[]}
        />
        <InputDateField
          label={t('create_invoice.date')}
          placeholder={t('create_invoice.contract_date')}
          value={new Date()}
          onChange={() => {}}
          buttonStyles={{
            borderColor: '#0000001A',
            justifyContent: 'space-between',
            w: { base: '100%', md: '174px' },
          }}
          imgStyles={{
            bg: 'transparent',
          }}
          imgSrc={CalendarIcon}
        />
        <InputField
          label={t('create_invoice.amount')}
          placeholder={t('create_invoice.amount')}
          w={{ base: '100%', md: '110px' }}
          value={receipientDetails?.amount}
          onChange={(e: any) =>
            handleChangeDetailsOfReceipts('amount', e.target.value)
          }
        />
        <Checkbox
          variant="roundBrand"
          isChecked={false}
          onChange={() => {}}
          w={{ base: '100%', md: 'auto' }}
          h="50px"
          alignItems="center">
          <Text variant="createQuoteCheckboxText">
            {t('create_invoice.vat', {
              percentage: businessPreferences?.vatPercent,
            })}
          </Text>
        </Checkbox>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label=""
            icon={<Icon as={FiMoreHorizontal} color="#93C3B3" />}
            variant="ghost"
            h="50px"
          />
          <MenuList>
            <MenuItem onClick={() => {}}></MenuItem>
          </MenuList>
        </Menu>
        <InputField
          label={t('create_invoice.bank')}
          placeholder={t('create_invoice.bank')}
          w={{ base: '100%', md: '288px' }}
        />
        <InputField
          label={t('create_invoice.branch')}
          placeholder={t('create_invoice.branch')}
          w={{ base: '100%', md: '288px' }}
        />
        <InputField
          label={t('create_invoice.bank_account_number')}
          placeholder={t('create_invoice.bank_account_number')}
          w={{ base: '100%', md: '288px' }}
        />
      </Flex>
    </Flex>
  );
}
