import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import logo from 'assets/svg/brilliant-logo-gold.svg';
import successImage from 'assets/svg/suppliers/suppliers-details-success.svg';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';

import ThreeDotsBtn from 'components/buttons/ThreeDotsBtn';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import { AddContactPerson } from 'views/main/managment/suppliers/components/AddContactPerson';
import useSupplierDetails from './hooks/useSupplierDetails';
const RequestingSupplierDetails = () => {
  const { t, i18n } = useTranslation();
  const {
    supplierProps,
    setSupplierProps,
    errorLabels,
    setErrorLabels,
    handleChange,
    businessCategories,
    handleChangeBankDetails,
    isContactMenuOpen,
    onContactMenuOpen,
    onContactMenuClose,
    onAddContact,
    onRemoveContactB,
    handleContactChange,
    handleSave,
    hasSentSuccessfully,
    handleNavigateToSignUp,
  } = useSupplierDetails();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bg = useColorModeValue('background.100', 'background.900');
  return (
    <Box bg={bg} minH="100vh" w="100vw" overflow="auto" fontFamily={'body'}>
      <Flex
        bg={bg}
        float={i18n.dir() === 'rtl' ? 'left' : 'right'}
        p={{ base: '24px 16px', md: '40px', lg: '80px' }}
        flexDir="column"
        align="center"
        w="full"
        gap={{ base: 6, md: '40px', lg: '80px' }}>
        {/* logo */}
        <Flex w="full">
          <Image src={logo} alt="logo" w="150px" h="40px" />
        </Flex>
        {/* form content */}
        {hasSentSuccessfully ? (
          <VStack
            w="full"
            align="center"
            maxW="460px"
            spacing={0}
            gap={6}
            textAlign="center">
            <Flex w="full" justify={'center'} align={'center'}>
              <Image src={successImage} alt="success" w="240px" h="240px" />
            </Flex>
            <Text variant="eventPageCardTitle" fontWeight={600}>
              {t('supplier_details.success.title')}
            </Text>
            <Text
              color="brand.900"
              fontWeight={700}
              fontSize="base"
              lineHeight={'16px'}
              letterSpacing="-0.02em">
              {t('supplier_details.success.info')}
            </Text>
            <Text
              variant="lineUpItemFileName"
              fontWeight={500}
              lineHeight="14px">
              {t('supplier_details.success.info_detail')}
            </Text>
            <Button
              variant="h1cta"
              minW="320px"
              onClick={handleNavigateToSignUp}>
              {t('supplier_details.buttons.continue')}
            </Button>
          </VStack>
        ) : (
          <Card
            maxW="1090px"
            w="full"
            gap={6}
            border={{ md: '1px solid #E8E8E8' }}
            borderRadius={{ base: 0, md: '30px' }}
            shadow={'none'}
            p={{ base: 0, md: 6 }}>
            <Text variant="eventPageCardTitle">
              {t('supplier_details.title')}
            </Text>
            <Flex p={0} w="100%">
              <Flex
                flexDir={{ base: 'column', md: 'row' }}
                w="100%"
                gap={6}
                justify={'space-between'}>
                <VStack
                  align="start"
                  justify={'start'}
                  w="full"
                  spacing={0}
                  gap={6}>
                  <HStack
                    gap={4}
                    spacing={0}
                    flex={1}
                    flexWrap={'wrap'}
                    align="start"
                    minWidth={310}
                    w="full">
                    <Text
                      fontSize={'xl'}
                      fontWeight="bold"
                      flexShrink={0}
                      w="full">
                      {t('supplier_details.business_information')}
                    </Text>
                    <InputField
                      id="name"
                      w="100%"
                      containerStyles={{ maxW: '320px' }}
                      value={supplierProps?.name}
                      placeholder={t(
                        'suppliers.modals.add_supplier.business_name',
                      )}
                      onChange={handleChange}
                      label={t('suppliers.modals.add_supplier.business_name')}
                      errorLabel={errorLabels.name}
                      borderColor={
                        errorLabels.name ? 'red.500' : 'secondaryGray.100'
                      }
                    />
                    <InputField
                      id="phone"
                      containerStyles={{ maxW: '320px' }}
                      value={supplierProps?.phone}
                      w="100%"
                      onChange={handleChange}
                      placeholder="eg. 051-2332456"
                      label={t('suppliers.modals.add_supplier.business_phone')}
                    />
                    <InputField
                      containerStyles={{ maxW: '320px' }}
                      id="address"
                      w="100%"
                      value={supplierProps?.address}
                      onChange={handleChange}
                      placeholder={t(
                        'suppliers.modals.add_supplier.business_address',
                      )}
                      label={t(
                        'suppliers.modals.add_supplier.business_address',
                      )}
                    />
                    <InputField
                      id="vatId"
                      value={supplierProps?.vatId}
                      w="100%"
                      containerStyles={{ maxW: '320px' }}
                      onChange={handleChange}
                      placeholder="eg. 123456789"
                      label={t('suppliers.modals.add_supplier.business_vat_id')}
                    />
                    <InputField
                      containerStyles={{ maxW: '320px' }}
                      value={supplierProps?.email}
                      id="email"
                      w="100%"
                      onChange={handleChange}
                      placeholder={t(
                        'suppliers.modals.add_supplier.email_address',
                      )}
                      label={t('suppliers.modals.add_supplier.email_address')}
                      errorLabel={errorLabels.email}
                      isReadOnly
                      borderColor={
                        errorLabels.email ? 'red.500' : 'secondaryGray.100'
                      }
                    />
                    <DropdownField
                      w="100%"
                      maxW="320px"
                      label={t(
                        'suppliers.modals.add_supplier.business_category',
                      )}
                      options={businessCategories?.map(
                        (category) => `categories.${category.name}`,
                      )}
                      placeholder={t('suppliers.supplier_category_placeholder')}
                      onSelected={(selected: any) => {
                        console.log('on selected ->', selected);
                        const selectedCategory = businessCategories.find(
                          (bc) => bc.name === selected.split('.').pop(),
                        );
                        const newCategories = [
                          ...(supplierProps?.category || []),
                        ];
                        if (!newCategories.includes(selectedCategory?.id)) {
                          newCategories.push(selectedCategory?.id as string);
                        } else {
                          newCategories.splice(
                            newCategories.indexOf(
                              selectedCategory?.id as string,
                            ),
                            1,
                          );
                        }

                        console.log('selected cat ->', newCategories);
                        setSupplierProps({
                          ...supplierProps,
                          category: newCategories,
                        });
                        setErrorLabels({
                          name: '',
                          email: '',
                          category: '',
                          contacts: [],
                        });
                      }}
                      name="category"
                      selectedOptions={supplierProps?.category?.map((sc) => {
                        const selectedCategory = businessCategories.find(
                          (bc) => bc.id === sc,
                        );
                        return `categories.${selectedCategory?.name}`;
                      })}
                      menuButton={{ w: { base: '100%', md: '320px' } }}
                      error={errorLabels.category}
                      showedOptions={supplierProps?.category
                        ?.map((sc) => {
                          const selectedCategory = businessCategories.find(
                            (bc) => bc.id === sc,
                          );
                          return t(`categories.${selectedCategory?.name}`);
                        })
                        .join(',')}
                    />
                  </HStack>
                  <HStack
                    flexWrap={'wrap'}
                    gap={4}
                    flex={1}
                    spacing={0}
                    minWidth={310}
                    w="full">
                    {/* business id, categories, tag services, internal notes */}{' '}
                    <Text
                      fontSize={'xl'}
                      fontWeight="bold"
                      flexShrink={0}
                      w="full">
                      {t('supplier_details.payment_information')}
                    </Text>
                    <InputField
                      id="bankName"
                      value={supplierProps?.bankDetails?.bankName}
                      w="100%"
                      containerStyles={{ maxW: '320px' }}
                      onChange={handleChangeBankDetails}
                      placeholder="eg. 123456789"
                      label={t('suppliers.modals.add_supplier.bank_name')}
                    />
                    <InputField
                      id="branchCode"
                      value={supplierProps?.bankDetails?.branchCode}
                      w="100%"
                      containerStyles={{ maxW: '320px' }}
                      onChange={handleChangeBankDetails}
                      placeholder="eg. 123456789"
                      label={t('suppliers.modals.add_supplier.bank_branch')}
                    />
                    <InputField
                      id="accountNumber"
                      value={supplierProps?.bankDetails?.accountNumber}
                      w="100%"
                      containerStyles={{ maxW: '320px' }}
                      onChange={handleChangeBankDetails}
                      placeholder="eg. 123456789"
                      label={t('suppliers.modals.add_supplier.account_number')}
                    />
                  </HStack>
                </VStack>
                <HStack
                  spacing={0}
                  align="start"
                  justify={'start'}
                  gap={4}
                  w={{ base: '100%', md: 'fit-content' }}>
                  <Divider
                    orientation={'vertical'}
                    display={{ base: 'none', md: 'block' }}
                  />
                  <VStack
                    align="start"
                    flex={1}
                    spacing={0}
                    minWidth={310}
                    maxW={{ base: '100%', md: 310 }}>
                    <HStack w="100%">
                      <Text fontSize={'xl'} fontWeight="bold" flex={1}>
                        {t('suppliers.modals.add_supplier.contact_person')}
                      </Text>
                      <Menu
                        isOpen={isContactMenuOpen}
                        onOpen={onContactMenuOpen}
                        onClose={onContactMenuClose}>
                        <ThreeDotsBtn
                          onClick={onContactMenuOpen}
                          mb="0px"
                          me="8px"
                        />
                        <MenuList
                          minW="unset"
                          maxW="180px !important"
                          border="transparent"
                          backdropFilter="blur(63px)"
                          borderRadius="20px"
                          py="10px"
                          px="14px">
                          <MenuItem
                            transition="0.2s linear"
                            color={textColor}
                            onClick={onAddContact}
                            py={2}
                            px={0}
                            borderRadius="8px"
                            display={
                              supplierProps.contacts?.length === 2
                                ? 'none'
                                : 'block'
                            }
                            _active={{
                              bg: 'transparent',
                            }}
                            _focus={{
                              bg: 'transparent',
                            }}>
                            <Text fontSize="sm" fontWeight="400">
                              {t('suppliers.modals.contact_person.menu.add')}
                            </Text>
                          </MenuItem>
                          <MenuItem
                            py={2}
                            transition="0.2s linear"
                            px="0px"
                            display={
                              supplierProps?.contacts?.length > 1
                                ? 'block'
                                : 'none'
                            }
                            borderRadius="8px"
                            color={textColor}
                            onClick={onRemoveContactB}
                            _active={{
                              bg: 'transparent',
                            }}
                            _focus={{
                              bg: 'transparent',
                            }}>
                            <Text
                              color="red.500"
                              fontSize="sm"
                              fontWeight="400">
                              {t(
                                'suppliers.modals.contact_person.menu.remove_contact',
                                {
                                  index: 'B',
                                },
                              )}
                            </Text>
                          </MenuItem>
                        </MenuList>{' '}
                      </Menu>
                    </HStack>
                    <AddContactPerson
                      label={t('suppliers.modals.contact_person.contact', {
                        index: "A",
                      })}
                      contact={supplierProps.contacts?.[0] || {}}
                      handleContactChange={(contact) =>
                        handleContactChange(contact, 0)
                      }
                      errorLabels={errorLabels.contacts?.[0]}
                    />
                    {supplierProps.contacts?.[1] && (
                      <AddContactPerson
                        label={t('suppliers.modals.contact_person.contact', {
                          index: "B",
                        })}
                        contact={supplierProps.contacts?.[1] || {}}
                        handleContactChange={(contact) =>
                          handleContactChange(contact, 1)
                        }
                        errorLabels={errorLabels.contacts?.[1]}
                      />
                    )}
                  </VStack>
                </HStack>
              </Flex>
            </Flex>
            <Flex w="100%" flexDir={'column'} align={{ md: 'end' }} gap={6}>
              <Button variant="h1cta" minW="320px" onClick={handleSave}>
                {t('supplier_details.buttons.send')}
              </Button>
              <Text variant="anchorMeetingDescription">
                {t('supplier_details.warning')}
              </Text>
            </Flex>
          </Card>
        )}
      </Flex>
    </Box>
  );
};
export default RequestingSupplierDetails;
