import { Button } from '@chakra-ui/react';
import QuoteCard from 'components/quotePickerDialog/quoteCard';
import { useGetQuote } from 'queries/quote';
import { useTranslation } from 'react-i18next';

interface IAssignQuoteProps {
  handleOpenQuoteDialog: () => void;
  quoteID: string;
}

export default function AssignQuote(props: IAssignQuoteProps) {
  const { handleOpenQuoteDialog, quoteID } = props;
  const { t } = useTranslation();

  const { data: quote } = useGetQuote(quoteID);

  if (quoteID && quote) {
    return (
      <QuoteCard
        quote={quote}
        hideEventName
        onClick={handleOpenQuoteDialog}
        w={{ base: '100%', md: '320px' }}
      />
    );
  }

  return (
    <Button
      variant="h1cta"
      height={'44px'}
      fontSize={'sm'}
      w={{ base: '100%', md: '320px' }}
      onClick={handleOpenQuoteDialog}>
      {t('create_invoice.assign_to_quote')}
    </Button>
  );
}
