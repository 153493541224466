import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { BusinessCategory, PaginatedResults } from 'services/@types';
import BusinessCategoryService from 'services/category.api';

// Query key factories
const getBusinessCategoriesQueryKey = (
  params?: Record<string, any>,
): QueryKey => ['businessCategories', params];

// Query options factories
const getBusinessCategoriesQueryOptions = (params?: Record<string, any>) => ({
  queryKey: getBusinessCategoriesQueryKey(params),
  queryFn: () => BusinessCategoryService.getBusinessCategories(params),
  staleTime: 5 * 60 * 1000, // 5 minutes
  gcTime: 15 * 60 * 1000, // 15 minutes
});

export const useBusinessCategories = (
  params: Record<string, any> = { limit: 1000 },
) => {
  return useQuery<PaginatedResults<BusinessCategory>>({
    ...getBusinessCategoriesQueryOptions(params),
    select: (data) => data,
  });
};

// Helper methods
useBusinessCategories.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
  params?: Record<string, any>,
) => {
  await queryClient.prefetchQuery(getBusinessCategoriesQueryOptions(params));
};

useBusinessCategories.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  params?: Record<string, any>,
) => {
  queryClient.invalidateQueries({
    queryKey: getBusinessCategoriesQueryKey(params),
  });
};
