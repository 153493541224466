import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMessage, Thread } from 'services/@types/index';
import messageService from 'services/message.api';
import threadService from 'services/thread.api';
import store from '../store';

interface SearchState {
  query: string;
  results: IMessage[];
  isSearching: boolean;
  isSearchBarOpened: boolean;
  currentIndex: number;
  total: number;
  isNavigating: boolean;
}

export interface MessengerState {
  threads: Thread[];
  eventThreads: Thread[];
  messages: IMessage[];
  currentThread: Thread | null;
  currentThreadUnreadMessagesId: string | null;
  newThread: Thread | null;
  loading: boolean;
  threadsLoading: boolean;
  error: string | null;
  messagePagination: {
    page: number;
    limit: number;
    totalPages: number;
  };
  shouldScrollToBottom: boolean;
  newMessageReceived: IMessage | null;
  searchState: SearchState;
}

const initialState: MessengerState = {
  currentThread: null,
  currentThreadUnreadMessagesId: null,
  newThread: null,
  threads: [],
  eventThreads: [],
  messages: [],
  messagePagination: {
    page: 1,
    limit: 10,
    totalPages: 0,
  },
  loading: false,
  threadsLoading: false,
  error: null,
  shouldScrollToBottom: false,
  newMessageReceived: null,
  searchState: {
    isSearchBarOpened: false,
    query: '',
    results: [],
    isSearching: false,
    currentIndex: -1,
    total: 0,
    isNavigating: false,
  },
};

export const createThread = createAsyncThunk(
  'messenger/createThread',
  async (thread: Thread) => {
    const response = await threadService.createThread(thread);
    return response;
  },
);

export const fetchThreads = createAsyncThunk(
  'messenger/fetchThreads',
  async (params: Record<string, any>) => {
    const response = await threadService.getThreads(params);
    return response;
  },
);

export const fetchEventThreads = createAsyncThunk(
  'messenger/fetchEventThreads',
  async (params: Record<string, any>) => {
    const response = await threadService.getThreads(params);
    return response;
  },
);

export const fetchThread = createAsyncThunk(
  'messenger/fetchThread',
  async (threadId: string) => {
    const response = await threadService.getThread(threadId);
    return response;
  },
);

export const fetchMissedThread = createAsyncThunk(
  'messenger/fetchMissedThread',
  async (threadId: string) => {
    const response = await threadService.getThread(threadId);
    return response;
  },
);

export const updateThread = createAsyncThunk(
  'messenger/updateThread',
  async ({
    threadId,
    thread,
  }: {
    threadId: string;
    thread: Partial<Thread>;
  }) => {
    const response = await threadService.updateThread(threadId, thread);
    return response;
  },
);

export const deleteThread = createAsyncThunk(
  'messenger/deleteThread',
  async (threadId: string) => {
    await threadService.deleteThread(threadId);
    return threadId;
  },
);

export const fetchMessages = createAsyncThunk(
  'messenger/fetchMessages',
  async (queryParams?: Record<string, any>) => {
    const response = await messageService.getMessages(queryParams);
    return response;
  },
);

export const fetchMessagesOnScroll = createAsyncThunk(
  'messenger/fetchMessagesOnScroll',
  async (params: Record<string, any>) => {
    const response = await messageService.getMessages(params);
    return response;
  },
);

export const createMessage = createAsyncThunk(
  'messenger/createMessage',
  async (message: IMessage) => {
    const response = await messageService.createMessage(message);
    return response;
  },
);

export const updateMessage = createAsyncThunk(
  'messenger/updateMessage',
  async (message: Partial<IMessage>) => {
    const messageId = message.id;
    delete message.id;
    const response = await messageService.updateMessage(messageId, message);
    // invalidate query client for the thread
    return response;
  },
);

export const deleteMessage = createAsyncThunk(
  'messenger/deleteMessage',
  async (messageId: string) => {
    await messageService.deleteMessage(messageId);
    return messageId;
  },
);

export const onNewMessageReceived = createAsyncThunk(
  'messenger/onNewMessageReceived',
  async (messageId: string) => {
    const message = await messageService.getMessage(messageId);
    const threads = store.getState().messenger.threads;
    if (!threads.find((thread) => thread.id === message.threadId)) {
      await store.dispatch(fetchMissedThread(message.threadId));
    }
    return message;
  },
);

export const searchMessages = createAsyncThunk(
  'messenger/searchMessages',
  async ({
    threadId,
    query,
    page = 1,
    limit = 20,
  }: {
    threadId: string;
    query: string;
    page?: number;
    limit?: number;
  }) => {
    // This would be your API call
    const response = await messageService.searchMessages({
      threadId,
      query,
      cursor: null,
      direction: 'next',
      limit,
    });
    return response;
  },
);

export const getMessagePage = createAsyncThunk(
  'messenger/getMessagePage',
  async ({ threadId, messageId }: { threadId: string; messageId: string }) => {
    const response = await messageService.getMessagePage({
      threadId,
      messageId,
    });
    return response;
  },
);

const messengerSlice = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    setNewThread: (state, action) => {
      state.newThread = action.payload;
    },
    setNewThreadTitle: (state, action) => {
      state.newThread.title = action.payload;
    },
    setNewThreadEvent: (state, action) => {
      state.newThread.event = action.payload;
    },
    setCurrentThread: (state, action) => {
      state.currentThread = action.payload;
    },
    setCurrentThreadTitle: (state, action) => {
      state.currentThread.title = action.payload;
    },
    setCurrentThreadEvent: (state, action) => {
      state.currentThread.event = action.payload;
    },
    updateLastThread: (state, action) => {
      state.threads[state.threads.length - 1] = {
        ...state.threads[state.threads.length - 1],
        ...action.payload,
      };
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    setThreads: (state, action) => {
      state.threads = action.payload;
    },
    resetShouldScrollToBottom: (state) => {
      state.shouldScrollToBottom = false;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchState.query = action.payload;
    },
    clearSearch: (state) => {
      state.searchState = initialState.searchState;
    },
    setSearchIndex: (state, action) => {
      state.searchState.currentIndex = action.payload;
    },
    setSearchResults: (
      state,
      action: PayloadAction<{
        results: IMessage[];
        currentIndex: number;
        total: number;
        query: string;
      }>,
    ) => {
      state.searchState.results = action.payload.results;
      state.searchState.currentIndex = action.payload.currentIndex;
      state.searchState.total = action.payload.total;
      state.searchState.query = action.payload.query;
    },
    setSearchState: (state, action: PayloadAction<Partial<SearchState>>) => {
      state.searchState = { ...state.searchState, ...action.payload };
    },
    setMessages: (state, action: PayloadAction<IMessage[]>) => {
      state.messages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThreads.pending, (state) => {
      state.threadsLoading = true;
    });
    builder.addCase(fetchThreads.fulfilled, (state, action) => {
      state.threadsLoading = false;
      state.threads = action.payload.results;
    });
    builder.addCase(fetchThreads.rejected, (state, action) => {
      state.threadsLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchThread.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchThread.fulfilled, (state, action) => {
      state.loading = false;
      state.currentThread = action.payload;
    });
    builder.addCase(fetchThread.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateThread.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateThread.fulfilled, (state, action) => {
      state.loading = false;
      const updatedThread = {
        ...state.currentThread,
        ...action.payload,
      };
      state.currentThread = updatedThread;
      state.threads = state.threads.map((thread) =>
        thread.id === action.payload.id ? updatedThread : thread,
      );
    });
    builder.addCase(updateThread.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteThread.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteThread.fulfilled, (state, action) => {
      state.loading = false;
      state.threads = state.threads.filter(
        (thread) => thread.id !== action.payload,
      );
    });
    builder.addCase(deleteThread.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchMessages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMessages.fulfilled, (state, action) => {
      state.loading = false;
      state.messages = action.payload.results.reverse();
      state.messagePagination = {
        page: action.payload.page,
        limit: action.payload.limit,
        totalPages: action.payload.totalPages,
      };
      state.shouldScrollToBottom = true;
      const unreadMessage = action.payload.results.find(
        (message) =>
          !message.seenBy?.find((seen) => seen.userId !== message.senderId),
      );
      state.currentThreadUnreadMessagesId = unreadMessage?.id || null;
    });
    builder.addCase(fetchMessages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createMessage.fulfilled, (state, action) => {
      state.loading = false;
      const existingMessage = state.messages.find(
        (message) => message.id === action.payload.id,
      );
      if (!existingMessage) {
        state.messages.push(action.payload);
      }
      // find the thread and update the last message and move it to the top
      const thread = state.threads.find(
        (thread) => thread.id === action.payload.threadId,
      );
      state.threads = state.threads.filter(
        (thread) => thread.id !== action.payload.threadId,
      );
      state.threads.unshift({
        ...thread,
        lastMessage: action.payload,
      });
      state.shouldScrollToBottom = true;
    });
    builder.addCase(createMessage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMessage.fulfilled, (state, action) => {
      state.loading = false;
      state.newMessageReceived = action.payload;
      state.messages = state.messages.map((message) =>
        message.id === action.payload.id ? action.payload : message,
      );
    });
    builder.addCase(updateMessage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteMessage.fulfilled, (state, action) => {
      state.loading = false;
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload,
      );
    });
    builder.addCase(deleteMessage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createThread.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createThread.fulfilled, (state, action) => {
      state.loading = false;
      const newThread = { ...state.newThread, ...action.payload };
      state.threads.push(newThread);
      state.messages = [];
      state.newThread = null;
      state.currentThread = newThread;
    });
    builder.addCase(createThread.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchEventThreads.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEventThreads.fulfilled, (state, action) => {
      state.loading = false;
      state.eventThreads = action.payload.results;
    });
    builder.addCase(fetchEventThreads.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchMessagesOnScroll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMessagesOnScroll.fulfilled, (state, action) => {
      state.loading = false;
      state.messages.unshift(...action.payload.results?.reverse());
      state.messagePagination = {
        page: action.payload.page,
        limit: action.payload.limit,
        totalPages: action.payload.totalPages,
      };
    });
    builder.addCase(fetchMessagesOnScroll.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(onNewMessageReceived.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(onNewMessageReceived.fulfilled, (state, action) => {
      state.loading = false;
      state.newMessageReceived = action.payload;
      const threadIndex = state.threads.findIndex(
        (thread) => thread.id === action.payload.threadId,
      );
      if (threadIndex !== -1) {
        const updatedThread = {
          ...state.threads[threadIndex],
          lastMessage: action.payload,
        };
        state.threads.splice(threadIndex, 1);
        state.threads.unshift(updatedThread);
      }
      if (state?.currentThread?.id === action.payload.threadId) {
        state.shouldScrollToBottom = true;
        state.messages.push(action.payload);
      }
    });
    builder.addCase(onNewMessageReceived.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchMissedThread.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMissedThread.fulfilled, (state, action) => {
      state.loading = false;
      state.threads.unshift(action.payload);
    });
    builder.addCase(fetchMissedThread.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(searchMessages.fulfilled, (state, action) => {
      const messageIds = action.payload.results.map((msg: any) => msg.id);
      console.log('search results fulfilled', messageIds);

      state.searchState.results = action.payload.results;
      state.searchState.currentIndex =
        action.payload.results.length > 0 ? 0 : -1;
      state.searchState.total = action.payload.totalResults;
    });
    builder.addCase(getMessagePage.pending, (state) => {
      state.searchState.isNavigating = true;
    });
    builder.addCase(getMessagePage.fulfilled, (state, action) => {
      state.searchState.isNavigating = false;
      state.messages = action.payload.results;
    });
    builder.addCase(getMessagePage.rejected, (state) => {
      state.searchState.isNavigating = false;
    });
  },
});

export const {
  setNewThread,
  setNewThreadTitle,
  setNewThreadEvent,
  setCurrentThread,
  setCurrentThreadTitle,
  setCurrentThreadEvent,
  updateLastThread,
  clearMessages,
  setThreads,
  resetShouldScrollToBottom,
  setSearchQuery,
  clearSearch,
  setMessages,
  setSearchIndex,
  setSearchResults,
  setSearchState,
} = messengerSlice.actions;

export default messengerSlice.reducer;
