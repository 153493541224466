import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './assets/css/App.css';
import store from './contexts/redux/store';
import AuthLayout from './layouts/auth';
import MainLayout from './layouts/main';

import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AddBriefNoteDialog from 'components/addBriefNoteDialog';
import AddEventClientDocumentDialog from 'components/addEventClientDocumentDialog';
import AddEventContractDialog from 'components/addEventContractDialog';
import AddPaymentDialog from 'components/addIPaymentDialog';
import AddTrackerCustomEventDialog from 'components/addTrackerCustomEventDialog';
import AddTrackerNoteDialog from 'components/addTrackerNoteDialog';
import AlertDialogsContainer from 'components/alertDialogsContainer';
import AppLoader from 'components/appLoader';
import AssignDocNumberDialog from 'components/assignDocNumberDialog';
import ContactsPickerDialog from 'components/contactsPickerDialog';
import ContractSharingDialog from 'components/contractSharingDialog';
import DeletedExternalEventsDialog from 'components/deletedExternalEventsDialog';
import DeletedExternalMeetingsDialog from 'components/deletedExternalMeetingsDialog';
import { ReportUserDialog } from 'components/dialogs/ReportUserDialog';
import EventsPickerDialog from 'components/eventsPickerDialog';
import ExternalSupplierDialog from 'components/externalSupplierDialog';
import { InterceptorComponent } from 'components/interceptor/InterceptorComponent';
import LineUpFormDialog from 'components/lineUpFormDialog';
import OfferClientToSupplierDialog from 'components/offerClientToSupplierDialog';
import ProtectedRoute from 'components/ProtectedRoute';
import QuotePickerDialog from 'components/quotePickerDialog';
import QuoteSharingDialog from 'components/quoteSharingDialog';
import RequestQuoteDialog from 'components/requestQuoteDialog';
import RequestSupplierDetailsDialog from 'components/requestSupplierDetails';
import ScheduleAnchorMeetingDialog from 'components/scheduleAnchorMeetingDialog';
import SelectClientDialog from 'components/selectClientDialog';
import SendQuoteDialog from 'components/sendQuoteDialog';
import Splash from 'components/splash';
import UploadQuoteDialog from 'components/uploadQuoteDialog';
import useUpdateFFTheme from 'helpers/hooks/useUpdateFFTheme';
import * as ReactGA from 'react-ga';
import NoMatch from 'views/admin/main/others/404';
import AddParticipantModal from 'views/main/event/components/AddParticipantModal';
import PaymentDetailsModal from 'views/main/event/components/financeCard/paymentDetailsModal/PaymentDetailsModal';
import UploadSignedContractDialog from 'views/main/finance/contract/contracts/components/UploadSignedContractDialog';
import { ViewPhotoModal } from 'views/main/gallery/components/ViewPhotoModal';
import AddCalendarEventModal from 'views/main/managment/calendar/components/AddCalendarEventModal';
import AddCalendarModal from 'views/main/managment/calendar/components/AddCalendarModal';
import CalendarEventDetailsModal from 'views/main/managment/calendar/components/CalendarEventDetailsModal';
import { CalendarsModal } from 'views/main/managment/calendar/components/CalendarsModal';
import ShareMyCalendarModal from 'views/main/managment/calendar/components/ShareMyCalendarModal';
import { ClientFoundModal } from 'views/main/managment/clients/components/ClientFoundModal';
import { StatusChangeModal } from 'views/main/managment/events/eventsOverview/components/StatusChangeModal';
import { AddSupplierModal } from 'views/main/managment/suppliers/components/AddSupplierModal';
import FileShareDialog from 'views/main/operations/files/components/share-dialog/FileShareDialog';
import InvoiceTypeDialog from 'views/main/finance/invoice/invoicesOverview/components/SelectTypeModal';
import FileSharedWithDialog from 'views/main/operations/files/components/share-dialog/FileSharedWithDialog';
import Token from 'views/token';
const environment = process.env.NODE_ENV || 'local'; // 'development', 'production', 'staging', or 'local'
// Chakra imports
Sentry.init({
  dsn: 'https://61551ad1adc6571284abe87d86ca1361@o4507521378287616.ingest.de.sentry.io/4507521416036432',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/app.brillevent\.com/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: environment,
});

ReactGA.initialize('G-VG0G6FQB6E');

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 2, // Cache for 2 minutes
      staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes
    } as { cacheTime: number; staleTime: number },
  },
});

function InviteQuoteRedirect() {
  const location = useLocation();
  return <Navigate to={`/main/invite-quote${location.search}`} />;
}

export default function Main() {
  const { theme, setTheme } = useUpdateFFTheme();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <InterceptorComponent />
          <Routes>
            <Route path="token/*" element={<Token />} />
            <Route element={<ProtectedRoute />}>
              <Route
                path="main/*"
                element={<MainLayout theme={theme} setTheme={setTheme} />}
              />
              <Route
                path="/auth/invite-quote"
                element={<InviteQuoteRedirect />}
              />
              <Route
                path="/"
                element={<Navigate to="/main/dashboard" replace />}
              />
            </Route>
            <Route path="auth/*" element={<AuthLayout />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
          <CalendarsModal />
          <AddCalendarModal />
          <ShareMyCalendarModal />
          <ViewPhotoModal />
          <EventsPickerDialog />
          <QuotePickerDialog />
          <ContactsPickerDialog />
          <Splash />
          <AppLoader />
          <RequestQuoteDialog />
          <DeletedExternalEventsDialog />
          <DeletedExternalMeetingsDialog />
          <AlertDialogsContainer />
          <AddTrackerNoteDialog />
          <AddCalendarEventModal />
          <StatusChangeModal />
          <CalendarEventDetailsModal />
          <ReportUserDialog />
          <QuoteSharingDialog />
          <ClientFoundModal />
          <AddBriefNoteDialog />
          <LineUpFormDialog />
          <AddEventClientDocumentDialog />
          <AddEventContractDialog />
          <AddParticipantModal />
          {/* <SelectSupplierDialog /> */}
          <AddSupplierModal />
          <AddTrackerCustomEventDialog />
          <SelectClientDialog />
          <PaymentDetailsModal />
          <AddPaymentDialog />
          <ContractSharingDialog />
          <UploadSignedContractDialog />
          <SendQuoteDialog />
          <FileShareDialog />
          <FileSharedWithDialog />
          <AssignDocNumberDialog />
          <ScheduleAnchorMeetingDialog />
          <UploadQuoteDialog />
          <RequestSupplierDetailsDialog />
          <ExternalSupplierDialog />
          <OfferClientToSupplierDialog />
          <InvoiceTypeDialog />
        </ChakraProvider>
      </Provider>
    </QueryClientProvider>
  );
}
