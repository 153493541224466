import { Icon, Image, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { LuMapPinOff } from 'react-icons/lu';
import { kelvinToCelsius } from 'helpers/kelvinToCelsius';
import { useWeatherData } from 'contexts/redux/weather/weatherSlice';
import useWeather from 'hooks/useWeather';
import { useTranslation } from 'react-i18next';
import WeatherIcon from '../../assets/svg/navbar/weather-icon.svg';

export default function Weather() {
  const { t } = useTranslation();
  const weatherData = useWeatherData();
  const {
    isOpen: isOpenTooltip,
    onOpen: onOpenTooltip,
    onClose: onCloseTooltip,
  } = useDisclosure();

  // get weather data
  useWeather();

  if (!weatherData) {
    return (
      <Tooltip
        variant="business"
        isOpen={isOpenTooltip}
        hasArrow
        label={t('weather.enable_location')}
        placement="right">
        <span
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <Icon
            as={LuMapPinOff}
            w="20px"
            h="20px"
            color="#93C3B3"
            onMouseEnter={onOpenTooltip}
            onMouseLeave={onCloseTooltip}
          />
        </span>
      </Tooltip>
    );
  }

  return (
    <>
      <Text fontSize="12px" fontWeight="400" color="primary.100">
        {kelvinToCelsius(weatherData.main.temp).toFixed(0)}
        ºC
      </Text>
      {/* TODO: add weather icon */}
      <Image src={WeatherIcon} alt="" w="24px" h="24px" />
    </>
  );
}
