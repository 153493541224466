import { Button, Divider, Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
import { IClient, IEvent, UrlItem } from 'services/@types';
import Header from 'views/main/finance/quote/previewQuote/components/Header';
import NoteAndTerms from 'views/main/finance/quote/previewQuote/components/NoteAndTerms';
import ProductsList from 'views/main/finance/quote/previewQuote/components/ProductsList';
import Summary from 'views/main/finance/quote/previewQuote/components/Summary';
import ContactDetails from './components/ContactDetails';
import DeclineQuoteDialog from './components/DeclineQuoteDialog';
import useApproveQuote from './hooks/useApproveQuote';

export default function ApproveQuote() {
  const { t } = useTranslation();
  const {
    quote,
    business,
    memoizedIsDownloading,
    forcedDesktopView,
    componentRef,
    setForcedDesktopView,
    setIsDownloading,
    handleDownloadPdf,
    memoDiscount,
    handleApprove,
    handleDecline,
    isDeclineQuoteDialogOpen,
    onDeclineQuoteDialogClose,
    onDeclineQuoteDialogOpen,
    comment,
    setComment,
  } = useApproveQuote();

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      height="100%"
      w={{ lg: '100%' }}
      pb="100px"
      pt={{ base: '200px', md: '80px', xl: '80px' }}
      overflow="auto">
      <Card
        alignItems="center"
        flexDirection="column"
        ref={componentRef}
        w={forcedDesktopView.state ? '1016px' : '100%'}
        bg="#FFFFFF"
        gap="36px"
        pb="100px"
        h="max-content"
        px={{ base: '30px', md: '100px' }}>
        <Flex w="100%" pt="17px" flexDirection="column" gap="8px">
          <Header business={business} />
          <Divider borderColor="#00000033" />
          <ContactDetails
            setIsDownloading={setIsDownloading}
            isDownloading={memoizedIsDownloading || forcedDesktopView?.state}
            handleDownload={() => {
              setForcedDesktopView({
                state: true,
                executor: handleDownloadPdf,
              });
            }}
            createdAt={quote?.createdAt}
            quoteNumber={quote?.quoteNumber}
            clientName={quote?.clients
              .map(
                (client: { client: IClient }) =>
                  `${client?.client?.firstName || ''} ${
                    client?.client?.lastName || ''
                  }`,
              )
              .join(' ')}
            numberOfGuests={quote?.numberOfGuests}
            location={quote?.location}
            totalAmount={quote?.generalPaymentDetails?.totalPayment}
            currency={quote?.currency}
            event={quote?.eventId as unknown as IEvent}
          />
          <ProductsList products={quote?.products} currency={quote?.currency} />
          <Divider borderColor="#00000033" />
          <Flex
            justify="space-between"
            gap="24px"
            flexDirection={{
              base: forcedDesktopView.state ? 'row' : 'column',
              md: 'row',
            }}>
            <NoteAndTerms
              comment={quote?.comment}
              paymentTerms={quote?.paymentTerms}
              legalText={quote?.legalText}
              media={quote?.media?.[0] as UrlItem}
            />
            <Summary
              currency={quote?.currency}
              total={quote?.generalPaymentDetails?.total}
              discount={memoDiscount}
              totalPayment={
                quote?.generalPaymentDetails?.totalIncludingDiscount
              }
              vat={quote?.generalPaymentDetails?.vat}
              amountToPay={quote?.generalPaymentDetails?.totalPayment}
              paymentInInstallments={quote?.paymentInInstallments}
            />
          </Flex>
        </Flex>
        <Flex
          justifyContent="end"
          gap="10px"
          alignItems="center"
          w="100%"
          display={
            forcedDesktopView?.state || memoizedIsDownloading ? 'none' : 'flex'
          }>
          {quote?.status === 'pendingApproval' && (
            <>
              <Button variant="h1cta" w="320px" onClick={handleApprove}>
                {t('quote_preview.approve')}
              </Button>
              <Button
                variant="h2outlined"
                w="320px"
                onClick={onDeclineQuoteDialogOpen}>
                {t('quote_preview.decline')}
              </Button>
            </>
          )}
        </Flex>
        <DeclineQuoteDialog
          isOpen={isDeclineQuoteDialogOpen}
          onClose={onDeclineQuoteDialogClose}
          handleDecline={handleDecline}
          comment={comment}
          setComment={setComment}
        />
      </Card>
    </Flex>
  );
}
