import { Flex, Hide, Text, useMediaQuery, VStack } from '@chakra-ui/react';
import DropDownField from 'components/fields/DropdownField';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IInvoice } from 'services/@types';
import InvoiceRow from './InvoiceRow';

const headers = ['', 'customer', 'amount', 'date', ''];

const headersMobile = ['', 'customer', 'amount', ''];

interface IInvoicesProps {
  invoices: IInvoice[];
  options: any;
  showedDocumentType: any;
  selectedDocumentType: any;
  onSelectedDocumentType: any;
}

export default function Invoices(props: IInvoicesProps) {
  const {
    invoices,
    options,
    showedDocumentType,
    selectedDocumentType,
    onSelectedDocumentType,
  } = props;
  const { t } = useTranslation();

  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const memoHeaders = useMemo(() => {
    return isMobile ? headersMobile : headers;
  }, [isMobile]);

  return (
    <VStack w="100%" spacing={0} align="stretch" overflowY="hidden">
      <Flex py={4} px={2} bg="white">
        {memoHeaders.map((header, index) => (
          <Flex
            key={header}
            flex={index === memoHeaders.length - 1 ? '0 0 60px' : 1}
            align="center"
            fontSize="14px"
            color="#00000099">
            {header ? (
              <Text>
                {t(`invoices_overview.header.${header.toLowerCase()}`)}
              </Text>
            ) : index === 0 ? (
              isMobile ? (
                <Text>{t('invoices_overview.type')}</Text>
              ) : (
                <DropDownField
                  options={options}
                  closeOnSelect
                  showedOptions={showedDocumentType}
                  selectedOptions={selectedDocumentType}
                  onSelected={onSelectedDocumentType}
                  placeholder={t('invoices_overview.type')}
                  w="120px"
                />
              )
            ) : (
              ''
            )}
          </Flex>
        ))}
      </Flex>

      {/* Rows */}
      <VStack spacing={0} align="stretch" overflowY="auto">
        {invoices.map((invoice) => (
          <InvoiceRow key={invoice.id} invoice={invoice} />
        ))}
      </VStack>
    </VStack>
  );
}
