import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import SwitchField from 'components/fields/SwitchField';
import {
  closeAlertDialog,
  openAlertDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import { IClient } from 'services/@types';
import useClientForm from '../hooks/useClientForm';
import AddNewClientContact from './AddNewClientContact';
import ChosenContact from './ChosenContact';

interface AddClientModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function AddClientModal(props: AddClientModalProps) {
  const { isOpen, onClose } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const {
    clientForms,
    handleChange,
    handleAddNewForm,
    handleRemoveForm,
    handleSubmitAll,
    handleCreateContact,
    handleRemoveContact,
    isEdit,
    handleClose,
    handleOpenContactCreate,
    handleCloseContactCreate,
  } = useClientForm({
    onClose,
  });

  const {
    isOpen: isCreateContactOpen,
    onClose: onCloseCreateContact,
    onOpen: onOpenCreateContact,
  } = useDisclosure();

  const handleOpenContact = useCallback(
    (formId: string) => {
      handleOpenContactCreate(formId);
      onOpenCreateContact();
    },
    [handleOpenContactCreate, onOpenCreateContact],
  );

  const handleCloseContact = useCallback(() => {
    handleCloseContactCreate();
    onCloseCreateContact();
  }, [handleCloseContactCreate, onCloseCreateContact]);

  const handleDeleteClient = useCallback(
    (clientFormId: string) => {
      dispatch(
        openAlertDialog({
          title: t('client_form.dialog.title'),
          content: t('client_form.dialog.description'),
          onConfirm: () => {
            handleRemoveForm(clientFormId);
            dispatch(closeAlertDialog());
          },
          onClose: () => {
            dispatch(closeAlertDialog());
          },
        }),
      );
    },
    [dispatch, handleRemoveForm, t],
  );

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent
        position="relative"
        maxH={{ md: '84dvh' }}
        overflow="hidden"
        borderRadius={{ base: '0px', md: '30px' }}
        h={{ base: '100%', md: 'auto' }}>
        {/* main content */}
        <VStack
          w="100%"
          p="24px"
          gap="50px"
          overflow="auto"
          h="100%"
          alignItems="flex-start"
          flexDir="column">
          {/* header */}
          <VStack spacing={0} gap={3} alignItems="flex-start" w="100%">
            <Flex justifyContent="space-between" w="full">
              <Flex flexDir="column" gap={1}>
                <Text variant="previewQuoteContactTitle">
                  {isEdit ? t('clients.edit_client') : t('clients.add_client')}
                </Text>
                <Text
                  variant="addClientSubTitle"
                  display={isEdit ? 'none' : 'block'}>
                  {t('clients.add_client_description')}
                </Text>
              </Flex>
              <IconButton
                aria-label="close"
                icon={<Icon as={CloseIcon} color="#939393" w="12px" h="12px" />}
                onClick={handleClose}
              />
            </Flex>
          </VStack>
          <VStack spacing={0} w="full" gap={7} align="start">
            {/* Client Forms */}
            {clientForms.map((clientForm, index) => (
              <Box
                key={clientForm.id}
                w="full"
                // borderRadius="15px"
                bg="white">
                <VStack w="full" gap={6}>
                  {!isEdit && (
                    <Flex
                      w="full"
                      justifyContent="space-between"
                      alignItems="center">
                      <HStack w="full" justifyContent="start" align="center">
                        <Text
                          fontSize="base"
                          lineHeight="28px"
                          color="black"
                          fontWeight={600}>
                          {t('client_form.client', { index: index + 1 })}
                        </Text>
                        {clientForms.length > 1 && (
                          <IconButton
                            aria-label="remove client"
                            icon={<CloseIcon w="12px" h="12px" />}
                            onClick={() => handleDeleteClient(clientForm.id)}
                            variant="ghost"
                            colorScheme="gray"
                          />
                        )}
                      </HStack>
                    </Flex>
                  )}

                  {/* Client Type Switch */}
                  <VStack spacing={0} alignItems="flex-start" gap={1} w="100%">
                    <SwitchField
                      id={`client-type-${clientForm.id}`}
                      w="100%"
                      wrapperProps={{
                        w: '100%',
                        p: '0px',
                      }}
                      labelProps={{
                        marginLeft: i18n.dir() === 'rtl' ? 0 : '12px',
                        marginRight: i18n.dir() === 'rtl' ? '12px' : 0,
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                      colorScheme="yellow"
                      color="#AA915D"
                      display="flex"
                      labelColor="#AA915D"
                      reversed
                      label={t('clients.business_client')}
                      isChecked={clientForm.form.type === 'business'}
                      onChange={() =>
                        handleChange(clientForm.id, {
                          target: {
                            name: 'type',
                            value:
                              clientForm.form.type === 'business'
                                ? 'private'
                                : 'business',
                          },
                        } as any)
                      }
                    />
                    <Text fontSize="12px" color="gray.500">
                      {t('clients.business_client_warning')}
                    </Text>
                  </VStack>

                  {/* fields */}
                  <Flex flexDir="column" gap="24px">
                    {clientForm.form.type === 'business' && (
                      <Text
                        fontSize={'base'}
                        lineHeight="28px"
                        color="black"
                        fontWeight={600}>
                        {t('clients_business_details.business_details')}
                      </Text>
                    )}
                    <Flex flexWrap="wrap" gap="24px 12px">
                      {getFieldsForClientType(clientForm.form.type).map(
                        (field) => (
                          <InputField
                            key={field.key}
                            name={field.key}
                            value={String(
                              clientForm.form[field.key as keyof IClient] || '',
                            )}
                            onChange={(e) => handleChange(clientForm.id, e)}
                            isInvalid={clientForm.errors.includes(field.key)}
                            flex={{ base: '1', md: '1 1 30%' }}
                            w={{
                              base: '100%',
                              md: `calc((100% - 12px) / 2)`,
                              lg: `calc((100% - 24px) / 3)`,
                            }}
                            label={
                              t(
                                `${
                                  clientForm.form.type === 'business'
                                    ? 'clients_business_details.label'
                                    : 'clients'
                                }.${field.key}`,
                              ) + (field.isRequired ? ' *' : '')
                            }
                            borderColor={
                              clientForm.errors.includes(field.key)
                                ? 'red.500'
                                : 'gray.300'
                            }
                            placeholder={t(`clients.${field.key}`)}
                            type={field.type}
                          />
                        ),
                      )}
                    </Flex>

                    {/* Business Contacts Section */}
                    {clientForm.form.type === 'business' && (
                      <>
                        <Text
                          fontSize={'base'}
                          lineHeight="28px"
                          color="black"
                          fontWeight={600}>
                          {t('client_contact.label')}
                        </Text>
                        <Button
                          margin={'0 !important'}
                          onClick={() => handleOpenContact(clientForm.id)}
                          variant="h2outlined"
                          w="full"
                          style={{
                            outline: clientForm.errors.includes('contacts')
                              ? '1px solid #FF554A'
                              : '',
                            outlineOffset: '4px',
                          }}
                          maxW={{ base: 'unset', md: '320px' }}>
                          {t('client_contact.title')}
                        </Button>
                        <HStack gap={2} wrap="wrap" w="full">
                          {clientForm.form.contacts?.map(
                            (contact, contactIndex) => (
                              <ChosenContact
                                key={contactIndex}
                                contact={contact}
                                handleRemoveContact={() =>
                                  handleRemoveContact(
                                    clientForm.id,
                                    contactIndex,
                                  )
                                }
                              />
                            ),
                          )}
                        </HStack>
                      </>
                    )}

                    {!isEdit && (
                      <Checkbox
                        variant="roundBrand"
                        borderColor="#AA915D"
                        isChecked={clientForm.isInviteToBrilliant}
                        onChange={(e) =>
                          handleChange(clientForm.id, {
                            target: {
                              name: 'isInviteToBrilliant',
                              type: 'checkbox',
                              checked: e.target.checked,
                              value: String(e.target.checked),
                            },
                          } as any)
                        }
                        alignItems="center">
                        <Text variant="inviteToBrilliant">
                          {t('clients.invite_to_brilliant')}
                        </Text>
                      </Checkbox>
                    )}
                  </Flex>
                </VStack>
              </Box>
            ))}
          </VStack>
          {!isEdit && (
            <HStack>
              <IconButton
                borderRadius="10px"
                icon={<Icon as={FaPlus} color="white" w="16px" h="16px" />}
                onClick={handleAddNewForm}
                aria-label="add"
                w="24px"
                h="24px"
                minW="24px"
                bg="#93C3B3"
              />
              <Text variant="addRowText">{t('clients.add_client')}</Text>
            </HStack>
          )}

          {/* footer */}
          <Flex justifyContent="center" w="100%">
            <Button
              variant="h1cta"
              w={{ base: '100%', md: '320px' }}
              onClick={handleSubmitAll}>
              {t('clients.save')}
            </Button>
          </Flex>

          {/* add new contact slide */}
          <AddNewClientContact
            isCreateContactOpen={isCreateContactOpen}
            onCloseCreateContact={handleCloseContact}
            onOpenCreateContact={() => {}}
            handleCreateContact={(contact) => {
              handleCreateContact(contact);
              handleCloseContact();
            }}
          />
        </VStack>
      </ModalContent>
    </Modal>
  );
}

// Helper function to get fields based on client type
function getFieldsForClientType(type: string | undefined) {
  if (type === 'business') {
    return [
      { key: 'company', type: 'text', isRequired: true },
      { key: 'address', type: 'text' },
      { key: 'phone', type: 'text', isRequired: true },
      { key: 'email', type: 'email', isRequired: true },
      { key: 'idNumber', type: 'text' },
    ];
  }
  return [
    { key: 'firstName', type: 'text', isRequired: true },
    { key: 'lastName', type: 'text', isRequired: true },
    { key: 'phone', type: 'text', isRequired: true },
    { key: 'address', type: 'text' },
    { key: 'email', type: 'email', isRequired: true },
    { key: 'idNumber', type: 'text' },
  ];
}
