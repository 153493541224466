import { Flex, Icon, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import NoEventsImg from 'assets/svg/event/not-found.svg';
import Card from 'components/card/Card';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useGetQuotes } from 'queries/quote';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit3 } from 'react-icons/fi';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { QuoteItem } from './QuoteItem';
import { Summary } from './Summary';
import { useDispatch } from 'react-redux';
import { setNewQuoteEventId } from 'contexts/redux/quote/quoteSlice';

type ClientQuoteProps = {
  eventId: string;
};

export const ClientQuote = ({ eventId }: ClientQuoteProps) => {
  const { t } = useTranslation();
  const business = useMyBusiness();
  const { style, ref } = useHighlightAnimation('quote');
  const { appNavigate: navigate } = useAppNavigate();
  const dispatch = useDispatch();

  const { data: quotesData, isPending: isLoading } = useGetQuotes({
    // supplierBusinessId: business?.id,
    businessId: business?.id,
    eventId,
  });
  const quote = useMemo(() => quotesData?.results[0], [quotesData]);

  const handleEditQuote = useCallback(() => {
    if (quote?.id) {
      navigate(`/main/finance/quotes/${quote?.id}`);
    } else {
      dispatch(setNewQuoteEventId(eventId));
      navigate('/main/finance/quotes/create');
    }
  }, [quote?.id, navigate, dispatch, eventId]);
  return (
    <Card
      ref={ref}
      {...style}
      w="100%"
      flex={1}
      zIndex={0}
      minW={{ base: '100%', lg: '310px' }}
      maxH="620px"
      borderRadius={{ base: 0, md: '30px' }}
      gap="20px">
      <Flex alignItems="center" justify="space-between" h="40px">
        <Text variant="eventPageCardTitle">{t('event.top_bar_tabs.sub_tabs.quote')}</Text>
        <Icon
          as={FiEdit3}
          color="#999EA9"
          w="24px"
          h="24px"
          cursor="pointer"
          onClick={handleEditQuote}
        />
      </Flex>
      {isLoading ? (
        <Flex
          w="100%"
          h="100%"
          flex={1}
          alignItems="center"
          justifyContent="center">
          <Spinner color="brand.500" />
        </Flex>
      ) : quote?.products.length > 0 ? (
        <VStack w="full" gap={5}>
          <VStack
            w="full"
            spacing={0}
            overflow="auto"
            letterSpacing="tight"
            lineHeight="4"
            mb={4}
            gap={{ base: 5, lg: '20px' }}
            color="blackAlpha.600">
            {quote?.products.map((product, index) => (
              <QuoteItem
                currency={quote.currency === 'ILS' ? '₪' : '$'}
                key={product.productId}
                product={product}
                number={index + 1}
              />
            ))}
          </VStack>
          <Summary quote={quote} />
        </VStack>
      ) : (
        <VStack
          w="100%"
          h="100%"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          gap="24px"
          fontWeight={500}
          textAlign="center">
          <Image src={NoEventsImg} w="180px" h="180px" alt="Quote Not Found" />
          <Text color="#00000033" fontSize="24px" lineHeight="26px">
            {t('event.no_data_found')}
          </Text>
          <Text color="#00000033" fontSize="14px" lineHeight="18px">
            {t('event.no_data_message')}
          </Text>
        </VStack>
      )}
    </Card>
  );
};
