import { useDisclosure, useToast } from '@chakra-ui/react';
// Custom components
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { closeAddSupplierDialog } from 'contexts/redux/dialog/dialogsSlice';
import { createInvite } from 'contexts/redux/invite/inviteSlice';
import { RootState } from 'contexts/redux/store';
import {
  createSupplier,
  setCurrentSupplier,
  updateSupplier,
} from 'contexts/redux/supplier/supplierSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Business, ContactPerson, IClient, Supplier } from 'services/@types';
import SupplierService from 'services/supplier.api';
import { isValidEmail } from 'utils/isValidEmail';
import { isValidPhone } from 'utils/isValidPhone';
import { AddSupplierModalProps } from '../components/AddSupplierModal';
import { useFindSupplier } from '../hooks/useFindBusiness';

const useAddSupplierModal = ({
  isOpen,
  onClose,
  editableSupplier,
  onFoundExistingSupplier,
}: AddSupplierModalProps) => {
  const dispatch = useDispatch<any>();
  const { addSupplierDialog } = useSelector(
    (state: RootState) => state.dialogs,
  );
  const myBusiness = useMyBusiness();
  const myUser = useMyUser();
  const {
    isOpen: isContactMenuOpen,
    onOpen: onContactMenuOpen,
    onClose: onContactMenuClose,
  } = useDisclosure();

  const toast = useToast();
  const { t } = useTranslation();
  const [supplierProps, setSupplierProps] = useState<Partial<Supplier>>({});
  const [errorLabels, setErrorLabels] = useState<{
    name: string;
    email: string;
    category: string;
    contacts: { email?: string; phone?: string }[];
  }>({
    name: '',
    email: '',
    category: '',
    contacts: [],
  });
  const [inviteCheckbox, setInviteCheckbox] = useState(false);
  const { businessCategories } = useSelector(
    (state: RootState) => state.businessCategories,
  );
  const { loading } = useSelector((state: RootState) => state.suppliers);

  const handleSupplierAction = useCallback((business: Business) => {
    setSupplierProps({
      businessID: business.id,
      phone: business.phone,
      name: business.businessName,
      email: business.email,
      address: business.address,
      vatId: business.businessVATId,
      category: business.category,
    });
  }, []);
  const { findBusinessBy, loadingExistingSupplier } =
    useFindSupplier(handleSupplierAction);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorLabels({ name: '', email: '', category: '', contacts: [] });
      setSupplierProps({ ...supplierProps, [e.target.id]: e.target.value });
    },
    [supplierProps],
  );
  const handleChangeBankDetails = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrorLabels({
        name: '',
        email: '',
        category: '',
        contacts: [],
      });
      setSupplierProps({
        ...supplierProps,
        bankDetails: {
          ...supplierProps.bankDetails,
          [e.target.id]: e.target.value,
        },
      });
    },
    [supplierProps],
  );

  const handleClose = useCallback(() => {
    dispatch(closeAddSupplierDialog());
    onClose?.();
    setErrorLabels({ name: '', email: '', category: '', contacts: [] });
  }, [dispatch, onClose]);

  const handleTagsChange = useCallback((tags: string[]) => {
    setSupplierProps((prev) => ({
      ...prev,
      tags: tags.filter((tag) => tag.length > 0),
    }));
  }, []);

  const handleContactChange = useCallback(
    (contactDetails: ContactPerson, index: number) => {
      setErrorLabels({
        name: '',
        email: '',
        category: '',
        contacts: [],
      });
      if (Object.keys(contactDetails).length === 0) {
        console.log('empty contact...');
        return;
      }
      setSupplierProps((prevProps) => {
        const newContacts = prevProps.contacts ? [...prevProps.contacts] : [];
        newContacts[index] = contactDetails;
        return { ...prevProps, contacts: newContacts };
      });
    },
    [],
  );

  useEffect(() => {
    if (editableSupplier) {
      setSupplierProps({
        ...editableSupplier,
        category: editableSupplier.category?.map((c: any) =>
          typeof c === 'string' ? c : c?.id,
        ),
      });
    } else {
      setSupplierProps({});
    }
  }, [editableSupplier, isOpen, handleClose]);

  useEffect(() => {
    if (!editableSupplier) {
      if (addSupplierDialog?.item?.clientDetails) {
        const clientDetails = addSupplierDialog?.item?.clientDetails as IClient;
        const payload: Partial<Supplier> = {};
        if (clientDetails?.type === 'business') {
          payload.vatId = clientDetails?.idNumber;
          payload.name = clientDetails?.company;
          payload.email = clientDetails?.email;
          payload.phone = clientDetails?.phone;
          payload.address = clientDetails?.address;
          payload.contacts = clientDetails?.contacts.map((contact) => ({
            fullName: contact?.firstName
              ? contact?.firstName + ' ' + contact?.lastName
              : '',
            email: contact?.email || '',
            phone: contact?.phone || '',
          }));
        } else {
          payload.email = clientDetails?.email;
          payload.phone = clientDetails?.phone;
          payload.contacts = [
            {
              fullName:
                clientDetails?.firstName + ' ' + clientDetails?.lastName,
              email: clientDetails?.email || '',
              phone: clientDetails?.phone || '',
            },
          ];
        }

        setSupplierProps(payload);
      }
    }
  }, [addSupplierDialog?.item?.clientDetails, editableSupplier]);

  const validate = useCallback(() => {
    let isValid = true;
    if (!supplierProps.name) {
      setErrorLabels((prev) => ({
        ...prev,
        name: t('suppliers.errors.name'),
      }));
      isValid = false;
    }
    if (!supplierProps.email) {
      setErrorLabels((prev) => ({
        ...prev,
        email: t('suppliers.errors.email'),
      }));
      isValid = false;
    }
    if (!isValidEmail(supplierProps.email)) {
      setErrorLabels((prev) => ({
        ...prev,
        email: t('suppliers.errors.invalid_email'),
      }));
      isValid = false;
    }
    if (!supplierProps.category || supplierProps.category.length === 0) {
      setErrorLabels((prev) => ({
        ...prev,
        category: t('suppliers.errors.category'),
      }));
      isValid = false;
    }

    if (supplierProps.contacts?.length > 0) {
      supplierProps.contacts.forEach((contact) => {
        if (
          !contact.email ||
          !isValidEmail(contact.email) ||
          !contact.phone ||
          !isValidPhone(contact.phone)
        ) {
          isValid = false;
        }
        setErrorLabels((prev) => {
          const newContacts = [...prev.contacts];
          const newContactErrors: { email?: string; phone?: string } = {};
          if (!contact.email || !isValidEmail(contact.email)) {
            newContactErrors.email = t('suppliers.errors.invalid_email');
          }
          if (!contact.phone || !isValidPhone(contact.phone)) {
            newContactErrors.phone = t('suppliers.errors.invalid_phone');
          }
          if (Object.keys(newContactErrors).length > 0) {
            newContacts.push(newContactErrors);
          } else {
            newContacts.push({});
          }
          return { ...prev, contacts: newContacts };
        });
      });
    }

    return isValid;
  }, [
    supplierProps?.name,
    supplierProps?.email,
    supplierProps?.category,
    t,
    supplierProps?.contacts,
  ]);

  const onAddContact = useCallback(() => {
    if (supplierProps?.contacts?.length === 2) {
      console.debug('max contacts reached');
      return;
    }
    if (!supplierProps.contacts || supplierProps.contacts?.length === 0) {
      setSupplierProps((prev) => ({ ...prev, contacts: [{}] }));
    }
    setSupplierProps((prev) => ({
      ...prev,
      contacts: [
        ...prev.contacts,
        {
          fullName: '',
          email: '',
          phone: '',
        },
      ],
    }));
    console.log('contacts ->', supplierProps.contacts);
  }, [supplierProps]);
  const onRemoveContact = useCallback((index: number) => {
    console.log('remove contact');
    setSupplierProps((prev) => ({
      ...prev,
      contacts: prev.contacts?.filter((_, i) => i !== index),
    }));
  }, []);

  const handleSave = useCallback(async () => {
    if (!validate()) {
      return;
    }
    if (editableSupplier) {
      const res = await dispatch(
        updateSupplier({
          supplierId: supplierProps.id,
          updates: supplierProps,
        }),
      );
      if (res?.error) {
        toast({
          variant: 'error',
          position: 'top-right',
          title: res?.payload?.response?.data?.message,
        });
        return;
      }
      if (res?.payload) {
        dispatch(setCurrentSupplier(res.payload));
      }
      toast({
        variant: 'main',
        title: t('suppliers.toast.supplier_updated'),
      });
    } else {
      // check if supplier already exists
      const supplierExists = await SupplierService.getSuppliers({
        email: supplierProps?.email,
        creatorUserID: myUser?.id,
        creatorBusinessID: myBusiness?.id,
      });

      if (supplierExists?.results?.length > 0) {
        toast({
          variant: 'error',
          position: 'top-right',
          title: t('suppliers.toast.supplier_already_exists'),
        });
        return;
      }
      const res = await dispatch(
        createSupplier({ invite: inviteCheckbox, supplier: supplierProps }),
      );
      if (res?.error) {
        toast({
          variant: 'error',
          position: 'top-right',
          title: res?.payload?.response?.data?.message,
        });
        return;
      }
      if (inviteCheckbox) {
        dispatch(
          createInvite({
            email: supplierProps.email,
            businessID: myBusiness.id,
            type: 'supplier',
            tags: supplierProps.tags,
            fullName: supplierProps.name,
            phone: supplierProps.phone,
          }),
        );
      }
      toast({
        variant: 'main',
        title: t('suppliers.toast.supplier_added'),
      });

      if (addSupplierDialog?.onConfirm) {
        addSupplierDialog.onConfirm(res?.payload?.id);
      }
    }
    handleClose();
  }, [
    dispatch,
    editableSupplier,
    inviteCheckbox,
    myBusiness?.id,
    handleClose,
    supplierProps,
    t,
    toast,
    validate,
    myUser?.id,
    addSupplierDialog,
  ]);
  return {
    supplierProps,
    setSupplierProps,
    handleSave,
    handleClose,
    isContactMenuOpen,
    onContactMenuOpen,
    onContactMenuClose,
    handleChange,
    handleChangeBankDetails,
    handleTagsChange,
    handleContactChange,
    onAddContact,
    onRemoveContact,
    errorLabels,
    loading,
    loadingExistingSupplier,
    businessCategories,
    validate,
    addSupplierDialog,
    inviteCheckbox,
    setInviteCheckbox,
    findBusinessBy,
    setErrorLabels,
  };
};
export default useAddSupplierModal;
