import {
  Button,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import DropdownField from 'components/fields/DropdownField';
import Dropzone from 'components/dropzone';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { IQuote, UrlItem } from 'services/@types';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { FaX } from 'react-icons/fa6';
import UploadService from 'services/upload.api';
import { RiDownloadCloud2Line } from 'react-icons/ri';
import { useQuoteCreate } from 'queries/quote';
import { useParams } from 'react-router-dom';
import { handleOpenPdf } from 'helpers/handleOpenPdf';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const QUOTE_STATUSES = [
  { label: 'event.quote_status.pending', value: 'draft' },
  { label: 'event.quote_status.approved', value: 'approved' },
  { label: 'event.quote_status.cancelled', value: 'cancelled' },
];

export default function UploadQuoteModal(props: IProps) {
  const { isOpen, onClose } = props;
  const params = useParams();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [quote, setQuote] = useState<IQuote | null>(null);
  const { t } = useTranslation();
  const myUser = useMyUser();

  const showedStatuses = useMemo(() => {
    const status = QUOTE_STATUSES.find((i) => i.value === quote?.status);
    return status ? t(status.label) : '';
  }, [quote?.status, t]);

  const selectedStatus = useMemo(() => {
    const status = QUOTE_STATUSES.find((i) => i.value === quote?.status);
    return status ? [{ label: t(status.label), value: status.value }] : [];
  }, [quote?.status, t]);

  const handleUploadCompleted = useCallback((url: UrlItem) => {
    setQuote((prev) => ({
      ...prev,
      media: [...(prev?.media || []), url],
    }));
    setIsUploading(false);
  }, []);

  const handleChange = useCallback((e: any) => {
    setQuote((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleRemoveQuoteFile = useCallback(async () => {
    const [target, targetId, access, fileId] = quote?.media
      ?.find((i) => i.type === 'quote')
      ?.url.split('/');
    try {
      await UploadService.deleteUpload({
        target,
        targetId,
        access,
        fileId,
      });
      setQuote((prev) => ({
        ...prev,
        media: prev?.media?.filter((i) => i.type !== 'quote'),
      }));
    } catch (error) {
      console.log('error: ', error);
    }
  }, [quote]);

  const handleChangeStatus = useCallback((e: any) => {
    setQuote((prev) => ({
      ...prev,
      status: prev?.status === e.value ? '' : e.value,
    }));
  }, []);

  const { mutate: createQuote } = useQuoteCreate();

  const handleCreateQuote = useCallback(() => {
    if (!quote) return;
    createQuote({
      ...quote,
      eventId: params?.id,
    });
    onClose();
    setQuote({});
  }, [createQuote, onClose, quote, params?.id]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent p="24px" borderRadius="30px" minW="445px" gap="24px">
        <Text variant="cardTitle">{t('event.upload_quote')}</Text>
        {!quote?.media?.find((i) => i.type === 'quote') && (
          <Dropzone
            labelSize="14px"
            maxH="68px"
            minH="68px"
            goldenUploadLabel={t('event.upload_document_here')}
            flex={1}
            cursor="pointer"
            onUploadCompleted={handleUploadCompleted}
            dropzoneOptions={{
              accept: [
                'image/*',
                'video/*',
                'audio/*',
                'application/pdf',
                'text/plain',
              ],
              multiple: false,
            }}
            onStartUpload={() => setIsUploading(true)}
            onUploadError={() => setIsUploading(false)}
            initialProgress={
              !!quote?.media?.find((i) => i.type === 'quote') ? 100 : 0
            }
            uploadIcon={
              <Icon
                as={RiDownloadCloud2Line}
                color="#AA915D"
                w="25px"
                h="25px"
              />
            }
            uploadKey="quote"
            uploadBarStyle="line"
            access="restricted"
            target="user"
            targetId={myUser?.id || ''}
          />
        )}
        {quote?.media?.find((i) => i.type === 'quote')?.url && (
          <Flex alignItems="center" gap="12px">
            <Text
              variant="trackerEventName"
              onClick={() => {
                handleOpenPdf(
                  quote?.media?.find((i) => i.type === 'quote')?.url,
                  quote?.media?.find((i) => i.type === 'quote')?.displayName,
                );
              }}>
              {quote?.media?.find((i) => i.type === 'quote')?.displayName}
            </Text>
            <IconButton
              icon={<Icon as={FaX} color="#939393" w="12px" h="12px" />}
              aria-label="add"
              variant="brand"
              onClick={handleRemoveQuoteFile}
            />
          </Flex>
        )}
        <InputField
          label={t('event.amount')}
          placeholder={'$0.00'}
          value={quote?.totalAmount}
          type="number"
          name="totalAmount"
          onChange={handleChange}
        />
        <DropdownField
          placeholder={t('event.status_title')}
          showedOptions={showedStatuses}
          selectedOptions={selectedStatus}
          options={QUOTE_STATUSES.map((i) => ({
            label: t(i.label),
            value: i.value,
          }))}
          closeOnSelect
          onSelected={handleChangeStatus}
        />
        <TextField
          label={t('event.comment')}
          placeholder={t('event.comment_placeholder')}
          value={quote?.comment}
          name="comment"
          onChange={handleChange}
        />
        <Button
          variant="h1cta"
          isLoading={isUploading}
          isDisabled={
            !quote?.totalAmount ||
            !quote?.status ||
            !quote?.media?.find((i) => i.type === 'quote') ||
            !quote?.comment
          }
          onClick={handleCreateQuote}>
          {t('event.upload_quote')}
        </Button>
      </ModalContent>
    </Modal>
  );
}
