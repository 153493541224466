import { VStack, HStack, Text, Image, Box } from '@chakra-ui/react';
import { formatCurrency } from 'helpers/formatNumber';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IQuote } from '../../../../services/@types';

interface SummaryProps {
  quote: IQuote;
}
export const Summary = ({ quote }: SummaryProps) => {
  const { t } = useTranslation();
  const { data: businessPreferences } = useGetBusinessPreferences();

  const formattedSum = useMemo(() => {
    if (!quote) return { sum: '0', vat: '0', total: '0' };
    return {
      sum: formatCurrency(quote.generalPaymentDetails.totalPayment),
      vat: formatCurrency(quote.generalPaymentDetails.vat),
      total: formatCurrency(quote.generalPaymentDetails.totalPayment),
    };
  }, [quote]);

  return (
    <VStack w="full" spacing={0} gap={{ base: 5, lg: '30px' }}>
      <HStack w="full" gap={6} pl="0px" color="blackAlpha.800" justify="end">
        <VStack align="flex-end" w="124px" spacing="1">
          <Text fontSize="sm">{t('event.quote_summary.sum')}</Text>
          <Text fontSize="sm">
            {t('event.quote_summary.vat', {
              percentage: businessPreferences?.vatPercent,
            })}
          </Text>
        </VStack>
        <VStack align="start" spacing="1" w="144px" fontWeight="bold">
          <Text fontSize="sm">{formattedSum.sum}</Text>
          <Text fontSize="sm">{formattedSum.vat}</Text>
        </VStack>
      </HStack>

      <HStack
        w="full"
        px="10"
        py="5"
        bg="#93C3B3"
        rounded="3xl"
        boxShadow="14px 17px 40px 4px #7090B014"
        justify="center"
        spacing="6">
        <HStack spacing="3" fontWeight="bold" color="#00000099">
          <Image
            w="6"
            h="6"
            alt="Total icon"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6291f44bdf29fc6db64ecdf4a72ac530c1ff2d30d1ea9f209565d52ba66da95c"
          />
          <Text noOfLines={1}>{t('event.quote_summary.total')}</Text>
        </HStack>
        <Box
          px="2"
          py="4"
          bg="whiteAlpha.600"
          noOfLines={1}
          wordBreak="break-all"
          flex={1}
          rounded="lg"
          minH="42px"
          w="124px"
          textAlign="center"
          fontWeight="medium"
          color="#465D55">
          {formattedSum.total}
        </Box>
      </HStack>
    </VStack>
  );
};
