import { AppDispatch } from 'contexts/redux/store';
import {
  getWeather,
  useWeatherData,
} from 'contexts/redux/weather/weatherSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export default function useWeather() {
  const dispatch = useDispatch<AppDispatch>();
  const weatherData = useWeatherData();

  useEffect(() => {
    navigator?.geolocation?.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;

      if (!weatherData) {
        dispatch(getWeather({ lat: latitude, lon: longitude }));
      }
    });
  }, [dispatch, weatherData]);

  return { weatherData };
}
