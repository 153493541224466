import { Flex, HStack, Icon, IconButton, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { IInvoice } from 'services/@types';
import Product from './Product';

interface IProductsProps {
  products: IInvoice['products'];
  handleSelectedProduct: (e: any, index: number) => void;
  currencyIcon: string;
  handleChangeProductVatIncluded: (e: any, index: number) => void;
  error: {
    products: boolean;
    totalPayment: boolean;
  };
  handleChangeProductDescription: (e: any, index: number) => void;
  handleChangeProductAmount: (e: any, index: number) => void;
  handleChangeProductPrice: (e: any, index: number) => void;
  handleRemoveProduct: (index: number) => void;
  handleAddProduct: () => void;
  updateProductByCurrencyChange: (
    productData: any,
    productIndex: number,
  ) => void;
}

export default function Products(props: IProductsProps) {
  const { t } = useTranslation();

  const {
    products,
    handleSelectedProduct,
    currencyIcon,
    handleChangeProductVatIncluded,
    handleChangeProductDescription,
    error,
    handleChangeProductAmount,
    handleChangeProductPrice,
    handleRemoveProduct,
    handleAddProduct,
    updateProductByCurrencyChange,
  } = props;
  return (
    <Flex flexDirection="column" gap="12px">
      <Text variant="lineUpFormDialogFileName">
        {t('create_invoice.products')}
      </Text>
      <Flex flexDirection="column" gap="12px">
        {products?.map((product, index) => (
          <Product
            product={product}
            index={index}
            handleSelectedProduct={handleSelectedProduct}
            currencyIcon={currencyIcon}
            key={index}
            error={error}
            handleChangeProductVatIncluded={handleChangeProductVatIncluded}
            handleChangeProductDescription={handleChangeProductDescription}
            handleChangeProductAmount={handleChangeProductAmount}
            handleChangeProductPrice={handleChangeProductPrice}
            handleRemoveProduct={handleRemoveProduct}
            updateProductByCurrencyChange={updateProductByCurrencyChange}
          />
        ))}
      </Flex>
      <HStack>
        <IconButton
          borderRadius="10px"
          icon={<Icon as={FiPlus} color="white" w="30px" h="30px" />}
          onClick={handleAddProduct}
          aria-label="add"
          w="33px"
          h="33px"
          minW="33px"
          bg="#93C3B3"
        />
        <Text variant="addRowText">{t('create_invoice.add_row')}</Text>
      </HStack>
    </Flex>
  );
}
