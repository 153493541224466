import { useMutation } from '@tanstack/react-query';
import { Supplier } from 'services/@types';
import UserActionsService from 'services/userActions.api';

export const useSupplierDetailsRequest = () => {
  return useMutation({
    mutationFn: ({
      supplierProps,
      token,
    }: {
      supplierProps: Partial<Supplier>;
      token: string;
    }) =>
      UserActionsService.createUserAction(
        'supplier-details-request',
        supplierProps,
        undefined,
        {
          token,
        },
      ),
  });
};
