import UploadService from 'services/upload.api';

/**
 * Opens a PDF file in a new tab.
 * @param url - The URL of the file to open.
 */
export const handleOpenPdf = async (url: string, displayName: string) => {
  try {
    console.log('handleOpenPdf');
    console.log('url: ', url);
    console.log('displayName: ', displayName);
    if (!displayName.endsWith('.pdf')) {
      throw new Error('File is not a PDF');
    }
    const response = await UploadService.getUpload(url);
    if (!response) {
      throw new Error('File not found');
    }

    const fileUrl = URL.createObjectURL(
      new Blob([response], { type: `application/pdf` }),
    );
    window.open(fileUrl);
  } catch (error) {
    console.error('Error opening PDF', error);
  }
};
