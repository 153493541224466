import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryResult,
  useMutation,
} from '@tanstack/react-query';
import { IQuote, PaginatedResults } from 'services/@types';
import _quoteService from 'services/quote.api';

// Query key factories
const getQuoteQueryKey = (quoteId: string): QueryKey => ['quote', quoteId];
const getQuotesQueryKey = (params?: Record<string, any>): QueryKey => [
  `quotes-${JSON.stringify(params)}`,
  
];

// Query options factories
const getQuoteQueryOptions = (quoteId: string) => ({
  queryKey: getQuoteQueryKey(quoteId),
  queryFn: () => _quoteService.getQuote(quoteId),
  staleTime: 5 * 60 * 1000, // 5 minutes
  gcTime: 15 * 60 * 1000, // 15 minutes
  retry: (failureCount: number, error: any) => {
    return failureCount < 3 && error.response?.status !== 404;
  },
});

const getQuotesQueryOptions = (params?: Record<string, any>) => ({
  queryKey: getQuotesQueryKey(params),
  queryFn: () => _quoteService.getQuotes(params),
  staleTime: 5 * 60 * 1000, // 5 minutes
  gcTime: 15 * 60 * 1000, // 15 minutes
});

// Hooks
interface UseGetQuoteOptions {
  enabled?: boolean;
}

export const useGetQuote = <T = IQuote>(
  quoteId: string,
  options: UseGetQuoteOptions = {},
): UseQueryResult<T, Error> => {
  return useQuery({
    ...getQuoteQueryOptions(quoteId),
    enabled: options.enabled !== false && !!quoteId,
  });
};

export const useGetQuotes = (params?: Record<string, any>) => {
  return useQuery<PaginatedResults<IQuote>>({
    ...getQuotesQueryOptions(params),
    enabled: true,
  });
};

// Mutation hooks
export const useUpdateQuote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, updates }: { id: string; updates: Partial<IQuote> }) =>
      _quoteService.updateQuote(id, updates),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getQuoteQueryKey(data.id) });
      queryClient.invalidateQueries({ queryKey: ['quotes'] });
    },
  });
};

export const useQuoteCreate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (quote: IQuote) => _quoteService.createQuote(quote),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getQuoteQueryKey(data.id) });
      queryClient.invalidateQueries({ queryKey: ['quotes'] });
    },
  });
};

export const useDeleteQuote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => _quoteService.deleteQuote(id),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: getQuoteQueryKey(id) });
      queryClient.invalidateQueries({ queryKey: ['quotes'] });
    },
  });
};

export const useSendQuote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, clientId }: { id: string; clientId: string }) =>
      _quoteService.sendQuote(id, clientId),
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: getQuoteQueryKey(id) });
    },
  });
};

// Helper methods
useGetQuote.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
  quoteId: string,
) => {
  await queryClient.prefetchQuery(getQuoteQueryOptions(quoteId));
};

useGetQuote.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  quoteId: string,
) => {
  queryClient.invalidateQueries({ queryKey: getQuoteQueryKey(quoteId) });
};

useGetQuotes.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
  params?: Record<string, any>,
) => {
  await queryClient.prefetchQuery(getQuotesQueryOptions(params));
};

useGetQuotes.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  params?: Record<string, any>,
) => {
  queryClient.invalidateQueries({ queryKey: getQuotesQueryKey(params) });
};
