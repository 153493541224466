import { Flex, Stack, Text } from '@chakra-ui/react';
import InputDateField from 'components/fields/InputDateField';
import InputField from 'components/fields/InputField';
import LocationSearchInput from 'components/placesAutocomplete';
import TimeSelector from 'components/timeSelector';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
interface CreateEventDetailsProps {
  handleChange: (key: string, value: any) => void;
  event: Record<string, any>;
  errorList: string[];
}
export default function CreateEventDetails(props: CreateEventDetailsProps) {
  const { handleChange, event, errorList } = props;
  const { t } = useTranslation();

  const dateAndTime = useMemo(
    () => event?.dateAndTime as Date,
    [event?.dateAndTime],
  );

  const dateAndTimeEnd = useMemo(
    () => event?.dateAndTimeEnd as Date,
    [event?.dateAndTimeEnd],
  );

  const memoEndTime = useMemo(() => {
    return event?.dateAndTimeEnd
      ?.toLocaleTimeString('en-US', { hourCycle: 'h24' })
      .slice(0, 5);
  }, [event?.dateAndTimeEnd]);

  const memoStartTime = useMemo(() => {
    return event?.dateAndTime
      ?.toLocaleTimeString('en-US', { hourCycle: 'h24' })
      .slice(0, 5);
  }, [event?.dateAndTime]);

  const handleChangeDateAndTime = useCallback(
    (name: string, value: string) => {
      console.log('handleChangeDateAndTime ->', 'name', name, 'value', value);
      let newDateAndTime = dateAndTime as Date;
      if (!newDateAndTime) {
        const newDate = new Date();
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
        newDateAndTime = newDate;
      }
      if (name === 'date') {
        newDateAndTime.setFullYear(new Date(value).getFullYear());
        newDateAndTime.setMonth(new Date(value).getMonth());
        newDateAndTime.setDate(new Date(value).getDate());
      }
      if (name === 'time') {
        newDateAndTime.setHours(Number(value.split(':')[0]));
        newDateAndTime.setMinutes(Number(value.split(':')[1]));
      }
      console.log('newDateAndTime ->', newDateAndTime);
      handleChange('dateAndTime', new Date(newDateAndTime));
    },
    [dateAndTime, handleChange],
  );

  const handleChangeDateAndTimeEnd = useCallback(
    (name: string, value: string) => {
      let newDateAndTime = dateAndTimeEnd as Date;
      if (!newDateAndTime) {
        const newDate = new Date();
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
        newDateAndTime = newDate;
      }
      if (name === 'date') {
        newDateAndTime.setFullYear(new Date(value).getFullYear());
        newDateAndTime.setMonth(new Date(value).getMonth());
        newDateAndTime.setDate(new Date(value).getDate());
      }
      if (name === 'time') {
        newDateAndTime.setHours(Number(value.split(':')[0]));
        newDateAndTime.setMinutes(Number(value.split(':')[1]));
      }
      handleChange('dateAndTimeEnd', new Date(newDateAndTime));
    },
    [handleChange, dateAndTimeEnd],
  );

  const errorLabelNumberOfGuestsChildren = useMemo(() => {
    return errorList.includes('numberOfGuestsChildren')
      ? t('create_event.number_of_guests_error')
      : '';
  }, [errorList, t]);

  const errorLabelNumberOfGuests = useMemo(() => {
    return errorList.includes('numberOfGuests')
      ? t('create_event.number_of_guests_error')
      : '';
  }, [errorList, t]);

  const getInputGuestsBorderColor = useCallback(
    (key: string) => {
      return errorList.includes(key) ? 'error.100' : 'gray.300';
    },
    [errorList],
  );

  return (
    <Stack gap={3} spacing={0}>
      <Text
        fontSize={'base'}
        lineHeight={'28px'}
        fontWeight={700}
        color="black">
        {t('create_event.event_details')}
      </Text>
      <Flex wrap="wrap" gap={3} alignItems="end">
        <LocationSearchInput
          label={t('create_event.placeholder.location')}
          placeholder={t('create_event.placeholder.location')}
          onChange={(e) => {
            console.log('location search input ->', e);
            handleChange('location', { label: e, value: e });
          }}
          value={event?.location?.label}
          onSelect={(e) => {
            console.log('location search input ->', e);
            handleChange('location', e);
          }}
          inputProps={
            errorList.includes('location')
              ? {
                  _empty: {
                    borderColor: 'error.100',
                  },
                }
              : {}
          }
        />

        <Flex gap={1} w={{ base: '100%', lg: '318px' }} alignItems="end">
          <InputDateField
            label={t('create_event.label.event_date')}
            value={dateAndTime}
            onChange={(e: Date) => {
              handleChangeDateAndTime('date', e.toString());
            }}
            justifyContent="end"
            w="100%"
            buttonStyles={{
              w: { base: '220px', md: 'fit-content' },
              maxW: { base: '220px', md: 'fit-content' },
            }}
            isGrey
          />
          <TimeSelector
            label={t('create_event.label.time')}
            value={memoStartTime}
            flex="1"
            onChange={(e: string) => {
              handleChangeDateAndTime('time', e);
            }}
          />
        </Flex>

        <Flex gap={1} w={{ base: '100%', lg: '318px' }} alignItems="end">
          <InputDateField
            label={t('create_event.label.event_date_end')}
            value={dateAndTimeEnd}
            placeholder={t('create_event.placeholder.event_date_end')}
            onChange={(e: Date) => {
              handleChangeDateAndTimeEnd('date', e.toString());
            }}
            justifyContent="end"
            w="100%"
            buttonStyles={{
              w: { base: '220px', md: 'fit-content' },
              maxW: { base: '220px', md: 'fit-content' },
            }}
            isGrey
          />
          <TimeSelector
            label={t('create_event.label.end_time')}
            value={memoEndTime}
            flex="1"
            onChange={(e: string) => {
              handleChangeDateAndTimeEnd('time', e);
            }}
          />
        </Flex>

        <InputField
          label={t('create_event.label.number_of_guests')}
          placeholder={t('create_event.placeholder.number_of_guests')}
          w={{ base: '100%', lg: '174px' }}
          flex="1"
          type="number"
          name="numberOfGuests"
          onChange={(e: any) => handleChange(e.target.name, e.target.value)}
          value={event?.numberOfGuests}
          zIndex="1"
          iconStyle={{ justifyContent: 'start' }}
          errorLabel={errorLabelNumberOfGuests}
          borderColor={getInputGuestsBorderColor('numberOfGuests')}
        />
        <InputField
          label={t('create_event.label.number_of_guests_children')}
          placeholder={t('create_event.placeholder.number_of_guests')}
          w={{ base: '100%', lg: '174px' }}
          flex="1"
          type="number"
          name="numberOfGuestsChildren"
          onChange={(e: any) => handleChange(e.target.name, e.target.value)}
          value={event?.numberOfGuestsChildren}
          zIndex="1"
          errorLabel={errorLabelNumberOfGuestsChildren}
          borderColor={getInputGuestsBorderColor('numberOfGuestsChildren')}
          iconStyle={{ justifyContent: 'start' }}
        />
      </Flex>
    </Stack>
  );
}
