import { IClientContact } from 'services/@types';
import { IClient } from 'services/@types';
import { IEvent } from 'services/@types';
import { Supplier } from 'services/@types';

export interface IPaymentDialogState {
  event: IEvent | null;
  clients: { client: IClient; contacts: IClientContact[] }[];
  data: Date;
  description: string;
  supplier: Supplier | null;
  amount: string;
  vat: string;
  totalVat: string;
  gross: string;
  discount: string;
  totalAmount: string;
  isVatIncluded: boolean;
  amountToPay: string;
  typeOfTransaction: string;
}

export const initialPayment: IPaymentDialogState = {
  event: null,
  // quote: null,
  clients: [],
  data: new Date(),
  description: '',
  amount: '',
  vat: '',
  totalVat: '',
  gross: '',
  discount: '',
  totalAmount: '',
  supplier: null,
  isVatIncluded: false,
  amountToPay: '',
  typeOfTransaction: '',
};

export const incomeOptions = ['client_income', 'other_income', 'supplier_fee'];
export const expenseOptions = [
  'payment_to_supplier',
  'event_expense',
  'petty_cash',
];
