import {
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import QuoteDeadlineIcon from 'assets/svg/notification/quote-deadline-icon.svg';
import moment from 'moment';
import 'moment/locale/he'; // Add Hebrew locale support
import { useGetEntity } from 'queries/entity';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RiDoubleQuotesR } from 'react-icons/ri';
import { INotification, IQuote } from 'services/@types';
import { AppNotificationEventName } from 'services/@types/notificationEventNames';
import { getNotificationMomentFormat } from './NotificationComponent';
import QuoteNotification from './quote/QuoteNotification';
import SupplierDetailsRequestIcon from 'assets/svg/notification/supplier-details-request-icon.svg';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'contexts/redux/store';
import { setExternalSupplierDialog } from 'contexts/redux/dialog/dialogsSlice';
import { fetchNotifications, updateNotification } from 'contexts/redux/notifications/notificationsSlice';

export default function PlainNotification({
  notification,
  notificationRef,
}: {
  notification: INotification;
  notificationRef: React.RefObject<HTMLDivElement>;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const { t, i18n } = useTranslation();

  // Set moment locale based on current language
  moment.locale(i18n.language.startsWith('he') ? 'he' : 'en');

  const { data: entity } = useGetEntity(
    notification?.type,
    notification?.entityId,
  );

  const icon = useMemo(() => {
    if (notification?.type === 'quote') {
      if (
        notification?.notificationEventName ===
        AppNotificationEventName.QuoteDeadlineReminder
      ) {
        return QuoteDeadlineIcon;
      }
      return null;
    }
    if (
      notification?.notificationEventName ===
      AppNotificationEventName.SupplierDetailsRequest
    ) {
      return SupplierDetailsRequestIcon;
    }
    return null;
  }, [notification?.notificationEventName, notification?.type]);

  const titleMessage = useMemo(() => {
    if (
      notification?.notificationEventName ===
      AppNotificationEventName.SupplierDetailsRequest
    ) {
      return t(notification.title, {
        ...notification?.variables,
        ...{
          name: notification?.variables?.name,
        },
      });
    }
    return '';
  }, [
    notification?.notificationEventName,
    notification.title,
    notification?.variables,
    t,
  ]);

  const message = useMemo(() => {
    return t(notification.message, {
      ...notification?.variables,
      ...{
        date: moment(notification?.variables?.date).format('D MMM, YYYY'),
      },
    });
  }, [notification.message, notification?.variables, t]);

  const button = useMemo(() => {
    if (
      notification?.notificationEventName ===
      AppNotificationEventName?.SupplierDetailsRequest
    ) {
      return {
        text: t('notifications.supplier_details_request.view_add'),
        handler: () => {
          dispatch(
            setExternalSupplierDialog({
              item: {
                supplierId: notification?.variables?.supplierId,
              },
              onConfirm: async () => {
                await dispatch(
                  updateNotification({
                    notificationId: notification?.id,
                    updates: {
                      visible: false,
                    },
                  }),
                );
                dispatch(fetchNotifications({ limit: 1000 }));
              },
            }),
          );
        },
      };
    }
    return null;
  }, [notification, t, dispatch]);

  return notification.type === 'quote' &&
    notification.notificationEventName !==
      AppNotificationEventName.QuoteDeadlineReminder ? (
    <QuoteNotification
      ref={notificationRef}
      createdAt={moment(notification?.createdAt).format(
        getNotificationMomentFormat(i18n.language),
      )}
      quote={entity as IQuote}
    />
  ) : (
    <Flex
      ref={notificationRef}
      border="1px solid #F9FAFB"
      borderRadius="12px"
      p="8px"
      flexDirection="column">
      <VStack
        border="1px solid #F9FAFB"
        p="8px"
        borderRadius={'8px'}
        bgColor={'#93C3B31A'}
        spacing={0}>
        <HStack alignItems={'center'} gap="12px">
          {icon ? (
            <Image src={icon} alt="" w="24px" h="24px" />
          ) : (
            <Icon as={RiDoubleQuotesR} color="brand.900" />
          )}
          <VStack spacing={0} alignItems={'flex-start'} gap={3}>
            <VStack spacing={0}>
              {titleMessage && (
                <>
                  <Text
                    variant="cardSubTitle"
                    color="#1B2037"
                    lineHeight={'20px'}>
                    {titleMessage}
                  </Text>
                  <br />
                </>
              )}
              <Text variant="cardSubTitle" color="#1B2037" lineHeight={'20px'}>
                {message}
              </Text>
            </VStack>
            {button && (
              <Button variant="h1cta" onClick={button.handler} w="full">
                {button.text}
              </Button>
            )}
          </VStack>
        </HStack>
        <Text fontSize="md" color="gray.500" alignSelf="flex-end">
          {moment(notification?.createdAt).format(
            getNotificationMomentFormat(i18n.language),
          )}
        </Text>
      </VStack>
    </Flex>
  );
}
