import { Button, Flex, Icon, Image, Text, VStack } from '@chakra-ui/react';
import NoEventsImg from 'assets/svg/event/not-found.svg';
import Card from 'components/card/Card';
import DropDownField from 'components/fields/DropdownField';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { IMeeting } from 'services/@types';
import TaskCard from '../../dashboard/components/TaskCard';
import MeetingCard from './components/MeetingCard';
import useTaskHistory from './hooks/useTaskHistory';
const NoTask = () => {
  return <Image w="96px" h="96px" src={NoEventsImg} alt="" />;
};

export default function TaskHistory() {
  const { t } = useTranslation();
  const { appNavigate: navigate } = useAppNavigate();
  const {
    isAll,
    eventData,
    date,
    eventPlans,
    selectedEvent,
    onSelectedEvent,
    activeTasks,
    completedTasks,
    todayMeetings,
    eventId,
  } = useTaskHistory();
  return (
    <Flex
      w="100%"
      pt={{ base: '200px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '0' }}
      flexDirection="column"
      gap={4}>
      <Card
        gap={5}
        h="calc(100vh - 170px)"
        overflowY="auto"
        borderRadius={{ base: '0px', md: '30px' }}>
        <Flex
          justifyContent="space-between"
          gap={{ base: 4, md: 0 }}
          align="start"
          flexDirection={{ base: 'column', md: 'row' }}
          position="relative">
          <VStack align="flex-start">
            {!isAll && (
              <>
                <Text variant="taskHistoryTitle">{eventData?.name}</Text>
                <Text variant="taskHistoryType">{eventData?.type}</Text>
                <Text variant="taskHistoryDate">{date}</Text>
              </>
            )}
            {isAll && (
              <Text variant="taskHistoryTitle">
                {t('tasks.history.general_tasks')}
              </Text>
            )}
          </VStack>
          <Flex
            alignItems="center"
            w={{ base: 'full', md: 'auto' }}
            justifyContent={{ base: 'flex-end', md: 'start' }}
            gap="12px">
            {isAll && (
              <DropDownField
                options={eventPlans?.map((plan) => ({
                  id: plan.eventId?.id,
                  label: plan.eventId?.name,
                }))}
                placeholder={t('tasks.history.filter_by_event')}
                w={{ base: 'full', md: '331px' }}
                closeOnSelect
                selectedOptions={selectedEvent?.id ? [selectedEvent?.id] : []}
                showedOptions={selectedEvent?.name}
                onSelected={onSelectedEvent}
              />
            )}
            <Icon
              as={FiX}
              w="16px"
              h="16px"
              position={{ base: 'absolute', md: 'static' }}
              top="4px"
              right="0px"
              cursor="pointer"
              onClick={() => navigate(-1 as any)}
            />
          </Flex>
        </Flex>
        <VStack align="flex-start">
          <Text variant="eventCardTitle">{t('tasks.history.active')}</Text>
        </VStack>
        {todayMeetings.data?.results.map((meeting: IMeeting) => (
          <MeetingCard key={meeting.id} meeting={meeting} />
        ))}
        {activeTasks?.map((task) => (
          <TaskCard key={task.id} task={task} />
        ))}
        {activeTasks?.length === 0 &&
          todayMeetings.data?.results.length === 0 && (
            <VStack gap={3}>
              <NoTask />
              <Text variant="noCompletedTasksSubTitle">
                {t('tasks.history.no_active_tasks')}
              </Text>
              <Button
                onClick={() =>
                  navigate(`/main/tasks/create?eventId=${eventId}`)
                }
                variant="h1cta">
                {t('tasks.create_new_task')}
              </Button>
            </VStack>
          )}
        <VStack align="flex-start">
          <Text variant="eventCardTitle">{t('tasks.history.completed')}</Text>
          {completedTasks?.map((task) => (
            <TaskCard key={task.id} task={task} isCompleted />
          ))}
        </VStack>
        {completedTasks?.length === 0 && (
          <VStack>
            <NoTask />
            <Text variant="noCompletedTasksTitle">
              {t('tasks.history.no_completed_tasks')}
            </Text>
            <Text variant="noCompletedTasksSubTitle">
              {t('tasks.history.no_completed_tasks_description')}
            </Text>
          </VStack>
        )}
      </Card>
    </Flex>
  );
}
