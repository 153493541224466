import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import ClientCard from 'components/contactsPickerDialog/components/ClientCard';
import {
  AppDialogProps,
  closeOfferClientToSupplierDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useGetEntity } from 'queries/entity';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { IClient } from 'services/@types';

const OfferClientToSupplierDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { offerClientToSupplierDialog } = useSelector((state: RootState) => ({
    offerClientToSupplierDialog: state.dialogs.offerClientToSupplierDialog,
  }));
  const dialog = offerClientToSupplierDialog as AppDialogProps;
  const handleClose = useCallback(() => {
    dispatch(closeOfferClientToSupplierDialog());
  }, [dispatch]);

  const { data: client } = useGetEntity<IClient>(
    'client',
    dialog?.item?.clientId,
  );

  const handleConfirm = useCallback(async () => {
    if (dialog?.onConfirm) {
      dialog?.onConfirm(client);
    }
    handleClose();
  }, [dialog, handleClose, client]);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px" overflow="hidden">
        <ModalBody p="24px" pt={0}>
          <Flex gap={9} flexDir="column" align="stretch">
            <HStack justify="space-between" align="center">
              <Text
                fontSize="base"
                fontWeight={600}
                color="#000000"
                lineHeight="20px">
                {t('dialogs.offer_client_to_supplier.title', {
                  clientName:
                    client?.company ||
                    `${client?.firstName} ${client?.lastName}`,
                })}
              </Text>
              <IconButton
                icon={<Icon as={MdClose} color="#939393" w="24px" h="24px" />}
                aria-label="close"
                variant="ghost"
                onClick={handleClose}
              />
            </HStack>
            {client && <ClientCard client={client} hideCheckbox />}
            <Text variant="body1">
              {t('dialogs.offer_client_to_supplier.note')}
            </Text>
            <Flex
              justify="flex-end"
              align="center"
              gap={{ base: '8px', md: '24px' }}>
              <Button
                minW="157px"
                variant="h1cta"
                fontSize={{ base: '12px !important', md: '14px !important' }}
                lineHeight={{ base: '12px !important', md: '16px !important' }}
                height="44px !important"
                onClick={() => handleConfirm()}>
                {t('dialogs.offer_client_to_supplier.buttons.update_contact')}
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OfferClientToSupplierDialog;
