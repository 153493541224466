const KELVIN_TO_CELSIUS_OFFSET = 273.15;
/**
 * Converts a temperature from Kelvin to Celsius.
 * @param kelvin - The temperature in Kelvin.
 * @returns The temperature in Celsius.
 */
export const kelvinToCelsius = (kelvin: number): number => {
  if (kelvin < 0) {
    throw new Error('Temperature in Kelvin cannot be negative.');
  }
  return kelvin - KELVIN_TO_CELSIUS_OFFSET;
};
