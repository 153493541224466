import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import DropdownField from 'components/fields/DropdownField';
import { closeInvoiceTypeDialog } from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch } from 'contexts/redux/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaX } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useDocumentType from '../hooks/useDocumentType';

export default function SelectTypeModal(props: any) {
  const { t } = useTranslation();
  const { invoiceTypeDialog } = useSelector((state: any) => state.dialogs);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    options,
    showedDocumentType,
    selectedDocumentType,
    onSelectedDocumentType,
    documentType,
    handleClearDocumentType,
  } = useDocumentType();

  const handleCreateDocument = useCallback(() => {
    dispatch(closeInvoiceTypeDialog());
    handleClearDocumentType();
    navigate(`/main/finance/invoices/create?documentType=${documentType}`);
  }, [dispatch, handleClearDocumentType, navigate, documentType]);

  const handleCloseModal = useCallback(() => {
    dispatch(closeInvoiceTypeDialog());
    handleClearDocumentType();
  }, [dispatch, handleClearDocumentType]);

  return (
    <Modal
      isOpen={!!invoiceTypeDialog}
      onClose={handleCloseModal}
      isCentered
      size="xl"
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent p="24px" borderRadius="30px" gap="36px" alignItems="center">
        <Flex alignItems="center" justify="space-between" w="100%">
          <Text variant="cardTitle">
            {t('invoices_overview.create_new_document')}
          </Text>
          <Icon
            as={FaX}
            onClick={handleCloseModal}
            cursor="pointer"
            color="#939393"
            w="20px"
            h="20px"
          />
        </Flex>
        <DropdownField
          w="320px"
          options={options}
          placeholder={t('invoicesAndReceipts.choose_document_type')}
          onSelected={onSelectedDocumentType}
          showedOptions={showedDocumentType}
          selectedOptions={selectedDocumentType}
          closeOnSelect
        />
        <Button
          variant="h1cta"
          w="320px"
          disabled={!documentType}
          onClick={handleCreateDocument}>
          {t('invoicesAndReceipts.create')}
        </Button>
      </ModalContent>
    </Modal>
  );
}
