import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';

export const documentTypes = [
  'tax_invoice',
  'receipt',
  'tax_invoice_receipt',
  'tax_invoice_credit',
  'pro_forma_invoice',
];

export default function useDocumentType() {
  const { t } = useTranslation();
  const [documentType, setDocumentType] = useState<string>('');

  const options = useMemo(
    () =>
      documentTypes.map((type) => ({
        label: t(`invoicesAndReceipts.document_type.${type}`),
        id: type,
      })),
    [t],
  );

  const showedDocumentType = useMemo(() => {
    return documentType
      ? t(`invoicesAndReceipts.document_type.${documentType}`)
      : '';
  }, [documentType, t]);

  const selectedDocumentType = useMemo(() => {
    return documentType ? [documentType] : [];
  }, [documentType]);

  const onSelectedDocumentType = useCallback((typeData: { id: string }) => {
    setDocumentType((prev) => (prev === typeData.id ? '' : typeData.id));
  }, []);

  const handleClearDocumentType = useCallback(() => {
    setDocumentType('');
  }, []);

  return {
    options,
    documentType,
    showedDocumentType,
    selectedDocumentType,
    onSelectedDocumentType,
    handleClearDocumentType,
  };
}
