import { formatNumber } from 'helpers/formatNumber';
import { IInvoiceProductListItem, Product } from 'services/@types';
import { EXCHANGE_RATE_FROM_ILS_TO_USD } from 'variables/currency';

// add selected product to products
export const addSelectedProduct = ({
  products,
  selectedProduct,
  index,
  vatValue,
}: {
  products: IInvoiceProductListItem[];
  selectedProduct: Product;
  index: number;
  vatValue: number;
}) => {
  return products.map((p, key) =>
    key === index
      ? {
          ...p,
          description: selectedProduct.name,
          productId: selectedProduct.id,
          price: selectedProduct.price,
          totalPrice: formatNumber(
            selectedProduct.price * vatValue + selectedProduct.price,
          ),
          isVatIncluded: true,
          amount: 1,
        }
      : p,
  );
};

// get updated product with vat included or not
export const getUpdatedProduct = (
  product: IInvoiceProductListItem,
  isVatIncluded: boolean,
  vatValue: number,
) => {
  if (isVatIncluded) {
    return {
      ...product,
      isVatIncluded,
      totalPrice: formatNumber(product.price * vatValue + product.price),
    };
  }

  return {
    ...product,
    isVatIncluded,
    totalPrice: product.price,
  };
};

// calculate products vat included or not by index
export const calculateProductsVatByIndex = (
  products: IInvoiceProductListItem[],
  index: number,
  isVatIncluded: boolean,
  vatValue: number,
) => {
  return products.map((p, key) =>
    key === index ? getUpdatedProduct(p, isVatIncluded, vatValue) : p,
  );
};

// calculate products vat included or not
export const calculateProductsVat = (
  products: IInvoiceProductListItem[],
  isVatIncluded: boolean,
  vatValue: number,
) => {
  return products.map((p) => getUpdatedProduct(p, isVatIncluded, vatValue));
};

// get updated product by amount
export const getUpdatedProductByAmount = (
  product: IInvoiceProductListItem,
  amount: number,
  vatValue: number,
) => {
  return {
    ...product,
    amount,
    totalPrice: product.isVatIncluded
      ? formatNumber(
          formatNumber(product.price * amount) +
            formatNumber(product.price * amount) * vatValue,
        )
      : formatNumber(product.price * amount),
  };
};

// get updated product by quantity
export const getUpdatedProductByPrice = (
  product: IInvoiceProductListItem,
  price: number,
  vatValue: number,
) => {
  return {
    ...product,
    price,
    totalPrice: product.isVatIncluded
      ? formatNumber(
          formatNumber(product.amount * price) +
            formatNumber(product.amount * price) * vatValue,
        )
      : formatNumber(product.amount * price),
  };
};

// get updated product by usd
export const getUpdatedProductByUsd = (
  product: IInvoiceProductListItem,
  price: number,
  vatValue: number,
) => {
  return {
    ...product,
    price: formatNumber(price * EXCHANGE_RATE_FROM_ILS_TO_USD),
    totalPrice: product?.isVatIncluded
      ? formatNumber(
          formatNumber(product.amount * price * EXCHANGE_RATE_FROM_ILS_TO_USD) +
            formatNumber(
              product.amount * price * EXCHANGE_RATE_FROM_ILS_TO_USD,
            ) *
              vatValue,
        )
      : formatNumber(product.amount * price * EXCHANGE_RATE_FROM_ILS_TO_USD),
  };
};

// get updated product by ils
export const getUpdatedProductByIls = (
  product: IInvoiceProductListItem,
  price: number,
  vatValue: number,
) => {
  return {
    ...product,
    price,
    totalPrice: product?.isVatIncluded
      ? formatNumber(
          formatNumber(product.amount * price) +
            formatNumber(product.amount * price) * vatValue,
        )
      : formatNumber(product.amount * price),
  };
};
