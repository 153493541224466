import { Divider, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { enGB, he } from 'date-fns/locale';
import EventService from 'services/event.api';

import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoLocationOutline, IoMailOpenOutline } from 'react-icons/io5';
import { Thread } from 'services/@types';
import ThreadListItem from './ThreadListItem';
interface ThreadListItemComponentProps {
  currentThread: Thread;
  onOpen: () => void;
}
export const ThreadListItemComponent: React.FC<
  ThreadListItemComponentProps
> = ({ currentThread, onOpen }) => {
  const { i18n } = useTranslation();

  const { t } = useTranslation();
  const eventResult = useQuery({
    queryKey: [`getEvent-${currentThread?.eventId}`],
    queryFn: () => EventService.getEvent(currentThread?.eventId),
    enabled: !!currentThread?.eventId,
  });

  const eventData = useMemo(() => eventResult?.data, [eventResult?.data]);

  const date = useMemo(
    () =>
      eventData?.dateAndTime
        ? format(new Date(eventData.dateAndTime), 'MMM dd', {
            locale: i18n.language.startsWith('he') ? he : enGB,
          })
        : '',
    [eventData?.dateAndTime, i18n.language],
  );

  return (
    <VStack gap={0} w="full">
      {/* // bg={chat.id === currentSupplier?.id ? '#D9D9D9' : 'transparent'}> */}
      <ThreadListItem
        thread={currentThread}
        onClick={() => {
          onOpen();
        }}
      />
      {eventData && (
        <HStack
          borderRadius={'8px'}
          border="1px solid #5FBF96"
          px={2}
          spacing={0}
          gap={1}
          py={1}
          justifyContent="space-evenly"
          w="full">
          <Text
            color="brand.900"
            fontSize="xs"
            noOfLines={1}
            wordBreak="break-all">
            {date}
          </Text>
          <Flex w="1px" h="16px" bg="#00000099" />
          <Text
            fontSize="xs"
            color="gray.500"
            fontWeight="bold"
            lineHeight="1.5"
            flex={1}
            maxW="100px"
            wordBreak={'break-all'}
            noOfLines={1}>
            {eventData.name}
          </Text>
          <Flex w="1px" h="16px" bg={'#00000099'} />
          <HStack spacing={1}>
            <Icon as={IoMailOpenOutline} color="general.100" />
            <Text
              wordBreak={'break-all'}
              noOfLines={1}
              color="#00000099"
              fontSize="xs">
              {t('messenger.invited', {
                numberOfGuests: eventData.numberOfGuests,
              })}
            </Text>
          </HStack>
          <Flex w="1px" h="16px" bg="#00000099" />
          <HStack spacing={1}>
            <Icon as={IoLocationOutline} color="general.100" />
            <Text wordBreak={'break-all'} noOfLines={1} fontSize="xs">
              {eventData.location?.label}
            </Text>
          </HStack>
        </HStack>
      )}
      <Divider
        sx={{
          mt: '0px !important',
          my: '10px !important',
          w: '90%',
          borderColor: 'gray.300',
        }}
      />
    </VStack>
  );
};
