import { useQuery } from '@tanstack/react-query';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useEffect, useMemo, useState } from 'react';
import { IQuote, IQuoteProductListItem, Product } from 'services/@types';
import _productService from 'services/product.api';
import QuoteService from 'services/quote.api';

const useGetLatestQuote = (eventId: string) => {
  const { data: businessPreferences } = useGetBusinessPreferences();
  const [areProductsLoading, setAreProductsLoading] = useState(true);
  const [products, setProducts] = useState<
    (IQuoteProductListItem & { populatedProduct: Product })[]
  >([]);
  const { data: quote, isLoading } = useQuery<IQuote | null>({
    queryKey: [`quoteInEventPage-${eventId}`],
    queryFn: async () => {
      const response = await QuoteService.getQuotes({
        eventId: eventId,
        limit: 1,
        sortBy: 'createdAt:desc',
        status: 'accepted',
      });
      if (response.results.length > 0) {
        const quoteData = response.results[0];
        return quoteData;
      }
      return null;
    },
    retry: false,
    enabled: !!eventId,
  });

  useEffect(() => {
    if (quote) {
      const retrievePackages = async () => {
        try {
          setAreProductsLoading((prev) => {
            if (!prev) return true;
            return prev;
          });

          const rawProducts = await Promise.all(
            quote.products.map(async (product) => {
              const populatedProduct =
                await _productService.getProduct<Product>(product.productId, {
                  populate: 'subItems',
                });

              return {
                ...product,
                populatedProduct,
              };
            }),
          );
          setProducts(rawProducts);
        } catch (err) {
          console.log('error inside retreivePackages', err);
        } finally {
          setAreProductsLoading(false);
        }
      };
      retrievePackages();
    } else {
      setAreProductsLoading(false);
    }
  }, [quote]);

  const loading = useMemo(() => {
    console.log('isLoading', isLoading);
    console.log('areProductsLoading', areProductsLoading);
    return isLoading || areProductsLoading;
  }, [isLoading, areProductsLoading]);

  // Calculate summary based on products
  const summary = useMemo(() => {
    if (products.length === 0 || !quote)
      return { sum: '0', vat: '0', total: '0' };
    const sum = quote.products.reduce(
      (acc: number, product: IQuoteProductListItem) => acc + product.net,
      0,
    );
    console.log('sum', sum);
    console.log(
      'businessPreferences?.vatPercent',
      businessPreferences?.vatPercent,
    );
    const vat = sum * businessPreferences?.vatPercent; // Example VAT calculation
    console.log('vat', vat);
    const total = sum + vat;
    return {
      sum: `${sum} ${quote.currency}`,
      vat: `${vat} ${quote.currency}`,
      total: `${total} ${quote.currency}`,
    };
  }, [products, quote, businessPreferences?.vatPercent]);

  return { isLoading: loading, quote, products, summary };
};

export default useGetLatestQuote;
