import { useToast } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  closeAlertDialog,
  openAlertDialog,
  setLineUpFormDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { deleteMediaItem } from 'contexts/redux/mediaItem/mediaItemSlice';
import { AppDispatch } from 'contexts/redux/store';
import { handleOpenPdf } from 'helpers/handleOpenPdf';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MediaItem } from 'services/@types';
import AlbumService from 'services/album.api';
import MediaItemService from 'services/mediaItem.api';
import UploadService from 'services/upload.api';

export default function useLineUp() {
  const params = useParams();
  const client = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const { t } = useTranslation();
  const myBusiness = useMyBusiness();
  const { data } = useQuery({
    queryKey: ['lineUp', params?.id],
    queryFn: async () =>
      await AlbumService.getAlbums({
        name: 'lineup',
        target: 'event',
        targetId: params?.id, // eventId,
      }),
    enabled: !!params?.id,
  });

  const lineUpData = useMemo(() => {
    return data?.results?.length === 1 ? data?.results[0] : null;
  }, [data?.results]);

  const { data: mediaData } = useQuery({
    queryKey: ['lineUp-media', lineUpData?.id],
    queryFn: async () =>
      await MediaItemService.getMediaItems({
        albumID: lineUpData?.id,
        // projectBy: '-updatedAt',
        sortBy: '-createdAt',
        businessID: myBusiness?.id,
        limit: 10000, // === no limit
      }),
    enabled: !!lineUpData?.id && !!myBusiness?.id,
  });

  const handleOpenLineUpFormDialog = useCallback(() => {
    dispatch(
      setLineUpFormDialog({
        item: {
          lineUpId: lineUpData?.id,
        },
      }),
    );
  }, [dispatch, lineUpData]);

  const handleDeleteLineUp = useCallback(
    async (mediaId: string) => {
      await dispatch(deleteMediaItem({ mediaItemId: mediaId }));
      await client.invalidateQueries({
        queryKey: ['lineUp-media', lineUpData?.id],
      });
      toast({
        title: t('event.line_up_delete_success'),
        variant: 'main',
        position: 'top-right',
      });
      dispatch(closeAlertDialog());
    },
    [dispatch, client, lineUpData?.id, toast, t],
  );

  const handleDeleteMedia = useCallback(
    (mediaId: string) => {
      dispatch(
        openAlertDialog({
          title: t('event.line_up_delete_title'),
          content: t('event.line_up_delete_description'),
          onConfirm: async () => {
            await handleDeleteLineUp(mediaId);
          },
        }),
      );
    },
    [handleDeleteLineUp, dispatch, t],
  );

  const handleDownloadMedia = useCallback(async (media: MediaItem) => {
    try {
      if (media.media[0].displayName.endsWith('.pdf')) {
        handleOpenPdf(media.media[0].url, media.media[0].displayName);
        return;
      }
      console.log('media: ', media);
      const response = await UploadService.getUpload(
        media.media[0].url,
        'original',
      );
      console.log('response', response);
      const imageData = URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = imageData;
      a.download = media.media[0].displayName;
      a.click();
    } catch (error) {
      console.error('handleDownloadMedia error: ', error);
    }
  }, []);

  useEffect(() => {
    if (data?.results?.length === 0) {
      const createLineUp = async () => {
        try {
          const response = await AlbumService.createAlbum({
            name: 'lineup',
            target: 'event',
            targetId: params?.id, // eventId,
          });
          if (response) {
            client.invalidateQueries({ queryKey: ['lineUp', params?.id] });
          }
        } catch (error) {
          console.log('createLineUp error: ', error);
        }
      };
      createLineUp();
    }
  }, [data?.results, params?.id, client]);

  return {
    handleOpenLineUpFormDialog,
    mediaData,
    handleDeleteMedia,
    handleDownloadMedia,
  };
}
