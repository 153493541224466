import {
  Flex,
  Hide,
  HStack,
  Icon,
  Image,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessEventPlan, EventStatus, IEvent } from 'services/@types';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import anchorMeetingsIcon from 'assets/svg/event/top_bar/anchor_meeting.svg';
import clientFileIcon from 'assets/svg/event/top_bar/client_file.svg';
import contractsIcon from 'assets/svg/event/top_bar/contracts.svg';
import detailsIcon from 'assets/svg/event/top_bar/details.svg';
import eventBriefIcon from 'assets/svg/event/top_bar/event_brief.svg';
import financeIcon from 'assets/svg/event/top_bar/finance.svg';
import incomeIcon from 'assets/svg/event/top_bar/income.svg';
import invitedIcon from 'assets/svg/event/top_bar/invited-icon.svg';
import lineUpIcon from 'assets/svg/event/top_bar/line_up.svg';
import mainIcon from 'assets/svg/event/top_bar/main.svg';
import meetingsIcon from 'assets/svg/event/top_bar/meetings.svg';
import messagesIcon from 'assets/svg/event/top_bar/messages.svg';
import myContractIcon from 'assets/svg/event/top_bar/my_contract.svg';
import outcomeIcon from 'assets/svg/event/top_bar/outcome.svg';
import participantsIcon from 'assets/svg/event/top_bar/participants.svg';
import quoteIcon from 'assets/svg/event/top_bar/quote.svg';
import supplierAssignmentIcon from 'assets/svg/event/top_bar/supplier_assignment.svg';
import tasksIcon from 'assets/svg/event/top_bar/tasks.svg';
import trackerIcon from 'assets/svg/event/top_bar/tracker.svg';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { setCurrentEventTab } from 'contexts/redux/event/eventSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { highlightComponent } from 'contexts/redux/uiHelper/uiHelperSlice';
import { format } from 'date-fns';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { FiMapPin } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import _businessEventPlanService from 'services/businessEventPlan.api';
import StatusDropdown from 'views/main/managment/events/eventsOverview/components/StatusDropdown';

interface EventPageTopNavigationBarProps {
  event?: IEvent;
  currentTab?: string;
}
export const NavigationTabs = {
  main: {
    icon: mainIcon,
    // tabs: ['tasks', 'tracker', 'messages'],
    tabs: [
      {
        name: 'tasks',
        icon: tasksIcon,
      },
      {
        name: 'tracker',
        icon: trackerIcon,
      },
      {
        name: 'messages',
        icon: messagesIcon,
      },
    ],
  },
  client_file: {
    icon: clientFileIcon,
    tabs: [
      {
        name: 'details',
        icon: detailsIcon,
      },
      {
        name: 'event_brief',
        icon: eventBriefIcon,
      },
      {
        name: 'quote',
        icon: quoteIcon,
      },
      {
        name: 'client_documents',
        icon: myContractIcon,
      },
      {
        name: 'anchor_meetings',
        icon: anchorMeetingsIcon,
      },
      {
        name: 'line_up',
        icon: lineUpIcon,
      },
    ],
  },
  finance: {
    icon: financeIcon,
    tabs: [
      {
        name: 'income',
        icon: incomeIcon,
      },
      {
        name: 'outcome',
        icon: outcomeIcon,
      },
    ],
  },
  supplier_assignment: {
    icon: supplierAssignmentIcon,
    tabs: [
      {
        name: 'event_quotes',
        icon: quoteIcon,
      },
      {
        name: 'event_suppliers',
        icon: participantsIcon,
      },
      {
        name: 'event_contracts',
        icon: contractsIcon,
      },
    ],
  },
};

export const EventPageTopNavigationBar: React.FC<
  EventPageTopNavigationBarProps
> = ({ event }) => {
  const { t } = useTranslation();
  const myUser = useMyUser();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { appNavigate: navigate } = useAppNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const currentTab = useSelector(
    (state: RootState) => state.event.currentEventTab,
  );
  const myBusiness = useMyBusiness();
  const businessId = myBusiness?.id;

  const { data: businessEventPlan } = useQuery<BusinessEventPlan | null>({
    queryKey: [`getEventParticipants-${event?.id}-${businessId}`],
    queryFn: async () => {
      const result = await _businessEventPlanService.getBusinessEventPlans({
        eventId: event.id,
        businessId,
        populate: 'businessId',
      });

      const data = result.results[0];
      return data;
    },
    enabled: !!event?.id && !!businessId,
  });

  const handleStatusChange = useCallback(
    async (newStatus: EventStatus) => {
      console.log('newStatus', newStatus);
      const res = await _businessEventPlanService.updateBusinessEventPlan(
        businessEventPlan?.id,
        {
          status: newStatus,
        },
      );
      if (res) {
        queryClient.invalidateQueries({
          queryKey: [`getEventParticipants-${event?.id}-${businessId}`],
        });
        queryClient.invalidateQueries({
          queryKey: ['dashboardTrackedEvents'],
        });
        toast({
          title:
            newStatus === 'completed'
              ? t('event.status_change.toast_success_completed')
              : t('event.status_change.toast_success'),
          variant: 'main',
          status: 'success',
        });
      }
    },
    [businessEventPlan?.id, businessId, event?.id, queryClient, t, toast],
  );

  const handleContainerClick = useCallback(
    (e: React.MouseEvent) => {
      // Prevent click if the click originated from the status dropdown container
      if (e.target instanceof Element && e.target.closest('.status-dropdown')) {
        return;
      }

      if (event?.curator === myUser?.id) {
        navigate(`/main/events/update/${event?.id}`);
      }
    },
    [event?.curator, event?.id, myUser?.id, navigate],
  );

  return (
    <Card
      h="fit-content"
      w="100%"
      borderRadius={{
        base: 'none',
        md: '2xl',
      }}>
      <HStack
        w="100%"
        align="start"
        gap={6}
        flexDirection={{ base: 'column', md: 'row' }}
        spacing={0}>
        <VStack
          spacing={0}
          cursor="pointer"
          onClick={handleContainerClick}
          w="100%"
          maxW={{ base: '100%', md: '180px' }}
          minW={{ base: '100%', md: '180px' }}
          align={'start'}>
          <HStack w="100%" gap={{ base: 2, md: 0 }}>
            <Flex
              className="status-dropdown"
              w="100%"
              onClick={(e) => e.stopPropagation()}>
              <StatusDropdown
                event={event}
                status={businessEventPlan?.status as EventStatus}
                filteredStatuses={
                  ['new', 'active', 'post', 'completed'] as EventStatus[]
                }
                onStatusChange={handleStatusChange}
              />
            </Flex>
            <Flex
              display={{ base: 'flex', md: 'none' }}
              w="100%"
              justifyContent={'flex-end'}>
              <Text fontSize="sm" fontWeight={'500'}>
                {event?.dateAndTime
                  ? format(
                      new Date(event?.dateAndTime),
                      'MMM dd, yyyy  hh:mm a',
                    )
                  : ''}
              </Text>
            </Flex>
          </HStack>
          <Text
            fontSize="xl"
            fontWeight={'700'}
            w="100%"
            wordBreak={'break-word'}
            noOfLines={2}>
            {event?.name || "Samantha's Birthday"}
          </Text>
          <Text fontSize="sm" color="brand.900" fontWeight={'700'}>
            {t(`event_types.${event?.type}`) || 'Private'}
          </Text>
          <Hide below="md">
            <Text fontSize="sm" fontWeight={'500'}>
              {event?.dateAndTime
                ? format(new Date(event?.dateAndTime), 'MMM dd, yyyy  hh:mm a')
                : ''}
            </Text>
          </Hide>
          <VStack spacing={2} opacity={0.6} align="start" pt={2}>
            <HStack spacing={1} align="start">
              <Image src={invitedIcon} />
              <Text fontSize="xs" fontWeight={'400'}>
                {t('event.top_bar_tabs.invited_adults_and_children', {
                  count: event?.numberOfGuests || 100,
                  childrenCount: event?.numberOfGuestsChildren || 0,
                })}
              </Text>
            </HStack>
            <HStack spacing={1} align="start">
              <Icon as={FiMapPin} />
              <Text fontSize="xs" fontWeight={'400'}>
                {event?.location?.label || 'Manhattan, NY'}
              </Text>
            </HStack>
          </VStack>
        </VStack>
        <VStack flex={1} gap={{ base: 0, md: 5 }} w="100%">
          <HStack
            w="100%"
            gap={6}
            overflow="scroll"
            pt={{ base: 2, md: 0 }}
            pb={2}>
            {Object.keys(NavigationTabs).map((key: any) => {
              const tab = NavigationTabs[key as keyof typeof NavigationTabs];
              return (
                <VStack
                  flex={1}
                  minW={{ base: '100px', md: undefined }}
                  borderRadius={'2xl'}
                  bg={currentTab === key ? 'brand.900' : 'transparent'}
                  cursor={'pointer'}
                  onClick={(e: any) => {
                    e?.target?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'nearest',
                    });
                    dispatch(setCurrentEventTab(key));
                  }}
                  //   shadow={'lg'}
                  boxShadow={currentTab !== key ? 'md' : 'none'}
                  //   boxShadow="0px 4px 4px 5px rgba(0, 0, 0, 0.15)"
                  key={key}
                  p={'11px'}
                  transition={'all 0.3s'}
                  _hover={{ bg: 'brad.900' }}
                  _focus={{ bg: 'brand.900' }}
                  _active={{ bg: 'brand.800' }}
                  spacing={1}
                  align="center">
                  {
                    <Image
                      filter={
                        currentTab === key ? 'brightness(0) invert(1)' : 'none'
                      }
                      color={currentTab === key ? 'white' : 'gray.700'}
                      src={tab.icon}
                    />
                  }
                  <Text
                    fontSize={'sm'}
                    color={currentTab === key ? 'white' : 'gray.700'}
                    textTransform="capitalize"
                    noOfLines={2}>
                    {/* {key} */}
                    {t(`event.top_bar_tabs.${key}`)}
                  </Text>
                </VStack>
              );
            })}
          </HStack>
          <HStack
            w="100%"
            justifyContent={'space-between'}
            spacing={{ base: 0.5, md: 2 }}>
            {NavigationTabs[
              currentTab as keyof typeof NavigationTabs
            ]?.tabs.map((tab: any, index) => (
              <VStack
                p={3}
                key={tab.name}
                borderRadius={{ base: 'lg', md: '2xl' }}
                bg="white"
                boxShadow={'none'}
                cursor={'pointer'}
                _hover={{ bg: 'gray.300' }}
                _active={{ bg: 'gray.500' }}
                transition={'all 0.3s'}
                flex={1}
                h="100%"
                justify="center"
                onClick={() => dispatch(highlightComponent(tab.name as string))}
                fontSize={{ base: 'xs', md: 'sm' }}
                spacing={1}>
                <Image src={tab.icon} />
                <Text key={tab.name} noOfLines={1} wordBreak="break-all">
                  {t(`event.top_bar_tabs.sub_tabs.${tab.name}`)}
                </Text>
              </VStack>
            ))}
          </HStack>
        </VStack>
      </HStack>
    </Card>
  );
};
