import {
  Icon,
  IconButton,
  IconButtonProps,
  MenuButton,
  MenuButtonProps,
} from '@chakra-ui/react';
import { FiMoreHorizontal } from 'react-icons/fi';

interface ThreeDotsBtnProps extends MenuButtonProps, Partial<IconButtonProps> {
  onClick: () => void;
  ref?: any;
  iconColor?: string;
}

export default function ThreeDotsBtn(props: ThreeDotsBtnProps) {
  const { onClick, ref, iconColor, ...rest } = props;
  return (
    <MenuButton
      as={IconButton}
      variant="actionIconBlue"
      icon={
        <Icon
          as={FiMoreHorizontal}
          w="20px"
          h="20px"
          color={iconColor || 'white'}
        />
      }
      onClick={onClick}
      ref={ref}
      {...rest}
    />
  );
}
