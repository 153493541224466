import {
  Divider,
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import FileSignedIcon from 'assets/svg/contracts/fileSignedIcon.svg';
import FileUnSignedIcon from 'assets/svg/contracts/fileUnSignedIcon.svg';
import { useTranslation } from 'react-i18next';
import { FiUpload } from 'react-icons/fi';
import { LuTrash2 } from 'react-icons/lu';
import { MdAdd, MdOutlineMoreHoriz } from 'react-icons/md';
import useContracts from '../hooks/useContracts';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
export default function Contracts() {
  const {
    handleOpenAddEventContractDialog,
    mediaData,
    handleDeleteMedia,
    handleDownloadMedia,
  } = useContracts();
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('event_contracts');
  return (
    <Card
      flex="1"
      borderRadius={{ base: 0, md: '30px' }}
      style={{ gridColumn: 'span 2' }}
      maxHeight="535px"
      h="100%"
      gap="20px"
      minH="535px"
      {...style}
      ref={ref}>
      <Flex alignItems="center" justify="space-between">
        <Text variant="eventPageCardTitle">{t('event.contracts.title')}</Text>
        {/* <Menu>
          <MenuButton>
            <Icon as={MdOutlineMoreHoriz} color="#93C3B3" w="20px" h="20px" />
          </MenuButton>
          <MenuList
            w="176px"
            h="72px"
            borderRadius="24px"
            p="0"
            overflow="hidden">
            <MenuItem
              gap="13px"
              justifyContent="center"
              alignItems="center"
              onClick={handleOpenAddEventContractDialog}
              h="100%">
              <Icon as={FiUpload} color="#FF554A" w="25px" h="25px" />
              <Text variant="lineUpItemDelete">
                {t('event.contracts.upload_contract')}
              </Text>
            </MenuItem>
          </MenuList>
        </Menu> */}
        <IconButton
          margin={'0 !important'}
          borderRadius="8px"
          maxWidth="24px"
          height="24px"
          minWidth="24px"
          p="0 !important"
          order={{ base: 1, lg: 0 }}
          aria-label="add-event"
          variant={'actionIconBlue'}
          icon={<Icon as={MdAdd} fontSize="16px" />}
          onClick={handleOpenAddEventContractDialog}
        />
      </Flex>
      <Flex
        flexDirection="column"
        display={mediaData?.results?.length > 0 ? 'flex' : 'none'}
        gap="10px"
        overflow="auto">
        {mediaData?.results?.map((media, index) => (
          <Flex flexDirection="column" gap="10px" key={media.id}>
            <Flex
              flex="1"
              h="38px"
              alignItems="center"
              justifyContent="space-between">
              <Flex
                flexDirection="column"
                cursor="pointer"
                onClick={() => handleDownloadMedia(media)}>
                <Text variant="lineUpItemFileName" noOfLines={1} maxW="200px">
                  {media?.media?.[0]?.displayName}
                </Text>
                <Text variant="previewQuoteProductHeader" textTransform="none">
                  {media?.name}
                </Text>
              </Flex>
              <Flex alignItems="center" gap="10px">
                {media?.signed && (
                  <Image src={FileSignedIcon} alt="fileSignedIcon" />
                )}
                {!media?.signed && (
                  <Image src={FileUnSignedIcon} alt="fileUnSignedIcon" />
                )}
                <Menu>
                  <MenuButton
                    as={IconButton}
                    variant="ghost"
                    icon={
                      <Icon
                        as={MdOutlineMoreHoriz}
                        color="#93C3B3"
                        w="20px"
                        h="20px"
                      />
                    }
                  />

                  <MenuList
                    w="129px"
                    h="69px"
                    minH="69px"
                    minW="129px"
                    borderRadius="24px"
                    p="0"
                    overflow="hidden">
                    <MenuItem
                      gap="13px"
                      justifyContent="center"
                      alignItems="center"
                      onClick={() => handleDeleteMedia(media.id)}
                      h="100%">
                      <Icon as={LuTrash2} color="#FF554A" w="25px" h="25px" />
                      <Text variant="lineUpItemDelete">
                        {t('event.contracts.delete')}
                      </Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
            <Divider
              bg="#E1E1E1"
              display={
                index === mediaData?.results?.length - 1 ? 'none' : 'block'
              }
            />
          </Flex>
        ))}
      </Flex>
      {mediaData?.results?.length === 0 && (
        <Flex justify="center" align="center" h="100%">
          <Text color="gray.500">{t('event.contracts.no_contracts')}</Text>
        </Flex>
      )}
    </Card>
  );
}
