import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { PaginatedResults, Supplier } from 'services/@types';
import supplierService from 'services/supplier.api';
import { useGetQuote } from './quote';

// Query key factories
const getSupplierQueryKey = (supplierId: string): QueryKey => [
  'supplier',
  supplierId,
];
const getSuppliersQueryKey = (params?: Record<string, any>): QueryKey => [
  'suppliers',
  params,
];

// Query options factories
const getSupplierQueryOptions = (supplierId: string) => ({
  queryKey: getSupplierQueryKey(supplierId),
  queryFn: () => supplierService.getSupplier(supplierId),
  staleTime: 5 * 60 * 1000, // 5 minutes
  gcTime: 15 * 60 * 1000, // 15 minutes
  retry: (failureCount: number, error: any) => {
    return failureCount < 3 && error.response?.status !== 404;
  },
});

const getSuppliersQueryOptions = (params?: Record<string, any>) => ({
  queryKey: getSuppliersQueryKey(params),
  queryFn: () => supplierService.getSuppliers(params),
  staleTime: 5 * 60 * 1000, // 5 minutes
  gcTime: 15 * 60 * 1000, // 15 minutes
});

// Hooks
interface UseGetSupplierOptions {
  enabled?: boolean;
}

export const useGetSupplier = <T = Supplier>(
  supplierId: string,
  options: UseGetSupplierOptions = {},
): UseQueryResult<T, Error> => {
  return useQuery({
    ...getSupplierQueryOptions(supplierId),
    enabled: options.enabled !== false && !!supplierId,
  });
};

export const useGetSuppliers = (params?: Record<string, any>) => {
  return useQuery<PaginatedResults<Supplier>>({
    ...getSuppliersQueryOptions(params),
    enabled: true,
  });
};

// Mutation hooks
export const useUpdateSupplier = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, updates }: { id: string; updates: Partial<Supplier> }) =>
      supplierService.updateSupplier(id, updates),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: getSupplierQueryKey(data.id) });
      queryClient.invalidateQueries({ queryKey: ['suppliers'] });
    },
  });
};

export const useDeleteSupplier = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => supplierService.deleteSupplier(id),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: getSupplierQueryKey(id) });
      queryClient.invalidateQueries({ queryKey: ['suppliers'] });
    },
  });
};

export const useApproveSupplier = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (supplierId: string) =>
      supplierService.updateSupplier(supplierId, {
        isPendingApproval: true,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['suppliers'] });
    },
  });
};

export const useDeclineSupplier = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (supplierId: string) =>
      supplierService.updateSupplier(supplierId, {
        isPendingApproval: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['suppliers'] });
    },
  });
};

// Helper methods
useGetQuote.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
  supplierId: string,
) => {
  await queryClient.prefetchQuery(getSupplierQueryOptions(supplierId));
};

useGetSupplier.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  supplierId: string,
) => {
  queryClient.invalidateQueries({ queryKey: getSupplierQueryKey(supplierId) });
};

useGetSupplier.prefetch = async (
  queryClient: ReturnType<typeof useQueryClient>,
  supplierId: string,
) => {
  await queryClient.prefetchQuery(getSupplierQueryOptions(supplierId));
};

useGetSupplier.invalidate = (
  queryClient: ReturnType<typeof useQueryClient>,
  supplierId: string,
) => {
  queryClient.invalidateQueries({ queryKey: getSupplierQueryKey(supplierId) });
};
