import {
  deleteClient,
  setEditClient,
  useSelectedClient,
} from 'contexts/redux/client/clientSlice';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IClient } from 'services/@types';

export default function useManageSelectedClient() {
  const { client } = useSelectedClient();
  const dispatch = useDispatch<any>();
  const { appNavigate: navigate } = useAppNavigate();

  const handleRemoveClient = useCallback(() => {
    if (!client) return;
    dispatch(deleteClient(client?.id));
  }, [client, dispatch]);

  const handleEditClient = useCallback(() => {
    if (!client) return;
    const clientData: IClient = {
      id: client.id,
      firstName: client.firstName,
      lastName: client.lastName,
      company: client.company,
      phone: client.phone,
      email: client.email,
      address: client.address,
      idNumber: client.idNumber,
      type: client.type,
      contacts: client.contacts,
    };
    dispatch(setEditClient(clientData));
  }, [client, dispatch]);

  const handleGoToEventsHistory = useCallback(() => {
    navigate(`/main/managment/clients/${client.id}/events-history`);
  }, [client?.id, navigate]);

  const handleDuplicateClient = useCallback(() => {}, []);

  return {
    handleRemoveClient,
    handleEditClient,
    handleGoToEventsHistory,
    handleDuplicateClient,
  };
}
