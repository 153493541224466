import { Flex } from '@chakra-ui/react';
import FinanceCard from '../financeOverview/components/FinanceCard';
import useOutcomes from './hooks/useOutcomes';

export default function Outcomes() {
  const {
    statsExpense,
    expenses,
    expensesFilter,
    setExpensesFilter,
    handleSortExpenses,
  } = useOutcomes();
  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '0' }}>
      <FinanceCard
        title={'finance_overview.outcome_tracking'}
        stats={statsExpense}
        payments={expenses}
        filter={expensesFilter}
        setFilter={setExpensesFilter}
        isSearchEnabled
        handleSort={handleSortExpenses}
      />
    </Flex>
  );
}
