import { Flex, Icon, Image, Text } from '@chakra-ui/react';
import CompanyIcon from 'assets/svg/client/companyIcon.svg';
import NoteIcon from 'assets/svg/client/noteIcon.svg';
import PhoneIcon from 'assets/svg/client/phoneIcon.svg';
import VatIcon from 'assets/svg/client/vatIcon.svg';
import { IoIosLink } from 'react-icons/io';
import { IoLocationOutline } from 'react-icons/io5';
import { PiGlobeLight } from 'react-icons/pi';
import { IClient } from 'services/@types';

export default function ClientInfo({ client }: { client: IClient }) {
  return (
    <>
      <Flex
        alignItems="center"
        gap="12px"
        display={client?.company ? 'flex' : 'none'}>
        <Image src={CompanyIcon} w="24px" h="24px" />
        <Text variant="clientDetailInfo">{client?.company}</Text>
      </Flex>
      <Flex
        alignItems="center"
        gap="12px"
        display={client?.phone ? 'flex' : 'none'}>
        <Image src={PhoneIcon} w="24px" h="24px" />
        <Text variant="clientDetailUnderline">{client?.phone}</Text>
      </Flex>
      <Flex
        alignItems="center"
        gap="12px"
        display={client?.address ? 'flex' : 'none'}>
        <Icon as={IoLocationOutline} w="24px" h="24px" color="#939393" />
        <Text variant="clientDetailInfo">{client?.address}</Text>
      </Flex>
      <Flex
        alignItems="center"
        gap="12px"
        display={client?.idNumber ? 'flex' : 'none'}>
        <Image src={VatIcon} w="24px" h="24px" />
        <Text variant="clientDetailInfo">{client?.idNumber}</Text>
      </Flex>
    </>
  );
}
