import { useToast } from '@chakra-ui/react';
import { async } from '@firebase/util';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  setContactsPickerDialog,
  setEventsDialog,
  setQuoteDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useInvoiceMutations } from 'hooks/useInvoice';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  IClient,
  IClientContact,
  IEvent,
  IInvoice,
  IQuote,
  Upload,
  UrlItem,
} from 'services/@types';
import invoiceService from 'services/invoice.api';
import UploadService from 'services/upload.api';
import { currency } from 'variables/currency';
import {
  addSelectedProduct,
  calculateProductsVat,
  calculateProductsVatByIndex,
  getUpdatedProductByAmount,
  getUpdatedProductByIls,
  getUpdatedProductByPrice,
  getUpdatedProductByUsd,
} from '../helpers/calculations';
import eventService from 'services/event.api';

export default function useInvoiceForm() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const myUser = useMyUser();
  const { products } = useSelector((state: RootState) => ({
    products: state.product.products,
    currentDocumentNumber: state.currentDocumentNumber.currentDocumentNumber,
  }));
  const [invoice, setInvoice] = useState<Partial<IInvoice>>({
    currency: 'ILS',
    products: [
      {
        description: '',
        price: 0,
        totalPrice: 0,
        amount: 1,
        isVatIncluded: false,
      },
    ],
  });

  const [isAllProductsVatIncluded, setIsAllProductsVatIncluded] =
    useState<boolean>(false);
  const [event, setEvent] = useState<IEvent | null>(null);
  const [error, setError] = useState<{
    clients: boolean;
    products: boolean;
    totalPayment: boolean;
  }>({
    clients: false,
    products: false,
    totalPayment: false,
  });
  const [searchParams] = useSearchParams();
  const { data: businessPreferences } = useGetBusinessPreferences();

  const documentType = useMemo(
    () =>
      (searchParams.get('documentType') as IInvoice['type']) || invoice?.type,
    [searchParams, invoice?.type],
  );

  const currencyIcon = useMemo(() => {
    const selected = currency.find((c) => c.value === invoice?.currency);
    return selected ? selected.symbol : '';
  }, [invoice?.currency]);

  const clientsList = useMemo(
    () => [
      ...(invoice?.clients
        ?.filter((c) => c.client?.type === 'private')
        ?.map((c) => c.client) ?? []),
      ...(invoice?.clients
        ?.filter((c) => c.client?.type === 'business')
        ?.flatMap(
          (c: { contacts: IClientContact[] | string[] }) =>
            c.contacts as IClientContact[],
        ) ?? []),
    ],
    [invoice?.clients],
  );

  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: IEvent) => {
          setEvent(e);
          setInvoice((prev) => ({
            ...prev,
            eventID: e?.id,
            clients: e?.clients,
          }));
        },
      }),
    );
  }, [dispatch]);

  const handleRemoveEvent = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation();
      setEvent(null);
      setInvoice((prev) => ({
        ...prev,
        eventID: null,
      }));
    },
    [],
  );

  const handleOpenQuoteDialog = useCallback(() => {
    dispatch(
      setQuoteDialog({
        onConfirm: async (quote: IQuote) => {
          setInvoice((prev) => {
            const newInvoice = { ...prev };
            newInvoice.quoteID = quote.id;

            // set clients
            if (quote?.clients?.length > 0) {
              newInvoice.clients = quote.clients;
            }

            // set event
            if (quote?.eventId) {
              newInvoice.eventID = quote.eventId;
            }

            // set products
            if (quote?.products?.length > 0) {
              newInvoice.products = quote.products.map((p) => ({
                productId: p.productId,
                description: p.description,
                isVatIncluded: p.isVatIncluded,
                price: p.amount,
                totalPrice: p.gross,
                amount: p.quantity,
              }));
            }

            // set currency
            if (quote?.currency) {
              newInvoice.currency = quote.currency;
            }

            // legal text
            if (quote?.legalText) {
              newInvoice.legalText = quote.legalText;
            }

            // description
            if (quote?.description) {
              newInvoice.description = quote.description;
            }

            // set comment
            if (quote?.comment) {
              newInvoice.comments = quote.comment;
            }

            return newInvoice;
          });

          if (quote.eventId) {
            const eventData = await eventService.getEvent(quote.eventId);
            if (eventData) {
              setEvent(eventData as IEvent);
            }
          }
        },
      }),
    );
  }, [dispatch]);

  const handleOpenClientDialog = useCallback(() => {
    dispatch(
      setContactsPickerDialog({
        item: {
          chosenClients: invoice?.clients || [],
          type: 'clients',
        },
        onConfirm: (
          selection: { client: IClient; contacts: IClientContact[] }[],
        ) => {
          setInvoice((prevstate) => {
            return {
              ...prevstate,
              clients: selection,
            };
          });
        },
      }),
    );
  }, [dispatch, invoice?.clients, invoice?.id]);

  const setClient = useCallback(
    (client: { client: IClient; contacts: IClientContact[] }) => {
      setInvoice((prev) => ({
        ...prev,
        clients: [...(prev?.clients || []), client],
      }));
    },
    [],
  );

  const removeChosenClient = useCallback((clientId, contactId) => {
    if (contactId) {
      setInvoice((prev) => {
        const contactClient = prev?.clients?.find((ic) =>
          ic.contacts?.some((c) => (c as IClientContact).id === contactId),
        );
        const newClients =
          contactClient.contacts.length === 1
            ? prev?.clients?.filter(
                (ic) => ic.client.id !== contactClient?.client.id,
              )
            : prev?.clients?.map((ic) => {
                if (ic.client.id === contactClient?.client.id) {
                  return {
                    ...ic,
                    contacts: (ic.contacts as IClientContact[]).filter(
                      (c) => c.id !== contactId,
                    ),
                  };
                }
                return ic;
              });
        return {
          ...prev,
          clients: newClients,
        };
      });
    } else {
      setInvoice((prev) => ({
        ...prev,
        clients: prev?.clients?.filter((ic) => ic.client.id !== clientId),
      }));
    }
  }, []);

  const handleChangeInvoice = useCallback((key: string, value: any) => {
    setInvoice((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleChangeDetailsOfReceipts = useCallback(
    (key: string, value: any) => {
      setInvoice((prev) => ({
        ...prev,
        receipientDetails: { ...prev.receipientDetails, [key]: value },
      }));
    },
    [],
  );

  const handleUploadCompleted = useCallback((file: UrlItem) => {
    console.log('handleUploadCompleted file', file);
    setInvoice((prev) => ({
      ...prev,
      media: [
        ...(prev.media || []),
        {
          url: file.url,
          type: file.type,
          displayName: file.displayName,
        },
      ],
    }));
  }, []);

  const handleChangeFile = useCallback(
    async (
      event: any,
      type: 'invoiceCommentAttachment' | 'invoiceAttachment',
    ) => {
      event.stopPropagation();
      const file = event?.target?.files[0];

      if (!file) return;

      try {
        const newImg: Upload = await UploadService.uploadFile(
          'user',
          myUser?.id,
          'private',
          file,
        );

        if (newImg) {
          setInvoice((prev) => ({
            ...prev,
            media: [
              ...(prev.media || []),
              {
                url: newImg.filePath,
                type: type,
                displayName: newImg.fileName,
              },
            ],
          }));
        }
      } catch (error) {
        console.error('use upload file error ->', error);
      }
    },
    [myUser?.id],
  );

  const removeFile = useCallback(
    async (type: 'invoiceCommentAttachment' | 'invoiceAttachment') => {
      try {
        const file = invoice?.media?.find((m) => m.type === type);
        if (file) {
          const [target, targetId, access, fileId] = file.url.split('/');
          await UploadService.deleteUpload({
            target,
            targetId,
            access,
            fileId,
          });
        } else {
          console.error('file not found');
          return;
        }
      } catch (error) {
        console.error('use clear file error ->', error);
        return;
      }

      setInvoice((prev) => ({
        ...prev,
        media: prev?.media?.filter((m) => m.type !== type),
      }));
    },
    [invoice?.media],
  );

  const handleSelectedProduct = useCallback(
    (e: any, index: number) => {
      const selectedProduct = products.find((p) => p.id === e);
      if (!selectedProduct) return;
      setInvoice((prev) => ({
        ...prev,
        products: addSelectedProduct({
          vatValue: businessPreferences?.vatValue,
          products: prev.products,
          selectedProduct,
          index,
        }),
      }));
    },
    [businessPreferences?.vatValue, products],
  );

  const handleChangeProductVatIncluded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      setInvoice((prev) => ({
        ...prev,
        products: calculateProductsVatByIndex(
          prev.products,
          index,
          e.target.checked,
          businessPreferences?.vatValue,
        ),
      }));
    },
    [businessPreferences?.vatValue],
  );

  const handleChangeAllProductsVatIncluded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsAllProductsVatIncluded(e.target.checked);
      setInvoice((prev) => {
        const products = calculateProductsVat(
          prev.products,
          e.target.checked,
          businessPreferences?.vatValue,
        );
        return {
          ...prev,
          products,
        };
      });
    },
    [businessPreferences?.vatValue],
  );

  const handleChangeProductAmount = useCallback(
    (e: any, index: number) => {
      setInvoice((prev: any) => ({
        ...prev,
        products: prev.products.map((p: any, key: number) =>
          key === index
            ? getUpdatedProductByAmount(
                p,
                e.target.value,
                businessPreferences?.vatValue,
              )
            : p,
        ),
      }));
    },
    [businessPreferences?.vatValue],
  );

  const handleChangeProductDescription = useCallback(
    (e: any, index: number) => {
      setInvoice((prev: any) => ({
        ...prev,
        products: prev.products.map((p: any, key: number) =>
          key === index ? { ...p, description: e.target.value } : p,
        ),
      }));
    },
    [],
  );

  const handleChangeProductPrice = useCallback((e: any, index: number) => {
    setInvoice((prev: any) => ({
      ...prev,
      products: prev.products.map((p: any, key: number) =>
        key === index
          ? getUpdatedProductByPrice(
              p,
              e.target.value,
              businessPreferences?.vatValue,
            )
          : p,
      ),
    }));
  }, []);

  const handleRemoveProduct = useCallback((index: number) => {
    setInvoice((prev) => ({
      ...prev,
      products: prev.products.filter((p, key) => key !== index),
    }));
  }, []);

  const handleAddProduct = useCallback(() => {
    setInvoice((prev) => ({
      ...prev,
      products: [
        ...(prev?.products || []),
        {
          description: '',
          amount: 1,
          price: 1,
          totalPrice: 1 + 1 * businessPreferences?.vatValue,
          isVatIncluded: true,
        },
      ],
    }));
  }, [businessPreferences?.vatValue]);

  const updateProductByCurrencyChange = useCallback(
    (productData, productIndex) => {
      setInvoice((prev) => {
        const products = prev.products;
        // update product by currency change
        let updatedProduct = products[productIndex];
        if (invoice?.currency === 'ILS') {
          updatedProduct = getUpdatedProductByIls(
            updatedProduct,
            productData?.price,
            businessPreferences?.vatValue,
          );
        }
        if (invoice?.currency === 'USD') {
          updatedProduct = getUpdatedProductByUsd(
            updatedProduct,
            productData?.price,
            businessPreferences?.vatValue,
          );
        }
        products[productIndex] = updatedProduct;
        return { ...prev, products: products };
      });
    },
    [invoice?.currency, businessPreferences?.vatValue],
  );

  // create invoice
  const { createInvoice, updateInvoice } = useInvoiceMutations();

  const saveAsDraft = useCallback(async () => {
    try {
      const newInvoice: Partial<IInvoice> = {
        ...invoice,
        type: documentType as IInvoice['type'],
        clients: invoice?.clients?.map((c) => ({
          ...c,
          client: c.client.id || '',
          contacts: c?.contacts?.map((contact) => contact?.id) || [],
        })),
      };
      let response;
      if (params?.invoiceId) {
        response = await updateInvoice.mutateAsync({
          invoiceId: params.invoiceId,
          updates: newInvoice as IInvoice,
        });
      } else {
        response = await createInvoice.mutateAsync(newInvoice);
      }

      if (response) {
        navigate('/main/finance/invoices/');
        toast({
          title: params?.invoiceId
            ? t('update_invoice.invoice_updated_successfully')
            : t('create_invoice.save_as_draft'),
          description: params?.invoiceId
            ? ''
            : t('create_invoice.invoice_saved_as_draft_description'),
          status: 'success',
          position: 'top-right',
          variant: 'main',
        });
      }
    } catch (error: any) {
      console.error('saveAsDraft error', error);
      toast({
        title: error?.response?.data?.message || error?.message,
        status: 'error',
        position: 'top-right',
      });
    }
  }, [invoice, navigate, toast, t, params?.invoiceId]);

  const sendInvoice = useCallback(async () => {
    // TODO: send invoice
  }, []);

  useEffect(() => {
    if (invoice?.products?.length > 0) {
      setIsAllProductsVatIncluded(
        invoice?.products?.every((p) => p?.isVatIncluded),
      );
    }
  }, [invoice?.products]);

  // get invoice if invoiceId is in the url
  useEffect(() => {
    if (params?.invoiceId) {
      const fetchInvoice = async () => {
        try {
          const invoiceData = await invoiceService.getInvoice(params.invoiceId);
          console.log('invoiceData', invoiceData);
          setInvoice({ ...invoiceData, eventID: invoiceData.eventID?.id });
          setEvent(invoiceData.eventID);
        } catch (error) {
          console.error('fetchInvoice error', error);
        }
      };
      fetchInvoice();
    }
  }, [params?.invoiceId]);

  return {
    documentType,
    handleOpenEventDialog,
    invoice,
    event,
    handleRemoveEvent,
    clientsList,
    handleOpenClientDialog,
    setClient,
    removeChosenClient,
    handleChangeInvoice,
    handleChangeFile,
    removeFile,
    handleChangeDetailsOfReceipts,
    handleSelectedProduct,
    currencyIcon,
    handleChangeProductVatIncluded,
    error,
    handleUploadCompleted,
    isAllProductsVatIncluded,
    handleChangeAllProductsVatIncluded,
    handleChangeProductDescription,
    handleChangeProductAmount,
    handleChangeProductPrice,
    handleRemoveProduct,
    handleAddProduct,
    updateProductByCurrencyChange,
    saveAsDraft,
    sendInvoice,
    handleOpenQuoteDialog,
  };
}
