import { Flex, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { enGB, he } from 'date-fns/locale';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { IClient, IClientContact, IQuote } from 'services/@types';

interface QuoteCardProps {
  quote: IQuote;
  onClick?: (quote: IQuote) => void;
  isSelected?: boolean;
  cursor?: string;
  hideEventName?: boolean;
  onClose?: () => void;
  [key: string]: any;
}
export default function QuoteCard(props: QuoteCardProps) {
  const { quote, onClick, cursor, hideEventName, onClose, ...rest } = props;
  const { i18n } = useTranslation();
  const { month, day } = useMemo(() => {
    const month = format(new Date(quote?.eventDate || 0), 'MMM', {
      locale: i18n.language.startsWith('he') ? he : enGB,
    });
    const day = format(new Date(quote?.eventDate || 0), 'dd');
    return { month, day };
  }, [quote.eventDate, i18n.language]);

  const { clientNames, eventName } = useMemo(() => {
    const clientNamesArr: string[] = [];
    quote.clients.forEach(
      (c: { client: IClient; contacts: string[] | IClientContact[] }) => {
        if (c.contacts && c.contacts.length > 0) {
          for (const contact of c.contacts) {
            clientNamesArr.push(
              `${(contact as IClientContact)?.firstName} ${
                (contact as IClientContact)?.lastName
              }`,
            );
          }
        } else {
          clientNamesArr.push(`${c?.client?.firstName} ${c?.client?.lastName}`);
        }
      },
    );
    const clientNames = clientNamesArr.join(', ');

    const eventName = quote.eventName;
    return { clientNames, eventName };
  }, [quote.clients, quote.eventName]);

  return (
    <HStack
      p={3}
      cursor={onClose ? 'default' : 'pointer'}
      overflow="hidden"
      borderRadius={'16px'}
      w="full"
      color={'#939393'}
      alignItems={'center'}
      gap={3}
      spacing={0}
      fontWeight={400}
      fontSize={'12px'}
      border={'1px solid'}
      lineHeight="16px"
      flexShrink={0}
      borderColor={'brand.900'}
      onClick={onClose ? () => {} : () => onClick?.(quote)}
      {...rest}>
      <Flex
        w="38px"
        justifyContent="center"
        alignItems="center"
        h="full"
        flexShrink={0}>
        <VStack
          textAlign="center"
          alignItems="center"
          spacing={0}
          justifyContent="center">
          <Text variant="quoteCardDate" fontSize="12px" lineHeight="24px">
            {month}
          </Text>
          <Text variant="quoteCardDate" fontSize={'24px'}>
            {day}
          </Text>
        </VStack>
      </Flex>
      <VStack
        alignItems="flex-start"
        flex={1}
        spacing={0}
        gap={2}
        overflow="hidden">
        <Flex w="full">
          <Text
            title={clientNames}
            color="#141718"
            fontSize="sm"
            lineHeight="18px"
            textTransform="capitalize"
            isTruncated
            textOverflow="ellipsis"
            overflow="hidden"
            w="full"
            flex={1}
            fontWeight={700}>
            {clientNames}
          </Text>
        </Flex>
        {!hideEventName && (
          <Text
            title={eventName}
            color="#939393"
            fontSize="12px"
            lineHeight="15px"
            fontWeight={400}
            letterSpacing="-2%">
            {eventName}
          </Text>
        )}
        <Text
          title={quote?.quoteNumber?.toString()}
          color="#939393"
          fontSize="12px"
          lineHeight="15px"
          fontWeight={400}
          letterSpacing="-2%">
          {quote.quoteNumber}
        </Text>
      </VStack>
      {onClose && (
        <IconButton
          aria-label="close"
          icon={<FiX fontSize={'24px'} />}
          onClick={onClose}
        />
      )}
    </HStack>
  );
}
