import {
  Button,
  ButtonProps,
  CircularProgress,
  Flex,
  FlexProps,
  FormLabel,
  FormLabelProps,
  forwardRef,
  HStack,
  Image,
  ImageProps,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import 'assets/css/InputDateField.css';
import CalendarIcon from 'assets/svg/date/calendar.svg';
import { getDate, getMonth, getYear } from 'date-fns';
import { he } from 'date-fns/locale/he';
import { useCallback } from 'react';
import DatePicker, {
  CalendarContainer,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
registerLocale('he', he);

export interface InputDateFieldProps {
  id?: string;
  label?: string;
  extra?: JSX.Element | string;
  errorLabel?: string;
  value?: Date;
  containerStyles?: FlexProps;
  mainContainerStyles?: FlexProps;
  buttonStyles?: ButtonProps;
  labelProps?: FormLabelProps;
  isGrey?: boolean;
  imgSrc?: string;
  imgStyles?: ImageProps;
  placeholder?: string;
  [x: string]: any;
}
export default function Default(props: InputDateFieldProps) {
  const {
    id,
    label,
    errorLabel,
    extra,
    value,
    isLoading,
    w,
    mb,
    type,
    labelProps,
    buttonStyles,
    containerStyles,
    isGrey = false,
    imgSrc,
    imgStyles,
    placeholder,
    mainContainerStyles,
    ...rest
  } = props;
  const textColorPrimary = useColorModeValue('primary.100', 'white');
  const { t, i18n } = useTranslation();

  const CustomInput = forwardRef(
    ({ value, onClick, className, placeholder }, ref) => {
      return (
        <Flex direction="column" mb={mb} w={w} {...containerStyles}>
          <HStack align="center">
            {isLoading && (
              <CircularProgress isIndeterminate color="brand.900" size="14px" />
            )}
            <FormLabel
              display={label || extra ? 'flex' : 'none'}
              htmlFor={id}
              mb={1}
              fontSize="sm"
              color={textColorPrimary}
              fontWeight="medium"
              _hover={{ cursor: 'pointer' }}
              {...labelProps}>
              {label}
              <Text fontSize="sm" fontWeight="400" ms="2px">
                {extra}
              </Text>
            </FormLabel>
          </HStack>
          <Button
            onClick={onClick}
            className={className}
            ref={ref}
            rightIcon={
              <Image
                src={imgSrc || CalendarIcon}
                ms="14px"
                w="32px"
                h="32px"
                bg="#93C3B3"
                padding="5px"
                borderRadius="10px"
                {...imgStyles}
              />
            }
            variant="date"
            {...buttonStyles}>
            {value || placeholder}
          </Button>
          {errorLabel && (
            <Text
              fontSize="xs"
              fontWeight={'semibold'}
              color="red.500"
              mt="2px">
              {t(errorLabel)}
            </Text>
          )}
        </Flex>
      );
    },
  );

  const renderDayContents = useCallback(
    (day: any, fullDate: any) => {
      const isSameDay = value && getDate(value) === day;
      const isSameMonth = value && getMonth(value) === fullDate.getMonth();
      const isSameYear = value && getYear(value) === fullDate.getFullYear();

      // check if the day is taken by the value
      const isDayTaken = isSameDay && isSameMonth && isSameYear;
      return (
        <Text
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="12px"
          borderRadius="10px"
          bg={isDayTaken ? (isGrey ? '#93C3B3' : '#AA915D') : 'white'}
          color={isDayTaken ? 'white' : 'rgba(0, 0, 0, 0.8)'}
          _hover={{ bg: !isGrey ? '#AA915D' : '#93C3B3', color: 'white' }}>
          {day}
        </Text>
      );
    },
    [value, isGrey],
  );

  const calendarContainer = useCallback(
    ({ className, children }: any) => {
      return (
        <CalendarContainer
          className={`${className} ${
            isGrey ? 'input-date-field-grey' : 'input-date-field'
          }`}>
          {children}
        </CalendarContainer>
      );
    },
    [isGrey],
  );

  return (
    <Flex w={buttonStyles?.w} {...mainContainerStyles}>
      <DatePicker
        customInput={<CustomInput />}
        renderDayContents={renderDayContents}
        calendarContainer={calendarContainer}
        showPopperArrow={false}
        dateFormat="MMM dd, yyyy"
        popperPlacement="bottom-start"
        selected={value}
        placeholderText={placeholder}
        title={placeholder}
        locale={i18n.language === 'he' ? 'he' : 'en'}
        {...rest}
      />
    </Flex>
  );
}
