import { useQueryClient } from '@tanstack/react-query';
import { formatToLocaleString } from 'helpers/formatNumber';
import { PAYMENT_KEYS, useGetExpenses } from 'hooks/usePayment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getDateRange } from 'utils/date.util';
import { getSortBy } from '../../financeOverview/hooks/useFinance';

export default function useOutcomes() {
  const queryClient = useQueryClient();
  const [expensesFilter, setExpensesFilter] = useState<{
    fromDate?: Date;
    toDate?: Date;
    range: string;
    search?: string;
    sortBy?: string;
  }>({
    range: '30days',
    search: '',
    sortBy: '',
  });

  const ExpensesQueryParams = useMemo(() => {
    const params: Record<string, any> = {};
    if (expensesFilter.range) {
      params.range = expensesFilter.range;
    }
    if (expensesFilter.fromDate) {
      params.fromDate = expensesFilter.fromDate;
    }
    if (expensesFilter.toDate) {
      params.toDate = expensesFilter.toDate;
    }
    if (expensesFilter.search) {
      params.search = expensesFilter.search;
    }
    if (expensesFilter.sortBy) {
      const sortBy = getSortBy(expensesFilter.sortBy);
      if (sortBy) {
        params.sortBy = sortBy;
      }
    }
    return params;
  }, [expensesFilter]);

  const { data: expenses } = useGetExpenses(ExpensesQueryParams);

  const expenseCalcs = useMemo(() => {
    let expected = 0;
    let paid = 0;
    let due = 0;
    expenses?.forEach((expense) => {
      expected += expense.amount;
      paid += expense.paidAmount;
      due += expense.amount - expense.paidAmount;
    });
    return {
      expected,
      paid,
      due: due < 0 ? 0 : due,
    };
  }, [expenses]);

  const handleSortExpenses = useCallback((header: string) => {
    setExpensesFilter((prev) => ({ ...prev, sortBy: header }));
  }, []);

  useEffect(() => {
    if (expensesFilter.range) {
      const { fromDate, toDate } = getDateRange(
        { range: expensesFilter.range },
        new Date(),
      );
      setExpensesFilter((prev) => ({ ...prev, fromDate, toDate }));
    }
  }, [expensesFilter.range]);

  const statsExpense = useMemo(
    () => [
      {
        title: 'finance_overview.expected',
        value: `₪${formatToLocaleString(expenseCalcs.expected)}`,
        bgColor: '#3F2F0D',
      },
      {
        title: 'finance_overview.paid',
        value: `₪${formatToLocaleString(expenseCalcs.paid)}`,
        bgColor: 'linear-gradient(104.02deg, #AA915D 20.34%, #443A25 183.1%)',
      },
      {
        title: 'finance_overview.due',
        value: `₪${formatToLocaleString(expenseCalcs.due)}`,
        bgColor: 'linear-gradient(104.02deg, #AA915D 20.34%, #443A25 183.1%)',
      },
    ],
    [expenseCalcs],
  );

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: PAYMENT_KEYS.lists() });
  }, [ExpensesQueryParams]);

  return {
    expenses,
    statsExpense,
    expensesFilter,
    setExpensesFilter,
    handleSortExpenses,
  };
}
