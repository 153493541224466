import {
  Button,
  Flex,
  Hide,
  HStack,
  Icon,
  Spinner,
  Text,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import DropdownField from 'components/fields/DropdownField';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { setCurrentDocumentNumber } from 'contexts/redux/currentDocumentNumber/currentDocumentNumberSlice';
import {
  AppDialogProps,
  setAssignDocNumberDialog,
  setUploadQuoteDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import {
  fetchNextPageQuotes,
  getQuotes,
} from 'contexts/redux/quote/quoteSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useGetBusinessPreferences } from 'queries/businessPreferences';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { IoTriangleSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { IQuoteListHeader } from 'services/@types';
import NoQuotes from './components/NoQuotes';
import NoQuotesCreated from './components/NoQuotesCreated';
import QuoteList from './components/QuoteList';

const tabs = ['sent', 'received'];

export default function Quotes() {
  const { t } = useTranslation();
  const { appNavigate: navigate } = useAppNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('');
  const myUser = useMyUser();
  const [activeTab, setActiveTab] = useState<'sent' | 'received'>('sent');
  const myBusiness = useMyBusiness();
  const { quotes, page, loading, totalPages } = useSelector(
    (state: RootState) => ({
      quotes: state.quote.qoutes,
      page: state.quote.pagination.page,
      loading: state.quote.loading,
      totalPages: state.quote.pagination.totalPages,
    }),
  );
  const { data: businessPreferences } = useGetBusinessPreferences();

  const isLoading = useMemo(() => {
    return loading && page > 1;
  }, [loading, page]);

  const handleSort = useCallback(
    (header: IQuoteListHeader) => {
      console.log('sortBy', sortBy);
      if (sortBy === header) {
        setSortBy('');
      } else if (sortBy.includes(header)) {
        setSortBy(header);
      } else {
        setSortBy(`-${header}`);
      }
    },
    [sortBy],
  );

  const handleParams = useCallback(() => {
    const params: {
      limit: number;
      search?: string;
      projectBy?: string;
      sortBy?: string;
      businessId?: string;
    } = {
      limit: 20,
      projectBy: '-updatedAt',
    };
    if (activeTab === 'sent') {
      params.businessId = myBusiness?.id;
    }
    if (search) {
      params.search = search;
    }

    if (sortBy) {
      let newSortBy = '';

      switch (sortBy) {
        case '-date':
          newSortBy = '-createdAt';
          break;
        case 'date':
          newSortBy = 'createdAt';
          break;
        case '-amount':
          newSortBy = '-generalPaymentDetails.totalPayment';
          break;
        case 'amount':
          newSortBy = 'generalPaymentDetails.totalPayment';
          break;
        case '-customer':
          newSortBy = '-customerDetails.firstName';
          break;
        case 'customer':
          newSortBy = 'customerDetails.firstName';
          break;
        case '-event_name':
          newSortBy = '-eventName';
          break;
        case 'event_name':
          newSortBy = 'eventName';
          break;
        case '-quote_number':
          newSortBy = '-quoteNumber';
          break;
        case 'quote_number':
          newSortBy = 'quoteNumber';
          break;
        case '-status':
          newSortBy = '-status';
          break;
        case 'status':
          newSortBy = 'status';
          break;
        default:
          newSortBy = '-createdAt';
          break;
      }
      params.sortBy = newSortBy;
    }
    return params;
  }, [myBusiness?.id, search, sortBy, activeTab]);

  const handleFetchNextPage = useCallback(() => {
    if (totalPages !== 0 && page >= totalPages) {
      return;
    }
    const params = handleParams();
    dispatch(fetchNextPageQuotes({ ...params, page: page + 1 }));
  }, [dispatch, page, handleParams, totalPages]);

  useEffect(() => {
    if (!myUser?.id || !myBusiness?.id) {
      return;
    }
    const params = handleParams();
    dispatch(getQuotes(params));
  }, [dispatch, handleParams, myUser?.id, myBusiness?.id]);

  const navigateToCreateQuote = useCallback(() => {
    const docNumber = businessPreferences?.quote;
    const docType = 'quote';

    dispatch(
      setAssignDocNumberDialog({
        item: {
          docNumber,
          docType,
        },
        onConfirm: ({
          docNumber,
          onlyCurrent,
        }: {
          docNumber: number;
          onlyCurrent: boolean;
        }) => {
          dispatch(
            setCurrentDocumentNumber({
              value: docNumber,
              type: docType,
              onlyCurrent,
            }),
          );
          navigate('/main/finance/quotes/create');
        },
      } as AppDialogProps),
    );
  }, [dispatch, navigate, businessPreferences?.quote]);

  const openUploadQuoteDialog = useCallback(() => {
    dispatch(setUploadQuoteDialog({} as AppDialogProps));
  }, [dispatch]);

  return (
    <Flex
      w="100%"
      pt={{ base: '200px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '60px' }}
      flexDirection="column"
      gap="8px">
      <Flex alignItems="center" gap="24px" ms={4}>
        {tabs.map((tab, index) => (
          <Flex
            key={index}
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            borderBottom="4px solid transparent"
            transition="all 0.4s ease"
            borderBottomColor={activeTab === tab ? '#93C3B3' : 'transparent'}
            onClick={() => setActiveTab(tab as 'sent' | 'received')}
            w="94px"
            h="41px">
            <Text variant="contractsTab">{t(`quote_list.${tab}`)}</Text>
          </Flex>
        ))}
      </Flex>
      <Hide above="md">
        <Card gap={5} borderRadius="0" zIndex={1} position="relative">
          <SearchBar
            w="173px"
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
            inputProps={{
              isDisabled: search === '' && quotes.length === 0,
              _disabled: {
                cursor: 'not-allowed',
              },
              borderRadius: '16px',
            }}
            borderRadius="16px"
          />
          <Flex align="center" gap="16px">
            <DropdownField
              options={[
                'date',
                'quote_number',
                'event_name',
                'amount',
                'customer',
                'version',
                'status',
              ].map((header) => ({
                label: t(`quote_list.${header.toLowerCase()}`),
                value: header,
                icon:
                  sortBy && sortBy?.includes?.(header) ? (
                    <Icon
                      as={IoTriangleSharp}
                      color="#93C3B3"
                      h="10px"
                      ml={2}
                      transform={
                        sortBy?.startsWith('-')
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)'
                      }
                    />
                  ) : null,
              }))}
              placeholder={t('quote_list.sort_by')}
              selectedOptions={sortBy || ''}
              onSelected={(selected: { value: string }) => {
                handleSort(selected.value as IQuoteListHeader);
              }}
            />
            <Button
              variant="h1cta"
              onClick={navigateToCreateQuote}
              flex="1"
              leftIcon={<Icon as={FaPlus} />}>
              {t('quote_list.create_quote')}
            </Button>
          </Flex>
        </Card>
      </Hide>
      <Card
        gap={5}
        h="calc(100vh - 170px)"
        borderRadius={{ base: 0, md: '30px' }}>
        <Hide below="md">
          <HStack justifyContent="space-between" align="center">
            <Text variant="cardTitle">{t('quote_list.title')}</Text>
            <Flex gap={4}>
              <SearchBar
                w="173px"
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                inputProps={{
                  isDisabled: search === '' && quotes.length === 0,
                  _disabled: {
                    cursor: 'not-allowed',
                  },
                }}
              />
              {/* {activeTab === 'sent' && (
              )}
              {activeTab !== 'sent' && (
              )} */}
              <Button
                variant="h1cta"
                onClick={openUploadQuoteDialog}
                p="18px 38px"
                leftIcon={<Icon as={FaPlus} />}>
                {t('quote_list.upload_quote')}
              </Button>
              <Button
                variant="h1cta"
                onClick={navigateToCreateQuote}
                p="18px 38px"
                leftIcon={<Icon as={FaPlus} />}>
                {t('quote_list.create_quote')}
              </Button>
            </Flex>
          </HStack>
        </Hide>
        {quotes.length > 0 && (
          <QuoteList
            quotes={quotes}
            handleSort={handleSort}
            sortBy={sortBy}
            handleFetchNextPage={handleFetchNextPage}
          />
        )}
        {search === '' && quotes.length === 0 && <NoQuotesCreated />}
        {search !== '' && quotes.length === 0 && <NoQuotes />}
        {isLoading && (
          <Flex justifyContent="center" alignItems="center" w="100%">
            <Spinner />
          </Flex>
        )}
      </Card>
    </Flex>
  );
}
