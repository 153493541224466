import {
  Button,
  Flex,
  HStack,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import InputAutocompleteField from 'components/fields/InputAutocompleteField';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClear } from 'react-icons/md';
import { Product } from 'services/@types';
import { useCreatePackage } from '../hooks/useCreatePackage';
import { AddNewProductSection } from './AddNewProductSection';

const tagsVariants = {
  pop: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      // stiffness: 260,
      // damping: 20,
      duration: 0.2,
    },
  },
};

interface PackageContentSectionProps {
  product: Product;
  isEditMode: boolean;
  handleChange?: (e: any) => void;
}
export const PackageContentSection: React.FC<PackageContentSectionProps> = ({
  product,
  isEditMode,
  handleChange,
}) => {
  const { t } = useTranslation();

  const {
    displayingOptions,
    handleOnSelected,
    handleOnSaveNewProduct,
    handleRemoveProduct,
    newProductInputValue,
    products,
    selectedProducts,
    newProducts,
    showNewProductSection,
    setShowNewProductSection,
    setNewProductInputValue,
  } = useCreatePackage(product, null, true);

  useEffect(() => {
    if (!handleChange) return;
    const subItems = [...newProducts, ...selectedProducts];

    handleChange({
      target: {
        name: 'subItems',
        value: subItems.map((item) => item.value),
      },
    });
  }, [newProducts, selectedProducts]);

  return (
    <VStack align="start" w="100%" px={0}>
      <Text fontWeight={'bold'} color="brand.900" px={0}>
        {t('products.add_package_modal.package_content')}
      </Text>
      <HStack
        px={0}
        h="100%"
        gap={{ base: 4, md: 10 }}
        w="100%"
        wrap="wrap"
        // minW={352}
        align="end"
        position={'relative'}>
        <InputAutocompleteField
          flex={1}
          label={t('products.add_package_modal.add_item')}
          w="100%"
          isDisabled={!isEditMode}
          maxW={320}
          id="name"
          value={newProductInputValue}
          onChange={(e) => setNewProductInputValue(e.target.value)}
          options={displayingOptions}
          onSelected={handleOnSelected}
          selectedOptions={selectedProducts}
        />
        <HStack
          gap={1}
          minW={352}
          maxW={360}
          transition={'opacity 0.3s'}
          opacity={displayingOptions.length === 0 ? 1 : 0}
          display={
            !showNewProductSection && newProductInputValue.length > 0
              ? 'flex'
              : 'none'
          }
          h={50}
          bottom={0}>
          <Text>{'This item dose not exist'}</Text>
          <Text
            fontWeight={500}
            color="brand.900"
            cursor={'pointer'}
            onClick={() => {
              setShowNewProductSection(true);
            }}
            textDecoration={'underline'}>
            Add new product
          </Text>
        </HStack>
      </HStack>
      <Flex>
        <AddNewProductSection
          showNewProductSection={showNewProductSection}
          onClose={function (): void {
            setShowNewProductSection(false);
          }}
          onSave={handleOnSaveNewProduct}
        />
      </Flex>
      <Flex wrap="wrap" gap={2} px={0}>
        <AnimatePresence>
          {[...newProducts, ...selectedProducts].map((prod) => {
            console.log('prod ->', prod);
            return (
              <Button
                as={motion.div}
                animate={'pop'}
                variants={tagsVariants}
                initial={{ scale: 0.6, opacity: 0 }}
                exit={{ scale: 0.6, opacity: 0 }}
                transition="0.1s"
                px="16px"
                py="12px"
                key={prod.value}
                rightIcon={
                  isEditMode && <IconButton
                    aria-label="remove product"
                    fontSize={'18px'}
                    variant="ghost"
                    icon={<MdClear />}
                    isDisabled={!isEditMode}
                    onClick={() => handleRemoveProduct(prod)}
                  />
                }
                variant="badgeTagBlue">
                {products.find((p) => (p?.id || p) === prod.value)?.name ||
                  prod.label}
              </Button>
            );
          })}
        </AnimatePresence>
      </Flex>
    </VStack>
  );
};
