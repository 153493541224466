import { Flex, Icon, IconButton, Image, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import DropdownField from 'components/fields/DropdownField';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import NoDocuments from 'assets/img/quote/NoQuotesCreated.png';

export default function RecentDocumentsCard() {
  const { t } = useTranslation();
  return (
    <Card
      p="33.5px 16px"
      gap="24px"
      borderRadius={{ base: '0', md: '30px' }}
      flex={{ base: '1', md: '1 1 25%' }}
      minH="600px">
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="previewQuoteContactTitle">
          {t('finance_overview.recent_documents')}
        </Text>
        <IconButton
          aria-label="Add"
          variant="actionIconBlueSmall"
          icon={<Icon as={FaPlus} color="warmWhite.100" />}
          display="flex"
          justifySelf="flex-end"
          transition="all 0.4s linear"
        />
      </Flex>
      <DropdownField
        options={[]}
        onSelected={() => {}}
        selectedOptions={[]}
        showedOptions={''}
        placeholder={t('finance_overview.last_30_days')}
      />
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        h="100%">
        <Image src={NoDocuments} alt="" w="180px" />
        <Text variant="financeOverviewNoDocumentsTitle">
          {t('finance_overview.nothing_yet_here')}
        </Text>
      </Flex>
    </Card>
  );
}
