import {
  fetchUser,
  useAuthToken,
  useMyUser,
} from 'contexts/redux/auth/authSlice';
import { fetchBusiness } from 'contexts/redux/business/businessSlice';
import { fetchCalendars } from 'contexts/redux/calendar/calendarsSlice';
import { fetchIntegrations } from 'contexts/redux/integrations/integrationSlice';
import { fetchInvites } from 'contexts/redux/invite/inviteSlice';
import { fetchNotifications } from 'contexts/redux/notifications/notificationsSlice';
import { RootState } from 'contexts/redux/store';
import { fetchSuppliers } from 'contexts/redux/supplier/supplierSlice';
import { fetchMyUserPreferences } from 'contexts/redux/userPreferences/userPreferencesSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSeatInviteRole from './useSeatInviteRole';

export const useUserInfoLoader = () => {
  const dispatch = useDispatch<any>();
  const authToken = useAuthToken();
  const user = useMyUser();
  const { loading } = useSelector((state: RootState) => state.businesses);
  const [loaders, setLoaders] = useState<{
    businessLoader: false;
  }>();

  useEffect(() => {
    if (authToken && user?.businessID) {
      dispatch(fetchBusiness(user.businessID));
      dispatch(fetchUser(user.id));
      dispatch(fetchSuppliers({}));
      dispatch(fetchInvites({}));
      dispatch(fetchIntegrations({}));
      dispatch(fetchMyUserPreferences());
      dispatch(fetchCalendars({}));
      dispatch(fetchNotifications({ limit: 1000 }));
    }
  }, [authToken, dispatch, user?.businessID, user?.id]);

  useSeatInviteRole();

  useEffect(() => {
    setLoaders((prev: any) => ({
      ...prev,
      businessLoader: loading,
    }));
  }, [loading]);

  return { loaders };
};
