import {
  Checkbox,
  FlexProps,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';

export default function ProductActions({
  product,
  index,
  handleChangeProductVatIncluded,
  businessPreferences,
  handleRemoveProduct,
  Parent,
  ParentProps,
}: {
  product: any;
  index: number;
  handleChangeProductVatIncluded: (e: any, index: number) => void;
  businessPreferences: any;
  handleRemoveProduct: (index: number) => void;
  Parent: any;
  ParentProps?: FlexProps;
}) {
  const { t } = useTranslation();
  return (
    <Parent {...ParentProps}>
      <Checkbox
        variant="roundBrand"
        isChecked={product?.isVatIncluded}
        onChange={(e) => handleChangeProductVatIncluded(e, index)}
        w={{ base: '100%', md: 'auto' }}
        h="50px"
        alignItems="center">
        <Text variant="createQuoteCheckboxText">
          {t('create_invoice.vat', {
            percentage: businessPreferences?.vatPercent,
          })}
        </Text>
      </Checkbox>

      <Menu>
        <MenuButton
          as={IconButton}
          aria-label=""
          icon={<Icon as={FiMoreHorizontal} color="#93C3B3" />}
          variant="ghost"
          h="50px"
        />
        <MenuList>
          <MenuItem onClick={() => handleRemoveProduct(index)}>
            {t('create_invoice.remove_product')}
          </MenuItem>
        </MenuList>
      </Menu>
    </Parent>
  );
}
