import editPencilIcon from 'assets/svg/event/edit-pencil-icon.svg';
import { BriefNote } from 'services/@types';

import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import _uploadService from 'services/upload.api';
import { handleOpenPdf } from 'helpers/handleOpenPdf';

interface BriefItemProps {
  id: string;
  item: BriefNote;
  onEdit: () => void;
}

export const BriefItem = ({ item, onEdit }: BriefItemProps) => {
  const { title, description, media } = item;
  return (
    <Flex gap="10" justify="space-between" align="start" w="full">
      <Flex gap="3" align="start" w="full">
        <Box w="3" minW={3} h="3" bg="brand.900" rounded="full" mt="2" />
        <VStack align="start" spacing="1" w="full">
          <HStack
            spacing={0}
            w="100%"
            justify="space-between"
            alignItems={'flex-start'}>
            <VStack align="start" spacing="1">
              <Text
                fontSize="base"
                fontWeight="bold"
                color="blackAlpha.800"
                wordBreak={'break-all'}>
                {title}
              </Text>
              <Text
                fontSize="sm"
                color="blackAlpha.600"
                whiteSpace="pre-line"
                wordBreak={'break-all'}>
                {description}
              </Text>
            </VStack>
            <IconButton
              aria-label="Edit"
              icon={<Image src={editPencilIcon} />}
              variant="ghost"
              onClick={onEdit}
            />
          </HStack>
          {media?.[0]?.displayName && (
            <Button
              onClick={async () => {
                // if the file is a pdf, open it in a new tab
                if (media?.[0]?.displayName.endsWith('.pdf')) {
                  handleOpenPdf(media?.[0]?.url, media?.[0]?.displayName);
                  return;
                }
                const file = await _uploadService.getUpload(media?.[0]?.url);
                const url = URL.createObjectURL(file);

                // Create temporary link element for download
                const link = document.createElement('a');
                link.href = url;
                link.download = media?.[0]?.displayName || 'download';
                document.body.appendChild(link);
                link.click();

                // Cleanup
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
              }}
              variant="h4"
              paddingStart={0}
              maxW={220}>
              <Text
                as="span"
                textDecorationLine="underline"
                noOfLines={2}
                fontSize="sm"
                fontWeight={500}
                overflow="hidden"
                textOverflow="ellipsis">
                {media?.[0]?.displayName}
              </Text>
            </Button>
          )}
        </VStack>
      </Flex>
    </Flex>
  );
};
