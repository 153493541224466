import { useToast } from '@chakra-ui/react';
import {
  setContractPreview,
  shareContract,
  useContractPreview,
} from 'contexts/redux/contract/contractSlice';
import {
  closeSplashDialog,
  setSplashDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { format } from 'date-fns';
import html2canvas from 'html2canvas-pro';
import jsPDF from 'jspdf';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useSearchParams } from 'react-router-dom';

const blobToFile = (blob: Blob, fileName: string) => {
  return new File([blob], fileName, { type: blob.type });
};

const usePreviewContract = () => {
  const { t } = useTranslation();
  const { appNavigate: navigate } = useAppNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<any>();
  const toast = useToast();

  // Desktop view handling when generating PDFs
  const [forcedDesktopView, setForcedDesktopView] = useState<{
    state: boolean;
    executor: null | any;
  }>({
    state: true,
    executor: () => {},
  });

  const [pdfToShare, setPdfToShare] = useState<Blob | null>(null);

  const contractPreviewRef = useRef<HTMLDivElement>(null);
  const { contractPreview } = useContractPreview();

  const contractFileName = useMemo(() => {
    return `contract${
      contractPreview?.documentTitle ? '_' + contractPreview?.documentTitle : ''
    }_${format(new Date(), 'dd-MM-yyyy')}.pdf`;
  }, [contractPreview?.documentTitle]);

  const shouldSendToContact = useMemo(() => {
    const shouldSend = searchParams.get('sent');
    return shouldSend === 'true';
  }, [searchParams]);

  const goBack = useCallback(() => {
    console.log('window?.history', window?.history);
    if (!window?.history?.length) {
      navigate('/main/finance/contracts');
    }
    if (window?.history?.length > 1) {
      navigate(-1);
    } else {
      navigate('/main/finance/contracts');
    }
  }, [navigate]);
  const decodeHTMLEntities = useCallback((str: string) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = str;
    return textarea.value;
  }, []);
  const generatedContract = useMemo(() => {
    let contractContent = '';

    for (const paragraphIndex in contractPreview?.paragraphs || []) {
      const paragraph = contractPreview?.paragraphs[paragraphIndex];
      const each = decodeHTMLEntities(paragraph.content);
      contractContent +=
        paragraphIndex === '0'
          ? each
          : `<div style="margin-top: 12px;"><p>${each.replace(/\n/g, '<br>')}</p></div>`;
    }
    return contractContent;
  }, [contractPreview?.paragraphs, decodeHTMLEntities]);

  const handleGenerateContractPDF = useCallback(
    async (returnBlob: boolean = false) => {
      if (!contractPreviewRef.current) return;

      dispatch(
        setSplashDialog({ title: 'create_contract.splash_generating_pdf' }),
      );

      setForcedDesktopView({
        state: true,
        executor: async () => {
          try {
            // Capture the component as an image
            const canvas = await html2canvas(contractPreviewRef.current, {
              scale: 2,
            });

            const imgData = canvas.toDataURL('image/jpeg');

            // Create a new PDF document
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgProps = pdf.getImageProperties(imgData);

            // Calculate image dimensions to fit the PDF page
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            // Add the image to the PDF
            pdf.addImage(imgData, 'jpeg', 0, 0, pdfWidth, pdfHeight);

            if (!returnBlob) {
              // Save the PDF
              pdf.save(contractFileName);
              return true;
            }

            setPdfToShare(pdf.output('blob'));
            return true;
          } finally {
            if (!returnBlob) {
              dispatch(closeSplashDialog());
            }
          }
        },
      });
    },
    [dispatch, contractFileName],
  );

  const handleShareContract = useCallback(async () => {
    await handleGenerateContractPDF(true);
  }, [handleGenerateContractPDF]);

  useEffect(() => {
    if (!contractPreview) {
      goBack();
    }
    const timeout = setTimeout(() => {
      if (shouldSendToContact) {
        handleShareContract();
      }
      return () => {
        dispatch(setContractPreview(null));
      };
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    contractPreview,
    dispatch,
    goBack,
    handleShareContract,
    shouldSendToContact,
  ]);

  useEffect(() => {
    if (pdfToShare) {
      (async () => {
        const file = blobToFile(pdfToShare, contractFileName);

        if (!file) {
          toast({
            title: t('contract_sharing_dialog.error_generating_pdf'),
            variant: 'main',
            position: 'top-right',
            status: 'error',
          });
          return;
        }
        const res = await dispatch(
          shareContract({ file, contractId: contractPreview?.id }),
        );
        if (res) {
          setTimeout(() => {
            navigate('/main/finance/contracts');
            dispatch(closeSplashDialog());
            toast({
              title: t('contract_sharing_dialog.sent'),
              variant: 'main',
              position: 'top-right',
              status: 'success',
            });
          }, 1000);
        }
      })();
    }
  }, [
    pdfToShare,
    contractFileName,
    contractPreview?.id,
    dispatch,
    navigate,
    t,
    toast,
  ]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (forcedDesktopView.state) {
      timeout = setTimeout(() => {
        forcedDesktopView?.executor();
        setForcedDesktopView({ state: false, executor: null });
      }, 300);
    }
    return () => timeout && clearTimeout(timeout);
  }, [forcedDesktopView]);

  return {
    goBack,
    generatedContract,
    contractPreviewRef,
    handleGenerateContractPDF,
    forcedDesktopView,
  };
};

export default usePreviewContract;
