import { createClient, fetchClients } from 'contexts/redux/client/clientSlice';
import {
  AppDialogProps,
  closeContactsPickerDialog,
  setAddSupplierDialog,
  setOfferClientToSupplierDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { fetchSuppliers } from 'contexts/redux/supplier/supplierSlice';
import useDebounce from 'hooks/useDebounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IClient, IClientContact, Supplier } from 'services/@types';
import { convertToClient, trimAndLowerCase } from '../helpers';

type Id = string;

type SelectClientFunction = (
  clientId: string,
  contactId?: string,
) => Promise<void>;
type SelectSupplierFunction = (supplierId: string) => Promise<void>;

export default function useContactsPickerDialog() {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedClients, setSelectedClients] = useState<
    Map<Id, { client: IClient; contacts: IClientContact[] | [] }>
  >(new Map());
  const [selectedSuppliers, setSelectedSuppliers] = useState<Map<Id, Supplier>>(
    new Map(),
  );
  const { contactsPickerDialog, clients, suppliers } = useSelector(
    (state: RootState) => ({
      contactsPickerDialog: state.dialogs.contactsPickerDialog,
      clients: state.clients.clients,
      suppliers: state.suppliers.suppliers,
    }),
  );
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 700);

  const filteredClients = useMemo(() => {
    if (!debouncedSearch)
      return clients.map((c) => ({ ...c, contactType: 'client' }));
    return clients
      .filter((c) =>
        trimAndLowerCase(
          `${c.firstName}${c.lastName || ''}${c.email}${c.company || ''}`,
        ).includes(debouncedSearch.toLowerCase().trim()),
      )
      .map((c) => ({ ...c, contactType: 'client' }));
  }, [clients, debouncedSearch]);

  const dialog = useMemo(
    () => contactsPickerDialog as AppDialogProps,
    [contactsPickerDialog],
  );

  const isOpen = useMemo(() => !!dialog, [dialog]);

  const filteredSuppliers = useMemo(() => {
    if (!debouncedSearch)
      return suppliers.map((s) => ({ ...s, contactType: 'supplier' }));
    return suppliers
      .filter((s) =>
        trimAndLowerCase(`${s.name}${s.email}${s.phone}`).includes(
          debouncedSearch.toLowerCase().trim(),
        ),
      )
      .map((s) => ({ ...s, contactType: 'supplier' }));
  }, [suppliers, debouncedSearch]);

  // type: clients, suppliers, contacts
  const type = useMemo(() => {
    if (dialog?.item?.type) {
      return dialog?.item?.type;
    }
    if (dialog?.item) {
      console.error(
        'useContactsPickerDialog: No type found in dialog item, defaulting to clients',
      );
    }

    // default to clients
    return 'clients';
  }, [dialog?.item]);

  const filteredContacts = useMemo(() => {
    const contracts = [];
    if (type === 'clients') {
      contracts.push(...filteredClients);
      contracts.push(
        ...filteredSuppliers.filter(
          (s) =>
            !filteredClients.some(
              (c) =>
                c.email === s.email ||
                c.phone === s.phone ||
                c.firstName + ' ' + c.lastName === s.name,
            ),
        ),
      );
    } else {
      contracts.push(...filteredSuppliers);
      contracts.push(
        ...filteredClients.filter(
          (c) =>
            !filteredSuppliers.some(
              (s) =>
                s.email === c.email ||
                s.phone === c.phone ||
                s.name === c.firstName + ' ' + c.lastName,
            ),
        ),
      );
    }
    return contracts;
  }, [type, filteredClients, filteredSuppliers]);

  const handleClose = useCallback(() => {
    dispatch(closeContactsPickerDialog());
    setSelectedClients(new Map());
    setSelectedSuppliers(new Map());
  }, [dispatch]);

  const handleSubmitContactsSelection = useCallback(() => {
    if (dialog?.onConfirm) {
      if (type === 'clients') {
        dialog.onConfirm(
          Array.from(selectedClients.entries()).map(
            ([clientId, { client, contacts }]) => ({
              client: clients.find((c) => c.id === clientId),
              contacts: contacts,
            }),
          ),
        );
      }

      if (type === 'suppliers') {
        dialog.onConfirm(
          Array.from(selectedSuppliers.entries()).map(
            ([supplierId, supplier]) => ({
              supplier: suppliers.find((s) => s.id === supplierId),
            }),
          ),
        );
      }

      if (type === 'contacts') {
        dialog.onConfirm({
          clients: Array.from(selectedClients.entries()).map(
            ([clientId, { client, contacts }]) => ({
              client: clients.find((c) => c.id === clientId),
              contacts: contacts,
            }),
          ),
          suppliers: Array.from(selectedSuppliers.entries()).map(
            ([supplierId, supplier]) => ({
              supplier: suppliers.find((s) => s.id === supplierId),
            }),
          ),
        });
      }
    }
    handleClose();
  }, [
    handleClose,
    dialog,
    selectedClients,
    clients,
    selectedSuppliers,
    type,
    suppliers,
  ]);

  const handleClientToSupplierConversion = useCallback(
    async (clientId: string, onSuccess: (supplierId: string) => void) => {
      dispatch(
        setOfferClientToSupplierDialog({
          item: {
            clientId,
          },
          onConfirm: async (client: IClient) => {
            dispatch(
              setAddSupplierDialog({
                item: {
                  clientDetails: client,
                },
                onConfirm: async (supplierId: string) => {
                  onSuccess(supplierId);
                },
              }),
            );
          },
        }),
      );
    },
    [dispatch],
  );

  const handleSupplierToClientConversion = useCallback(
    async (supplierId: string, onSuccess: (clientId: string) => void) => {
      try {
        const supplier = suppliers.find((s) => s.id === supplierId);
        if (supplier) {
          const convertedClient = convertToClient(supplier);
          const newClient = await dispatch(
            createClient({ client: convertedClient }),
          );
          const newClientData = newClient?.payload as IClient;
          if (newClientData?.id) {
            onSuccess(newClientData.id);
          }
        }
      } catch (error) {
        console.error('handleSupplierToClientConversion: ', error);
      }
    },
    [dispatch, suppliers],
  );

  const updateSelectedClients = useCallback(
    (clientId: string, contactId?: string) => {
      setSelectedClients((prev) => {
        const selectedClientMap = new Map(prev);
        const foundClientExists = selectedClientMap.has(clientId);
        const foundClient = selectedClientMap.get(clientId);

        if (!contactId) {
          if (!foundClientExists) {
            selectedClientMap.set(clientId, {
              client: clients.find((c) => c.id === clientId),
              contacts: null,
            });
          } else {
            selectedClientMap.delete(clientId);
          }
        } else {
          if (!foundClientExists) {
            const client = clients.find((c) => c.id === clientId);
            const contact = client?.contacts?.find((c) => c?.id === contactId);
            if (contact) {
              selectedClientMap.set(clientId, {
                client,
                contacts: [contact],
              });
            }
          } else {
            const foundContact = foundClient?.contacts?.find(
              (contact) => contact?.id === contactId,
            );
            if (!foundContact) {
              const client = clients.find((c) => c.id === clientId);
              const contact = client?.contacts?.find(
                (c) => c?.id === contactId,
              );
              if (contact) {
                selectedClientMap.set(clientId, {
                  client,
                  contacts: foundClient?.contacts?.length
                    ? [...foundClient.contacts, contact]
                    : [contact],
                });
              }
            } else {
              if (foundClient?.contacts?.length > 1) {
                selectedClientMap.set(clientId, {
                  ...foundClient,
                  contacts: foundClient?.contacts?.filter(
                    (c) => c?.id !== contactId,
                  ),
                });
              } else {
                selectedClientMap.delete(clientId);
              }
            }
          }
        }
        return selectedClientMap;
      });
    },
    [clients],
  );

  const updateSelectedSuppliers = useCallback(
    (supplierId: string) => {
      setSelectedSuppliers((prev) => {
        const newMap = new Map(prev);
        if (newMap.has(supplierId)) {
          newMap.delete(supplierId);
        } else {
          newMap.set(
            supplierId,
            suppliers.find((s) => s.id === supplierId),
          );
        }
        return newMap;
      });
    },
    [suppliers],
  );

  const handleSelectClient: SelectClientFunction = useCallback(
    async (clientId: string, contactId?: string) => {
      if (type === 'suppliers') {
        await handleClientToSupplierConversion(
          clientId,
          updateSelectedSuppliers,
        );
        return;
      }
      updateSelectedClients(clientId, contactId);
    },
    [
      type,
      updateSelectedClients,
      handleClientToSupplierConversion,
      updateSelectedSuppliers,
    ],
  );

  const handleSelectSupplier: SelectSupplierFunction = useCallback(
    async (supplierId: string) => {
      if (type === 'clients') {
        await handleSupplierToClientConversion(supplierId, (clientId) => {
          updateSelectedClients(clientId);
        });
        return;
      }
      updateSelectedSuppliers(supplierId);
    },
    [
      type,
      handleSupplierToClientConversion,
      updateSelectedClients,
      updateSelectedSuppliers,
    ],
  );

  const handleSearch = useCallback((e: any) => {
    setSearch(e.target.value);
  }, []);

  useEffect(() => {
    const getClients = async () => {
      if (!!dialog) {
        await dispatch(fetchClients({}));
        await dispatch(fetchSuppliers({}));
      }
    };
    getClients();
  }, [dispatch, dialog]);

  useEffect(() => {
    if (dialog?.item?.chosenClients) {
      setSelectedClients(
        new Map(
          dialog?.item?.chosenClients.map((client: any) => [
            client?.client?.id,
            client,
          ]),
        ),
      );
    }
  }, [dialog?.item?.chosenClients]);

  useEffect(() => {
    if (dialog?.item?.chosenSuppliers) {
      setSelectedSuppliers(
        new Map(
          dialog?.item?.chosenSuppliers.map((supplier: any) => [
            supplier?.id,
            supplier,
          ]),
        ),
      );
    }
  }, [dialog?.item?.chosenSuppliers]);

  return {
    filteredContacts,
    handleClose,
    handleSelectClient,
    handleSubmitContactsSelection,
    isOpen,
    type,
    search,
    handleSearch,
    selectedClients,
    handleSelectSupplier,
    selectedSuppliers,
  };
}
