import { useToast } from '@chakra-ui/react';
import {
  createClient,
  fetchClients,
  updateClient,
} from 'contexts/redux/client/clientSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IClient, IClientContact } from 'services/@types';

interface MultiClientForm {
  id: string;
  form: Partial<IClient>;
  errors: string[];
  isInviteToBrilliant: boolean;
}

const initialForm: MultiClientForm = {
  id: crypto.randomUUID(),
  form: { type: 'private' },
  errors: [],
  isInviteToBrilliant: false,
};

export default function useClientForm({
  onClose,
}: {
  onClose: (newClientData?: IClient) => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch<any>();
  const { editClient } = useSelector((state: RootState) => state.clients);
  const [clientForms, setClientForms] = useState<MultiClientForm[]>([
    initialForm,
  ]);
  const [activeBusinessFormId, setActiveBusinessFormId] = useState<
    string | null
  >(null);

  const isEdit = useMemo(() => !!editClient, [editClient]);

  const resetForms = useCallback(() => {
    setClientForms([{ ...initialForm, id: crypto.randomUUID() }]);
  }, []);

  const handleClose = useCallback(() => {
    resetForms();
    onClose();
  }, [onClose, resetForms]);

  const handleChange = useCallback(
    (formId: string, e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, type, checked } = e.target;

      setClientForms((prev) =>
        prev.map((form) => {
          if (form.id === formId) {
            if (type === 'checkbox') {
              return {
                ...form,
                isInviteToBrilliant: checked,
                errors: form.errors.filter((field) => field !== name),
              };
            }
            return {
              ...form,
              errors: form.errors.filter((field) => field !== name),
              form: { ...form.form, [name]: e.target.value },
            };
          }
          return form;
        }),
      );
    },
    [],
  );

  const handleAddNewForm = useCallback(() => {
    setClientForms((prev) => [
      ...prev,
      {
        id: crypto.randomUUID(),
        form: { type: 'private' },
        errors: [],
        isInviteToBrilliant: false,
      },
    ]);
  }, []);

  const handleRemoveForm = useCallback((formId: string) => {
    setClientForms((prev) => prev.filter((form) => form.id !== formId));
  }, []);

  // Validation function for a single form
  const validateForm = useCallback((form: Partial<IClient>): string[] => {
    const errors: string[] = [];

    if (form.type === 'private') {
      if (!form.firstName?.trim()) errors.push('firstName');
      if (!form.lastName?.trim()) errors.push('lastName');
      if (!form.phone?.trim()) errors.push('phone');
      if (!form.email?.trim()) errors.push('email');
    } else if (form.type === 'business') {
      if (!form.company?.trim()) errors.push('company');
      if (!form.phone?.trim()) errors.push('phone');
      if (!form.email?.trim()) errors.push('email');
      if (!form.contacts?.length) errors.push('contacts');
    }

    // Email validation
    if (form.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)) {
      errors.push('email');
    }

    return errors;
  }, []);

  // Validate all forms
  const validateAllForms = useCallback((): boolean => {
    let isValid = true;

    setClientForms((prev) =>
      prev.map((clientForm) => {
        const formErrors = validateForm(clientForm.form);
        if (formErrors.length > 0) isValid = false;

        return {
          ...clientForm,
          errors: formErrors,
        };
      }),
    );

    return isValid;
  }, [validateForm]);

  const handleSubmitAll = useCallback(async () => {
    const isValid = validateAllForms();

    if (!isValid) {
      return;
    }

    try {
      if (isEdit) {
        const clientForm = clientForms[0];
        const updates = { ...clientForm.form };
        const result = await dispatch(
          updateClient({ clientId: editClient?.id, updates }),
        );

        if (!result.error) {
          await dispatch(fetchClients({}));
          toast({
            title: t('clients.client_updated'),
            variant: 'main',
            position: 'top-right',
          });
          handleClose();
        } else if (result?.payload?.response?.data?.message) {
          toast({
            title: result.payload.response.data.message,
            variant: 'error',
            position: 'top-right',
          });
        }
      } else {
        const promises = clientForms.map((clientForm) =>
          dispatch(createClient({ client: clientForm.form })),
        );

        const results = await Promise.all(promises);
        const hasErrors = results.some((result) => result.error);

        if (!hasErrors) {
          await dispatch(fetchClients({}));
          toast({
            title: t('clients.clients_created'),
            variant: 'main',
            position: 'top-right',
          });
          handleClose();
        }
      }
    } catch (error) {
      toast({
        title: isEdit
          ? t('clients.error_updating_client')
          : t('clients.error_creating_clients'),
        status: 'error',
        position: 'top-right',
      });
    }
  }, [
    clientForms,
    dispatch,
    validateAllForms,
    toast,
    t,
    handleClose,
    isEdit,
    editClient,
  ]);

  const handleFoundClient = useCallback((client: IClient) => {
    setClientForms((prev) =>
      prev.map((form) => {
        if (form.id === client.id) {
          return {
            ...form,
            form: client,
          };
        }
        return form;
      }),
    );
  }, []);

  const handleOpenContactCreate = useCallback((formId: string) => {
    setActiveBusinessFormId(formId);
  }, []);

  const handleCloseContactCreate = useCallback(() => {
    setActiveBusinessFormId(null);
  }, []);

  const handleCreateContact = useCallback(
    (contact: IClientContact) => {
      if (!activeBusinessFormId) return;

      setClientForms((prev) =>
        prev.map((form) => {
          if (form.id === activeBusinessFormId) {
            return {
              ...form,
              form: {
                ...form.form,
                contacts: [...(form.form?.contacts || []), contact],
              },
              errors: form.errors.filter((error) => error !== 'contacts'),
            };
          }
          return form;
        }),
      );

      handleCloseContactCreate();
    },
    [activeBusinessFormId, handleCloseContactCreate],
  );

  const handleRemoveContact = useCallback((formId: string, index: number) => {
    setClientForms((prev) =>
      prev.map((form) => {
        if (form.id === formId && form.form.contacts) {
          const newContacts = [...form.form.contacts];
          newContacts.splice(index, 1);
          return {
            ...form,
            form: {
              ...form.form,
              contacts: newContacts,
            },
          };
        }
        return form;
      }),
    );
  }, []);

  useEffect(() => {
    if (editClient) {
      setClientForms([
        {
          id: editClient.id,
          form: editClient,
          errors: [],
          isInviteToBrilliant: false,
        },
      ]);
    } else {
      resetForms();
    }
  }, [editClient, resetForms]);

  return {
    clientForms,
    handleChange,
    handleAddNewForm,
    handleRemoveForm,
    handleSubmitAll,
    handleFoundClient,
    handleCreateContact,
    handleRemoveContact,
    validateForm,
    validateAllForms,
    isEdit,
    handleClose,
    handleOpenContactCreate,
    handleCloseContactCreate,
    activeBusinessFormId,
  };
}
