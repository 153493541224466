import { Flex } from '@chakra-ui/react';
import FinanceCard from './components/FinanceCard';
import RecentDocumentsCard from './components/RecentDocumentsCard';
import ReportsCard from './components/ReportsCard';
import useFinance from './hooks/useFinance';

export default function FinanceOverview() {
  const {
    expenses,
    incomes,
    statsIncome,
    statsExpense,
    handleShowAllExpenses,
    handleShowAllIncomes,
    incomesFilter,
    expensesFilter,
    setIncomesFilter,
    setExpensesFilter,
    handlePlusClick,
    handleSortIncomes,
    handleSortExpenses,
  } = useFinance();
  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '0' }}
      flexDirection="column"
      gap="8px">
      <Flex
        wrap="wrap"
        flexDirection={{ base: 'column', md: 'row' }}
        gap="20px"
        maxW="1920px">
        <FinanceCard
          title={'finance_overview.income_tracking'}
          stats={statsIncome}
          payments={incomes}
          handleShowAll={handleShowAllIncomes}
          filter={incomesFilter}
          setFilter={setIncomesFilter}
          handlePlusClick={() => handlePlusClick('income')}
          handleSort={handleSortIncomes}
        />
        <ReportsCard />
        <FinanceCard
          title={'finance_overview.outcome_tracking'}
          stats={statsExpense}
          payments={expenses}
          handleShowAll={handleShowAllExpenses}
          filter={expensesFilter}
          setFilter={setExpensesFilter}
          handlePlusClick={() => handlePlusClick('expense')}
          handleSort={handleSortExpenses}
        />
        <RecentDocumentsCard />
      </Flex>
    </Flex>
  );
}
