import { AxiosInstance, AxiosResponse } from 'axios';
import { INotification, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class NotificationService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async getNotifications(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<INotification>> {
    const response: AxiosResponse<PaginatedResults<INotification>> =
      await this.api.get('/notifications', {
        params: queryParams,
      });
    return response.data;
  }

  public async queryNotifications(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<INotification>> {
    const response: AxiosResponse<PaginatedResults<INotification>> =
      await this.api.get('/notifications', {
        params: queryParams,
      });
    return response.data;
  }

  public async getNotification(id: string): Promise<INotification> {
    const response: AxiosResponse<INotification> = await this.api.get(
      `/notifications/${id}`,
    );
    return response.data;
  }

  public async updateNotification(
    id: string,
    updates: Partial<INotification>,
  ): Promise<INotification> {
    const response: AxiosResponse<INotification> = await this.api.patch(
      `/notifications/${id}`,
      updates,
    );
    return response.data;
  }

  public async markNotificationAsRead(id: string): Promise<void> {
    console.log('markNotificationAsRead', id);
    await this.updateNotification(id, { readAt: new Date() });
  }

  public async deleteNotification(id: string): Promise<void> {
    await this.api.delete(`/notifications/${id}`);
  }
  // get unread notifications
  public async getUnreadNotifications(): Promise<{ count: number }> {
    const response: AxiosResponse<{ count: number }> = await this.api.get(
      '/notifications/unread-count',
    );
    return response.data;
  }
}

const _INotificationService = new NotificationService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _INotificationService;
