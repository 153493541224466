import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  AppDialogProps,
  closeExternalSupplierDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useGetEntity } from 'queries/entity';
import { useApproveSupplier, useDeclineSupplier } from 'queries/supplier';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BsMusicPlayer } from 'react-icons/bs';
import { GrLocation } from 'react-icons/gr';
import { LuPhone } from 'react-icons/lu';
import { MdClose } from 'react-icons/md';
import { TbMailOpened } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { Supplier } from 'services/@types';

const ExternalSupplierDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { externalSupplierDialog } = useSelector((state: RootState) => ({
    externalSupplierDialog: state.dialogs.externalSupplierDialog,
  }));
  const dialog = externalSupplierDialog as AppDialogProps;
  const handleClose = useCallback(() => {
    dispatch(closeExternalSupplierDialog());
  }, [dispatch]);

  const { data: supplier } = useGetEntity<Supplier>(
    'supplier',
    dialog?.item?.supplierId,
  );

  const { mutateAsync: approveSupplier } = useApproveSupplier();
  const { mutateAsync: declineSupplier } = useDeclineSupplier();

  const handleConfirm = useCallback(async () => {
    try {
      await approveSupplier(dialog?.item?.supplierId);

      if (dialog?.onConfirm) {
        handleClose();
        dialog?.onConfirm();
      }
    } catch (error) {
      console.error('error -> ', error);
    }
    if (dialog?.onConfirm) {
      handleClose();
      dialog?.onConfirm();
    }
  }, [dialog, handleClose, approveSupplier]);

  const handleDecline = useCallback(async () => {
    try {
      await declineSupplier(dialog?.item?.supplierId);

      if (dialog?.onConfirm) {
        dialog?.onConfirm();
      }
      handleClose();
    } catch (error) {
      console.error('error -> ', error);
    }
  }, [dialog, handleClose, declineSupplier]);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px" overflow="hidden">
        <ModalBody p="24px" pt={0}>
          <Flex gap="32px" flexDir="column">
            <HStack justify="space-between" align="center">
              <Text
                fontSize="base"
                fontWeight={600}
                color="#000000"
                lineHeight="20px">
                {t('dialogs.supplier_information.title')}
              </Text>
              <IconButton
                icon={<Icon as={MdClose} color="#939393" w="24px" h="24px" />}
                aria-label="close"
                variant="ghost"
                onClick={handleClose}
              />
            </HStack>
            <VStack gap={6} align="start" spacing={0}>
              <VStack gap={4} spacing={0} align="start">
                <Text variant="supInfoTitle">
                  {t('dialogs.supplier_information.business_details')}
                </Text>
                <VStack gap={3} align="start" spacing={0}>
                  <Flex gap={'10px'}>
                    <Icon as={TbMailOpened} color="inherit" w="15px" h="15px" />
                    <Text variant="supInfoValue">{supplier?.email || '_'}</Text>
                  </Flex>
                  <Flex gap={'10px'}>
                    <Icon as={LuPhone} color="inherit" w="15px" h="15px" />
                    <Text variant="supInfoValue">{supplier?.phone || '_'}</Text>
                  </Flex>
                  <Flex gap={'10px'}>
                    <Icon
                      as={BsMusicPlayer}
                      color="inherit"
                      w="15px"
                      h="15px"
                    />
                    <Text variant="supInfoValue">{supplier?.vatId || '_'}</Text>
                  </Flex>
                  <Flex gap={'10px'}>
                    <Icon as={GrLocation} color="inherit" w="15px" h="15px" />
                    <Text variant="supInfoValue">
                      {supplier?.address || '_'}
                    </Text>
                  </Flex>
                </VStack>
              </VStack>
              {supplier?.contacts?.length > 0 &&
                supplier.contacts.map((contact) => (
                  <VStack gap={4} spacing={0} align="start">
                    <Text variant="supInfoTitle">
                      {contact.fullName || '_'}{' '}
                      {t('dialogs.supplier_information.details')}
                    </Text>
                    <VStack gap={3} align="start" spacing={0}>
                      <Flex gap={'10px'}>
                        <Icon
                          as={TbMailOpened}
                          color="inherit"
                          w="15px"
                          h="15px"
                        />
                        <Text variant="supInfoValue">
                          {contact.email || '_'}
                        </Text>
                      </Flex>
                      <Flex gap={'10px'}>
                        <Icon as={LuPhone} color="inherit" w="15px" h="15px" />
                        <Text variant="supInfoValue">
                          {contact.phone || '_'}
                        </Text>
                      </Flex>
                    </VStack>
                  </VStack>
                ))}
              <VStack gap={4} spacing={0} align="start">
                <Text variant="supInfoTitle">
                  {t('dialogs.supplier_information.bank_details')}
                </Text>
                <VStack gap={3} align="start" spacing={0}>
                  <Flex gap={'10px'}>
                    <Text minWidth="100px" variant="supInfoBankLabel">
                      {t('dialogs.supplier_information.bank_labels.bank_name')}
                    </Text>
                    <Text variant="supInfoValue">Bank of Georgia</Text>
                  </Flex>
                  <Flex gap={'10px'}>
                    <Text minWidth="100px" variant="supInfoBankLabel">
                      {t(
                        'dialogs.supplier_information.bank_labels.bank_branch',
                      )}
                    </Text>
                    <Text variant="supInfoValue">
                      Harav Reines St 2, Tel Aviv-Yafo, 6438102
                    </Text>
                  </Flex>
                  <Flex gap={'10px'}>
                    <Text minWidth="100px" variant="supInfoBankLabel">
                      {t(
                        'dialogs.supplier_information.bank_labels.account_number',
                      )}
                    </Text>
                    <Text variant="supInfoValue">
                      BOG098H0973097210470470000023
                    </Text>
                  </Flex>
                  <Flex gap={'10px'}>
                    <Text minWidth="100px" variant="supInfoBankLabel">
                      {t('dialogs.supplier_information.bank_labels.iban')}
                    </Text>
                    <Text variant="supInfoValue">IL35020</Text>
                  </Flex>
                </VStack>
              </VStack>
            </VStack>
            <Flex
              content="space-between"
              align="center"
              flexDir={{ base: 'column', md: 'row' }}
              gap={{ base: '8px', md: '24px' }}>
              <Button
                w="full"
                variant="h2outlined"
                fontSize={{ base: '12px !important', md: '14px !important' }}
                lineHeight={{ base: '12px !important', md: '16px !important' }}
                height="44px !important"
                onClick={() => handleDecline()}>
                {t('dialogs.supplier_information.buttons.decline')}
              </Button>
              <Button
                w="full"
                variant="h1cta"
                fontSize={{ base: '12px !important', md: '14px !important' }}
                lineHeight={{ base: '12px !important', md: '16px !important' }}
                height="44px !important"
                onClick={() => handleConfirm()}>
                {t('dialogs.supplier_information.buttons.confirm')}
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ExternalSupplierDialog;
