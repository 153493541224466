import {
  Button,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';
import { Product } from 'services/@types';
interface AddNewProductSectionProps {
  onClose: () => void;
  onSave: (product: Partial<Product>) => void;
  showNewProductSection: boolean;
}

export const AddNewProductSection: React.FC<AddNewProductSectionProps> = ({
  onSave,
  onClose,
  showNewProductSection,
}) => {
  const { t } = useTranslation();
  const { onClose: onCloseSection } = useDisclosure();
  const [newProductProps, setNewProductProps] = useState<Partial<Product>>({
    name: '',
    price: 0,
  });

  const handleCloseSection = useCallback(() => {
    onCloseSection();
    onClose();
    setNewProductProps({ name: '', price: 0 });
  }, [onClose, onCloseSection]);

  const handleSaveAndClose = useCallback(() => {
    onSave(newProductProps);
    handleCloseSection();
  }, [handleCloseSection, newProductProps, onSave]);

  return (
    <Collapse
      in={showNewProductSection}
      animateOpacity
      style={{ width: '100%' }}>
      <Flex
        w="100%"
        bg="#f5f5f5"
        px={6}
        py={4}
        display={showNewProductSection ? 'flex' : 'none'}>
        <VStack w="100%">
          <HStack w="100%" align="start">
            <Text color="brand.900" fontWeight={'bold'}>
              {t('products.add_package_modal.add_new_product')}
            </Text>
            <Spacer />
            <IconButton
              variant={'ghost'}
              color={'gray.500'}
              icon={<IoClose />}
              position={'relative'}
              onClick={handleCloseSection}
              p={'1px'}
              fontSize={'1.6rem'}
              aria-label={'close-modal'}
            />
          </HStack>
          <HStack
            gap={{ base: 4, md: 20 }}
            w="100%"
            wrap={{ base: 'wrap', md: 'nowrap' }}
            spacing={0}>
            <InputField
              id="name"
              value={newProductProps?.name}
              maxW={340}
              onChange={(e) => {
                setNewProductProps((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
              label={t('products.add_package_modal.product_name')}
              placeholder={t(
                'products.add_package_modal.product_name_placeholder',
              )}
              w="100%"
            />
            <InputField
              maxW={340}
              id="price"
              type='number'
              value={newProductProps?.price || 0 + ''}
              onChange={(e) => {
                setNewProductProps((prev) => ({
                  ...prev,
                  price: parseInt(e.target.value || '0'),
                }));
              }}
              label={t('products.add_package_modal.price')}
              placeholder={t('products.add_package_modal.price_placeholder')}
              w="100%"
            />
          </HStack>

          <Button
            alignSelf={'end'}
            variant={'h2outlined'}
            onClick={handleSaveAndClose}>
            {t('products.add_package_modal.add_close')}
          </Button>
        </VStack>
      </Flex>
    </Collapse>
  );
};
