import { Button } from '@chakra-ui/react';
import EventCard from 'components/eventsPickerDialog/eventCard';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IEvent } from 'services/@types';

interface IAssignEventProps {
  handleOpenEventDialog: () => void;
  handleRemoveEvent: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  event?: IEvent | null;
  w?: any;
  maxW?: any;
}

function AssignEvent(props: IAssignEventProps) {
  const { handleOpenEventDialog, event, handleRemoveEvent, w, maxW } = props;
  const { t } = useTranslation();

  if (event) {
    return (
      <EventCard
        event={event}
        onClick={handleOpenEventDialog}
        isQuoteEvent
        maxW={maxW}
        flex="1"
        handleClickXIcon={handleRemoveEvent}
        w={w}
      />
    );
  }

  return (
    <Button variant="h1cta" onClick={handleOpenEventDialog} w={w}>
      {t('create_quote.assign_event')}
    </Button>
  );
}

export default memo(AssignEvent);
