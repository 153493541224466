import { AxiosInstance, AxiosResponse } from 'axios';
import {
  Business,
  IEvent,
  Invite,
  IQuote,
  PaginatedResults,
  Supplier,
  User,
} from './@types';
import { createAxiosInstance } from './axios.util';

class InviteService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createInvite<T = {}>(
    invite: Partial<Invite>,
    options?: Record<string, any>,
  ): Promise<Invite & T> {
    const response: AxiosResponse<Invite & T> = await this.api.post(
      '/invites',
      invite,
      {
        ...(options && { params: options }),
      },
    );
    return response.data;
  }

  public async getInvites(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<Invite>> {
    const response: AxiosResponse<PaginatedResults<Invite>> =
      await this.api.get('/invites', {
        params: queryParams,
      });
    return response.data;
  }

  public async getInvite(inviteId: string): Promise<Invite> {
    const response: AxiosResponse<Invite> = await this.api.get(
      `/invites/${inviteId}`,
    );
    return response.data;
  }

  public async getInviteByToken(token: string): Promise<{
    invite: Invite;
    sender: User;
    business?: Business;
    supplier?: Supplier;
    quote?: IQuote;
    event?: IEvent;
  }> {
    const response: AxiosResponse<{
      invite: Invite;
      sender: User;
      business?: Business;
      supplier?: Supplier;
      quote?: IQuote;
      event?: IEvent;
    }> = await this.api.get(`/invites/token?token=${token}`);
    return response.data;
  }

  public async updateInvite(
    inviteId: string,
    updates: Partial<Invite>,
  ): Promise<Invite> {
    const response: AxiosResponse<Invite> = await this.api.patch(
      `/invites/${inviteId}`,
      updates,
    );
    return response.data;
  }

  public async deleteInvite(inviteId: string): Promise<void> {
    await this.api.delete(`/invites/${inviteId}`);
  }
}

const _inviteService = new InviteService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _inviteService;
